
<nav class="bnx-table-pagination"  aria-label="Pagination">
  <div class="bnx-table-pagination__left-sec">
    <div class="bnx-table-pagination__txt"> Rows per page </div>
    <ng-select  [(ngModel)]="pageLimit" (change)="changePageLimit()" class="page-ngselect" >
      <ng-option *ngFor="let item of pageLimits">{{item}}</ng-option>
    </ng-select>
    <!-- <ng-select [items]="cities" bindLabel="name" [dropdownPosition]="'top'" [searchable]="false" placeholder="0" [(ngModel)]="selectedCity"></ng-select> -->


  </div>
  <div class="bnx-table-pagination__right-sec">
    <a (click)="onPrevious()" class="tag-a" [ngClass]="{'disable':initialPage==1}">
      <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
        aria-hidden="true">
        <path fill-rule="evenodd"
          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
          clip-rule="evenodd" />
      </svg>

    </a>
    <!-- aria-current="page" -->
    <!-- {{pageArr|json}} -->
    <!-- <a class="number-a" aria-current="page" *ngFor="let item of pageArr;let i=index" (click)="gotoPage(item)"
    [ngClass]="{
        'selected' :(i+1===initialPage) || (mode!==1 && i+1===pseudoI),
        'bg-white text-gray-500 border-gray-300': i+1!=initialPage }" >
      {{item?item:'...'}}
    </a> -->

    <a  *ngFor="let item of pageArr;let i=index" (click)="item.page!=='...'? gotoPage(item.page):''"
        [ngClass]="{ 'selected' :item.selected }" class="number-a">
          <!-- {{'( '+ (i+1) +' - '  +initialPage +')' }} -->
          <!-- {{item.selected}} -->
          {{item ? item.page : '...'  }}
         <!-- {{item?item.page:'...'}} -->
        </a>

    <a (click)="onNext()" class="tag-a last" [ngClass]="{'disable':initialPage==_totalPage}">


      <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
        aria-hidden="true">
        <path fill-rule="evenodd"
          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
          clip-rule="evenodd" />
      </svg>
    </a>
  </div>

  <!-- <p class="page-desc">{{'Page '+initialPage+' of '+_totalPage}}</p> -->
</nav>
