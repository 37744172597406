
<div class="sub-form">
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <label for="username">username or email  </label>
    <input type="text" value="" formControlName="email" >
    <label for="password">password </label>
    <input type="text" formControlName="password">
    <button class="#">login</button>
</form>
</div>
<!-- <div>christo.siby@beinex.com</div>
<div>davisdivine1@gmail.com</div> -->
<!-- <span>admin.apps@beinex.com</span>
<span>Beinex@iraj1</span> -->
