import { Component } from '@angular/core';
import { finalize } from 'rxjs';
import { FunctionHelperService } from 'src/app/core/helper/function.helper.service';
import { TableOption, editDelete } from 'src/app/core/interface/table';
import { DownloadService } from 'src/app/core/services/common/download.service';
import { InterviewScheduleService } from 'src/app/modules/user/services/interview-schedule-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-upcomming-joinees',
  templateUrl: './upcomming-joinees.component.html',
  styleUrls: ['./upcomming-joinees.component.scss']
})
export class UpcommingJoineesComponent {
  isLoading: boolean = false;
  upcommingJobList: any[] = [];
  totalPages!: number;
  tableLimit = 10;
  page = 1;
  tableTitle = [
    'Name',
    'Email',
    'Job Title',
    'Offer Date',
    'Joining Date',
    'Offer Status',
    '',
    '',
  ];

  tableKey = [
    'Name',
    'EmailID',
    'jobTitle',
    'OfferDate',
    'JoiningDate',
    'status',
  ];

  offerStatusList = [
    { status: 'Offered', color: '#ffc107' },
    { status: 'Revoked', color: '#b9c0c7' },
    { status: 'Declined', color: '#b9c0c7' },
    { status: 'Accepted', color: '#dc3545' },
    { status: 'Selected', color: '#28a745' }
  ];

  tableEditDelele: editDelete = {
    edit: false,
    delete: false,
  };

  tableOption: TableOption = {
    viewMode: false,
    infoMode: false,
    optionMode: false,
    optionList: []
  }
  setDefaultPage: boolean =false;
  filterArray:any = {
    "job_opening_status" : [],
    "jobTitile": [],
  };
  constructor(private InterviewScheduleService: InterviewScheduleService,
    private helper: FunctionHelperService,
  
    private downloadService: DownloadService) {}

  ngOnInit(): void {
    this.getallUpcommingJoinee('');
  }


  getallUpcommingJoinee(filterArray: any): void {
    if (filterArray == '') {
      filterArray = {
        "job_opening_status" : [],
        "jobTitile": [],
      };
    }
    this.isLoading = true;
    this.InterviewScheduleService.upcommingJoineeList(
      filterArray,
      (this.page - 1) * this.tableLimit,
      this.tableLimit
    )
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((res: any) => {
        this.isLoading = false;
        this.totalPages = res?.count % this.tableLimit == 0 ? Math.trunc(res?.count / this.tableLimit) : Math.trunc(res.count / this.tableLimit) + 1;
        this.upcommingJobList = res.results.map((item: any) => {
        this.setDefaultPage = false;
        let tableColor = this.offerStatusList.filter((res: any) => {
          if (res.status == item.offer_status) {
            return res.color;
          }
        })

        return {
          id: item?.id,
          Name:item.candidate?.name,
          EmailID:item.candidate?.email,
          jobTitle : item.candidate?.job_position,
          OfferDate : item?.offer_date,
          JoiningDate : item?.date_of_join,
          status :  {
            label: item.offer_status,
            color: tableColor[0]?.color
          }
        };
      });
      this.tableKey = [
        'Name',
        'EmailID',
        'jobTitle',
        'OfferDate',
        'JoiningDate',
        'status',
      ];
      })
  }

  getChange(env: any) {
    switch (env.action) {

      case 'pageLimit':
        this.tableLimit = env.pageLimit;
        this.getallUpcommingJoinee(this.filterArray);
        break;
        case 'page':
        this.page = env.page;
        this.getallUpcommingJoinee(this.filterArray);
        break;
      default:
        break;
    }
  }


  getFilteredChange(env:any){
    this.tableLimit = 10;
        this.page = 1;
        this.setDefaultPage = true
    switch (env.type) {
      case 'search':
        this.getallUpcommingJoinee(env.filterData);
        break;
      case 'filter':
        this.getallUpcommingJoinee(env.filterData);
        break;
      case 'clear':
        this.getallUpcommingJoinee(env.filterData);
        break;
      default:
        break;
    }
    
  }

  downloadExcel(filterArray: any): void{
    if(filterArray == ''){
      filterArray = {
        "location" : [],
        "job_position" : [],
        "job_opening_status" : [],
       }
    }

    const apiUrl = environment.baseUrl + environment.apiVersion;
    this.downloadService
        .download(
          `${apiUrl}upcoming-joinees-export/xls_download?filter=${JSON.stringify(filterArray)+'&token='+this.helper.userData.key}`
        )
        .subscribe((data) => {
          this.downloadService.createATagAndClickDownload(data, {
            name: 'Upcomming Joinees List.xls',
          });
        });


    // let apiUrl = environment.baseUrl + environment.apiVersion;
    // window.open(apiUrl + 'upcoming-joinees-export/xls_download?filter='+ JSON.stringify(filterArray)+'&token='+this.helper.userData.key);
    }



}
