<div class="bnx-main-wrapper" >
    <app-header></app-header> 
  <ng-container *ngIf="loggedUserType !== 'CANDIDATE'">
  <app-sidebar></app-sidebar>
</ng-container>

  <div class="bnx-main-wrapper__body " [ngClass]="{'close' : sideNavStatus}" [ngClass]="{'ms-0 ':loggedUserType === 'CANDIDATE'}">
    <router-outlet></router-outlet>
  </div>
</div>
