import { Component } from '@angular/core';
import { LoginService } from '../../service/login.service';
import { ToastrService } from 'ngx-toastr';
import { catchError, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FunctionHelperService } from 'src/app/core/helper/function.helper.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent {
  constructor(public login: LoginService, private toast: ToastrService,private route: ActivatedRoute,    private helper: FunctionHelperService) {
    if (this.route?.snapshot?.queryParams['message']) {
      this.toast.error(this.route?.snapshot?.queryParams['message']);
    }
  }

  loginMicrosoft() {
    this.login.loginSaml().pipe(
      catchError((err:any) => {
        if (err.error?.status == "failed") {
          this.toast.error(err.error?.message , 'Error');
        } else {
          this.toast.error(err.statusText , 'Error');
        }
        return of(false)
      })).subscribe((res: any) => {
      // if (res.status === ApiStatus.ok) {
        window.open(res.url, '_self');
    //  }
    });
  }


}
