import { makeAutoObservable } from "mobx";
import { action, observable } from "mobx-angular"; 


class Store {
    constructor() {
        makeAutoObservable(this)
    }
    
    //App
    @observable
    title: string = 'Operation Excellence Portal';

    @observable
    pageTitle: string = '';

    @observable
    noContentText: string = 'NA';

    activeStatusId: number = 1;

    inActiveStatusId: number = 2;

    overlay: boolean = false;

    confirmationLoading: boolean = false;

    previousUrl: string = '';

    currentUrl: string = '';

    @observable
    showTutorial: boolean = false;

    @action
    setTitle(title: string) {
        if (title) {
            this.title = `${title.trim()} Operation Excellence Portal`;
            this.pageTitle = title.substring(0, title.indexOf('|')).trim();
        }
    }

    //loading
    @observable
    loading: boolean = false;

    @action
    enableLoading() {
        this.loading = true;
    }

    @action
    disableLoading() {
        this.loading = false;
    }

}

export const AppStore = new Store();