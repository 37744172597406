import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiEndPoints } from 'src/app/core/constants/constants';
import { HttpService } from 'src/app/core/services/common/HttpService';

@Injectable({
  providedIn: 'root'
})
export class CandidateMasterService {
selectedCandidateId! : any ;
  constructor(
    private httpService : HttpService
  ) { }

  getCandateList(filterArray: any,offset:any,limit:any): Observable<any> {
    return this.httpService.get(`${ApiEndPoints.candidateList}?filter=${JSON.stringify(filterArray)}&offset=${offset}&limit=${limit}`);
  }

  addCandidate(data:any): Observable<any> {
    return this.httpService.post(ApiEndPoints.addCandidate,data);
  }

  inactiveCandidate(id:string){
    return this.httpService.delete(`${ApiEndPoints.inactiveCandidate}/${id}`);
  }

  candidateDetails(id:string){
    return this.httpService.get(`${ApiEndPoints.candidateDetails}/${id}`);
  }

  editCandidateStatus(data:any,id:string): Observable<any> {
    return this.httpService.patch(`${ApiEndPoints.editCandidateStatus}/${id}`,data);
  }

  getCandidateDetails(id:any){
    return this.httpService.get(`${ApiEndPoints.candidateDetails}/${id}`);
  }

  editCandidateDetails(data:any,id:string): Observable<any> {
    return this.httpService.patch(`${ApiEndPoints.editCandidateDetails}/${id}`,data);
  }

  getCandidatePanel(id:any){
    return this.httpService.get(`${ApiEndPoints.candidatePanel}/${id}`);
  }

  updateCandidatePanel(data:any,id:string){
    return this.httpService.patch(`${ApiEndPoints.updateCandidatePanel}/${id}`, data);
  }

  editCandidatePanel(data:any,id:string){
    return this.httpService.patch(`${ApiEndPoints.updateCandidatePanel}/${id}`, data);
  }

  candidateAssignment(data:any){
    return this.httpService.post(`${ApiEndPoints.candidateAssignment}`, data);
  }

  submitAssignment(data:any){
    return this.httpService.post(`${ApiEndPoints.candidateSubmitAssignment}`, data);
  }

  getCandidateAssignment(id:any){
    return this.httpService.get(`${ApiEndPoints.candidateAssignmentList}/${id}`);
  }

  getCandateHistory(id:any): Observable<any> {
    return this.httpService.get(`${ApiEndPoints.candidateHistory}/${id}`);
  }


  getCandidateDocument(id:any){
    return this.httpService.get(`${ApiEndPoints.candidateDocumentList}/${id}`);
  }

  documentUpload(id:any, data:any){
    return this.httpService.patch(`${ApiEndPoints.documentUpload}/${id}`, data);
  }


  getcandidateInterviewStatus(id:any){
    return this.httpService.get(`${ApiEndPoints.candidateInterviewStatus}/${id}`);
  }

  importCandidate(data:any) {
    return this.httpService.post(`${ApiEndPoints.importCandidate}`, data);
  }

  getProfileImage(id:any){
    return this.httpService.get(`${ApiEndPoints.getprofileImage}/${id}`);
  }

  uploadCandidateImage(data:any, id:any){
    return this.httpService.post(`${ApiEndPoints.uploadProfileImage}/${id}`, data);
  }

  getFreezeList(filterArray: any,offset:any,limit:any): Observable<any> {
    return this.httpService.get(`${ApiEndPoints.freezeList}?filter=${JSON.stringify(filterArray)}&offset=${offset}&limit=${limit}`);
  }

  activeCandidate(id:string){
    return this.httpService.post(`${ApiEndPoints.activeCandidate}/${id}`);
  }

  getDocLinks(id:string){
    return this.httpService.get(`${ApiEndPoints.docCandidateLinks}/${id}`);
  }

  editHRFeedbackDetails(data:any, id:any){
    return this.httpService.patch(`${ApiEndPoints.editHrFeedback}/${id}`, data);
  }
}
