import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ApiStatus } from 'src/app/core/constants/constants';
import { CommonService } from '../../service/common.service';
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnChanges {
  searchFilter: string = '';
  searchText: any;
  departmentList: any = [];
  checkedDepartment: any = [];
  checkedStatus: any = [];
  jobTitileList: any = [];
  checkedJobTitle: any = [];
  statusList: any = [];
  rollList: any = []
  demandStatusList: any = [{ id: '1', name: 'Approved' }, { id: '2', name: 'Pending' }, { id: '3', name: 'Rejected' }];
  demandJobStatusList: any = [{ name: 'Active', id: 1 }, { name: 'Inactive', id: 0 }];
  candidateLevelList: any = [{ name: 'L1' }, { name: 'L2' }, { name: 'L3' }]
  keyPressSearch: any;
  locationList: any = [];
  checkedLocation: any = [];
  checkedDemandStatus: any = [];
  checkedDemandJobStatus: any = [];
  checkedCandidateLevel: any = [];
  checkedRoll: any = [];
  showFilter: boolean = false;
  settingComponment: boolean = false;

  filterArray: any = {
    'q': ''
  }

  selectedLocation: any;
  selectedJobTitle: any;
  selectedDepartment: any;
  selectedStatus: any;
  selectedDemandStatus: any;
  selectedDemandJobStatus: any;
  selectedLevel: any;
  selectedRoll: any;
  cleatBtn: boolean =false;

  @Output() filterChange = new EventEmitter();
  @Output() download = new EventEmitter();
  @Input() checkedEmit: any;
  @Input() componentName!: string;
  @Input() showLocation!: boolean;
  @Input() showClient!: boolean;
  @Input() status!: boolean;
  @Input() projects!: boolean;
  @Input() demandStatus!: boolean;
  @Input() demandJobStatus!: boolean;
  @Input() department!: boolean;
  @Input() roll!: boolean;
  @Input() showExcelDownload !: boolean;
  @Input() showMultiExcelDownload !: boolean;
  @Input() candidateLevel!: boolean;
  @Input() searchClear!: boolean;
  @Input() actionList: any;


  constructor(
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.getAllDepartment();
    this.getAllLocation();
    this.getAllJobTitile();
    this.getAllRoles();

    if (this.componentName === 'settings') {
      this.settingComponment = true;
    }

    if (this.componentName === 'candidateMaster' || this.componentName === 'candidateFreezeMaster') {
      this.getStatusForCandidateMaster();
    } else if (this.componentName === 'interviewSchedule' || this.componentName === 'interviews-panel' || this.componentName === 'upcomming-interviews-panel') {
      this.statusList = [{ name: 'In Progress' }, { name: 'Pending' }, { name: 'Shortlist' }, { name: 'Completed' }, { name: 'On hold' }, { name: 'Rejected' }];
      this.statusList.forEach((v: object) => {
        Object.assign(v, { isCheck: false });
      })
    } else if (this.componentName === 'offerstatus') {
      this.statusList = [{ name: 'Accepted' }, { name: 'Declined' }, { name: 'Revoked' }, { name: 'Offered' }, { name: 'Selected' }];
      this.statusList.forEach((v: object) => {
        Object.assign(v, { isCheck: false });
      })
    }
    else {
      this.getAllStatus();
    }
  }


  ngOnChanges(change: SimpleChanges) {
    if (change['searchClear']) {
      this.searchText = null;
    }
  }

  getCheckDepartment(env: any) {
    if (env !== undefined) {
      let checked = this.checkedDepartment.find((ele: any) => ele.id === env.id);
      if (checked === undefined) {
        this.checkedDepartment.push({
          id: env?.id,
          name: env?.name
        })
      }
    } else {
      this.checkedDepartment.forEach((element: any, index: any) => {
        if (element?.id == env?.id) {
          var index = index;
          if (index !== -1) {
            this.checkedDepartment.splice(index, 1);
          }
        }
      });

    }
    // let filterArray = this.getFilteredData();
    // this.filterChange.emit(filterArray);
    const data = {
      type: 'filter',
      filterData: this.getFilteredData()
    };
    this.filterChange.emit(data);
  }

  getCheckStatusJobTitile(env: any) {
    if (env !== undefined) {
      let checked = this.checkedJobTitle.find((ele: any) => ele.id === env.id);
      if (checked === undefined) {
        this.checkedJobTitle.push({
          id: env?.id,
          name: env?.name
        })
      }
    } else {
      this.checkedJobTitle.forEach((element: any, index: any) => {
        if (element?.id == env?.id) {
          var index = index;
          if (index !== -1) {
            this.checkedJobTitle.splice(index, 1);
          }
        }
      });

    }
    // let filterArray = this.getFilteredData();
    // this.filterChange.emit(filterArray);
    const data = {
      type: 'filter',
      filterData: this.getFilteredData()
    };
    this.filterChange.emit(data);
  }

  getCheckStatus(env: any) {
    if (this.componentName === 'interviewSchedule') {
      this.getCheckStatusForInterview(env);
      return;
    } else if (this.componentName === 'offerstatus') {
      this.getCheckStatusForoffer(env);
      return;
    }
    else {
      if (env !== undefined) {
        let checked = this.checkedStatus.find((ele: any) => ele.name === env.name);
        if (checked === undefined) {
          this.checkedStatus.push({
            id: env?.id,
            name: env?.name
          })
        }
      } else {
        this.checkedStatus.forEach((element: any, index: any) => {
          if (element?.name == env?.name) {
            var index = index;
            if (index !== -1) {
              this.checkedStatus.splice(index, 1);
            }
          }
        });
      }
    }
    // let filterArray = this.getFilteredData();
    // this.filterChange.emit(filterArray);
    const data = {
      type: 'filter',
      filterData: this.getFilteredData()
    };
    this.filterChange.emit(data);
  }

  getCheckStatusForInterview(env: any) {
    if (env !== undefined) {
      let checked = this.checkedStatus.find((ele: any) => ele.name === env.name);
      if (checked === undefined) {
        this.checkedStatus.push({
          id: env?.id,
          name: env?.name
        })
      }
    } else {
      this.checkedStatus.forEach((element: any, index: any) => {
        if (element?.name == env?.name) {
          var index = index;
          if (index !== -1) {
            this.checkedStatus.splice(index, 1);
          }
        }
      });
    }
    // let filterArray = this.getFilteredData();
    // this.filterChange.emit(filterArray);
    const data = {
      type: 'filter',
      filterData: this.getFilteredData()
    };
    this.filterChange.emit(data);

  }


  getCheckStatusForoffer(env: any,) {
    if (env !== undefined) {
      let checked = this.checkedStatus.find((ele: any) => ele.name === env.name);
      if (checked === undefined) {
        this.checkedStatus.push({
          id: env?.id,
          name: env?.name
        })
      }
    } else {
      this.checkedStatus.forEach((element: any, index: any) => {
        if (element?.name == env?.name) {
          var index = index;
          if (index !== -1) {
            this.checkedStatus.splice(index, 1);
          }
        }
      });
    }
    // let filterArray = this.getFilteredData();
    // this.filterChange.emit(filterArray);
    const data = {
      type: 'filter',
      filterData: this.getFilteredData()
    };
    this.filterChange.emit(data);

  }


  getCheckLocation(env: any) {
    if (env !== undefined) {
      let checked = this.checkedLocation.find((ele: any) => ele.id === env.id);
      if (checked === undefined) {
        this.checkedLocation.push({
          id: env?.id,
          name: env?.name

        });
      }
    } else {
      this.checkedLocation.forEach((element: any, index: any) => {
        if (element?.id == env?.id) {
          var index = index;
          if (index !== -1) {
            this.checkedLocation.splice(index, 1);
          }
        }
      });

    }
    // let filterArray = this.getFilteredData();
    // this.filterChange.emit(filterArray);
    const data = {
      type: 'filter',
      filterData: this.getFilteredData()
    };
    this.filterChange.emit(data);
  }


  getCheckRoll(env: any) {
    if (env !== undefined) {
      let checked = this.checkedRoll.find((ele: any) => ele.id === env.id);
      if (checked === undefined) {
        this.checkedRoll.push({
          id: env?.id,
          name: env?.name
        })
      }
    } else {
      this.checkedRoll.forEach((element: any, index: any) => {
        if (element?.id == env?.id) {
          var index = index;
          if (index !== -1) {
            this.checkedRoll.splice(index, 1);
          }
        }
      });

    }
    // let filterArray = this.getFilteredData();
    // this.filterChange.emit(filterArray);
    const data = {
      type: 'filter',
      filterData: this.getFilteredData()
    };
    this.filterChange.emit(data);
  }


  getCheckDemandStatus(env: any) {
    if (env !== undefined) {
      let checked = this.checkedDemandStatus.find((ele: any) => ele.id === env.id);
      if (checked === undefined) {
        this.checkedDemandStatus.push({
          id: env?.id,
          name: env?.name
        })
      }
    } else {
      this.checkedDemandStatus.forEach((element: any, index: any) => {
        if (element?.id == env?.id) {
          var index = index;
          if (index !== -1) {
            this.checkedDemandStatus.splice(index, 1);
          }
        }
      });

    }
    // let filterArray = this.getFilteredData();
    // this.filterChange.emit(filterArray);
    const data = {
      type: 'filter',
      filterData: this.getFilteredData()
    };
    this.filterChange.emit(data);
  }

  getCheckDemandJobStatus(env: any) {
    if (env !== undefined) {
      let checked = this.checkedDemandJobStatus.find((ele: any) => ele.id === env.id);
      if (checked === undefined) {
        this.checkedDemandJobStatus.push({
          id: env?.id,
          name: env?.name
        })
      }
    } else {
      this.checkedDemandJobStatus.forEach((element: any, index: any) => {
        if (element?.id == env?.id) {
          var index = index;
          if (index !== -1) {
            this.checkedDemandJobStatus.splice(index, 1);
            this.checkedDemandJobStatus.controls['checked'].patchValue(false)
          }
        }
      });

    }
    // let filterArray = this.getFilteredData();
    const data = {
      type: 'filter',
      filterData: this.getFilteredData()
    };
    this.filterChange.emit(data);
  }


  getCheckCandidateLevel(env: any) {
    if (env !== undefined) {
      let checked = this.checkedCandidateLevel.find((ele: any) => ele.name === env.name);
      if (checked === undefined) {
        this.checkedCandidateLevel.push({
          name: env?.name
        })
      }
    } else {
      this.checkedCandidateLevel.forEach((element: any, index: any) => {
        if (element?.name == env?.name) {
          var index = index;
          if (index !== -1) {
            this.checkedCandidateLevel.splice(index, 1);
          }
        }
      });

    }
    // let filterArray = this.getFilteredData();
    const data = {
      type: 'filter',
      filterData: this.getFilteredData()
    };
    this.filterChange.emit(data);
  }



  searchTable($event: any) {
    this.keyPressSearch = $event.target.value;
    const data = {
      type: 'search',
      searchData: $event.target.value,
      filterData: this.getFilteredData()
    };
    this.filterChange.emit(data);
  }

  getFilteredData(): object {
    this.cleatBtn = true;
    let checkedDepartment = this.checkedDepartment.map((item: any) => {
      return item?.id;
    })
    let checkedJobTitle = this.checkedJobTitle.map((item: any) => {
      return item?.id;
    })
    let checkedStatus;
    if (this.componentName === 'interviewSchedule' || this.componentName === 'interviews-panel') {
      checkedStatus = this.checkedStatus.map((item: any) => {
        return item?.name;
      })
    } else {
      checkedStatus = this.checkedStatus.map((item: any) => {
        return item?.id;
      })
    }
    let checkedLocation = this.checkedLocation.map((item: any) => {
      console.log(item);

      return item?.id;
    })
    let checkedDemandStatus = this.checkedDemandStatus.map((item: any) => {
      return item?.name;
    })
    let checkedDemandJobStatus = this.checkedDemandJobStatus.map((item: any) => {
      return item?.id;
    })
    let checkedCandidateLevel = this.checkedCandidateLevel.map((item: any) => {
      return item?.name;
    })

    let checkedRoll = this.checkedRoll.map((item: any) => {
      return item?.id;
    })

    if (this.componentName === 'interviewSchedule') {
      checkedStatus = this.checkedStatus.map((item: any) => {
        return item?.name;
      })
    }


    if (this.componentName === 'offerstatus') {
      checkedStatus = this.checkedStatus.map((item: any) => {
        return item?.name;
      })
    }

    let filterArray = {};
    if (this.componentName === 'demandMaster') {
      filterArray = {
        "job_position": checkedDepartment,
        "demand_status": checkedDemandStatus,
        "demand_job_status": checkedDemandJobStatus,
        "q": this.searchText || ''
      }
    }
    else if (this.componentName !== 'demandMaster') {
      filterArray = {
        "department": checkedDepartment,
        "job_position": checkedJobTitle,
        "job_opening_status": checkedStatus,
        "location": checkedLocation,
        "level": checkedCandidateLevel,
        "license_type": checkedRoll,
        "user_status": checkedDemandJobStatus,
        "q": this.searchText || ''
      }
    }


    // let filterArray = {
    //   "department": checkedDepartment,
    //   "job_position": job_position,
    //   "job_opening_status" : checkedStatus,
    //   "location" : checkedLocation,
    //   "demand_status": checkedDemandStatus,
    //   "demand_job_status": checkedDemandJobStatus,
    //   "q": this.searchText || ''
    // }

    return filterArray;
  }

  getAllDepartment(): void {
    this.commonService.getDepartmentList()
      .subscribe((res: any) => {
        if (ApiStatus.ok) {
          this.departmentList = res;
          this.departmentList.forEach((v: object) => {
            Object.assign(v, { isCheck: false });
          })
        }
      })
  }

  getAllJobTitile(): void {
    this.commonService.getJobPosition()
      .subscribe((res: any) => {
        if (ApiStatus.ok) {

          this.jobTitileList = res;
          this.jobTitileList.forEach((v: object) => {
            Object.assign(v, { isCheck: false });
          })
        }
      })
  }


  getAllRoles(): void {
    this.commonService.getRoles()
      .subscribe((res: any) => {
        if (ApiStatus.ok) {
          this.rollList = res.map(function (group: any) {
            return {
              id: group.id,
              name: group.name.toUpperCase() === 'SLT DIRECTORS' ? 'SLT DIRECTOR' : group.name.toUpperCase(),
            }
          });
          var index = this.rollList.findIndex((x:any) => x?.name == "CANDIDATE");
          if (index !== -1) {
            this.rollList.splice(index, 1);
          }
          this.rollList.forEach((v: object) => {
            Object.assign(v, { isCheck: false });
          })
        }
      })
  }

  getAllStatus(): void {
    this.commonService.getJobStatus()
      .subscribe((res: any) => {
        if (ApiStatus.ok) {
          this.statusList = res;
          this.statusList.forEach((v: object) => {
            Object.assign(v, { isCheck: false });
          })
        }
      })
  }

  getStatusForCandidateMaster(): void {
    this.commonService.getCandidateStatus()
      .subscribe((res: any) => {
        if (ApiStatus.ok) {
          this.statusList = res;
          this.statusList.forEach((v: object) => {
            Object.assign(v, { isCheck: false });
          })
        }
      })
  }


  getAllLocation(): void {
    this.commonService.getCountryList()
      .subscribe((res: any) => {
        if (ApiStatus.ok) {
          this.locationList = res;
          this.locationList.forEach((v: object) => {
            Object.assign(v, { isCheck: false });
          })
        }
      })
  }

  clearAllCheckBoxes(): void {
    this.checkedDepartment = [];
    this.checkedJobTitle = [];
    this.checkedStatus = [];
    this.checkedDemandJobStatus = [];
    this.checkedDemandStatus = [];
    this.checkedCandidateLevel = [];

    this.departmentList.forEach((v: any) => {
      v.isCheck = false;
    })
    this.jobTitileList.forEach((v: any) => {
      v.isCheck = false;
    })
    this.statusList.forEach((v: any) => {
      v.isCheck = false;
    })
    this.locationList.forEach((v: any) => {
      v.isCheck = false;
    })
    this.demandJobStatusList.forEach((v: any) => {
      v.isCheck = false;
    })
    this.demandStatusList.forEach((v: any) => {
      v.isCheck = false;
    })
    this.candidateLevelList.forEach((v: any) => {
      v.isCheck = false;
    })
    this.filterChange.emit('');
    this.searchText = "";
  }

  excelDownload(): any {
    let filterArray = this.getFilteredData();
    this.download.emit(filterArray);
  }

  multiExcelDownload(item: any) {
    let filterArray = this.getFilteredData();
    this.download.emit({
      actionFrom: 'table',
      type: 'export',
      filters: filterArray,
      selectedItem: item.id,
      checkedData: this.checkedEmit?.dataChecked || [],
    });
  }

  remove(data: any, type: any): void {
    if (type == "department") {
      this.checkedDepartment.forEach((element: any, index: any) => {
        if (element?.id == data?.id) {
          var index = index;
          if (index !== -1) {
            this.checkedDepartment.splice(index, 1);
            let statusIndex = this.departmentList.findIndex((f: any) => f.id === element.id);
            this.departmentList[statusIndex].isCheck = false;
            if (data.id === this.selectedDepartment) {
              this.selectedDepartment = null;
            }
          }
        }
      });
    }
    if (type == "demandStatus") {
      this.checkedDemandStatus.forEach((element: any, index: any) => {
        if (element?.id == data?.id) {
          var index = index;
          if (index !== -1) {
            this.checkedDemandStatus.splice(index, 1);
            let statusIndex = this.demandStatusList.findIndex((f: any) => f.id === element.id);
            this.demandStatusList[statusIndex].isCheck = false;
            console.log(data);
            if (data.id === this.selectedDemandStatus) {
       
              this.selectedDemandStatus = null;
            }
          }
        }
      });
    }
    if (type == "jobStatus") {
      this.checkedDemandJobStatus.forEach((element: any, index: any) => {
        if (element?.id == data?.id) {
          var index = index;
          if (index !== -1) {
            this.checkedDemandJobStatus.splice(index, 1);
            let statusIndex = this.demandJobStatusList.findIndex((f: any) => f.id === element.id);
            this.demandJobStatusList[statusIndex].isCheck = false;
            if (data.name === this.selectedDemandJobStatus) {
              this.selectedDemandJobStatus = null;
            }
          }
        }
      });
    }
    if (type == "location") {
      this.checkedLocation.forEach((element: any, index: any) => {
        if (element?.id == data?.id) {
          var index = index;
          if (index !== -1) {
            this.checkedLocation.splice(index, 1);
            let statusIndex = this.locationList.findIndex((f: any) => f.id === element.id);
            this.locationList[statusIndex].isCheck = false;
            if (data.id === this.selectedLocation) {
              this.selectedLocation = null;
            }


          }
        }
      });
    }
    if (type == "jobTitle") {
      this.checkedJobTitle.forEach((element: any, index: any) => {
        if (element?.id == data?.id) {
          var index = index;
          if (index !== -1) {
            this.checkedJobTitle.splice(index, 1);
            let statusIndex = this.jobTitileList.findIndex((f: any) => f.id === element.id);
            this.jobTitileList[statusIndex].isCheck = false;
            console.log(data);
            if (data.id === this.selectedJobTitle) {
              
              this.selectedJobTitle = null;
            }
          }
        }
      });
    }
    if (type == "status") {
      this.checkedStatus.forEach((element: any, index: any) => {
        if (element?.name == data?.name) {
          var index = index;
          if (index !== -1) {
            this.checkedStatus.splice(index, 1);
            let statusIndex = this.statusList.findIndex((f: any) => f.name === element.name);
            this.statusList[statusIndex].isCheck = false;
            console.log(data);
            if (data.name === this.selectedStatus) {
              
              this.selectedStatus = null;
            }
          }
        }
      });
    }
    if (type == "level") {
      this.checkedCandidateLevel.forEach((element: any, index: any) => {
        if (element?.name == data?.name) {
          var index = index;
          if (index !== -1) {
            this.checkedCandidateLevel.splice(index, 1);
            let levelIndex = this.candidateLevelList.findIndex((f: any) => f.name === element.name);
            this.candidateLevelList[levelIndex].isCheck = false;
            if (data.name === this.selectedLevel) {
              console.log(data);
              this.selectedLevel = null;
            }
          }
        }
      });
    }
    if (type == "roll") {
      this.checkedRoll.forEach((element: any, index: any) => {
        if (element?.name == data?.name) {
          var index = index;
          if (index !== -1) {
            this.checkedRoll.splice(index, 1);
            let levelIndex = this.rollList.findIndex((f: any) => f.name === element.name);
            this.rollList[levelIndex].isCheck = false;
            console.log(data);
            
            if (data.id === this.selectedRoll) {
              this.selectedRoll = null;
            }
          }
        }
      });
    }

    // let filterArray = this.getFilteredData();
    // this.filterChange.emit(filterArray);
    const body = {
      type: 'clear',
      filterData: this.getFilteredData()
    };
    this.filterChange.emit(body);
    this.checkComponent();
  }



  checkComponent(){
    if (this.componentName === 'userManagement') {
      if (this.checkedRoll.length === 0 && this.checkedDemandJobStatus.length === 0) {
        this.cleatBtn = false;
      }
    }

    if(this.componentName === 'candidateMaster'){
      if (this.checkedCandidateLevel.length === 0 && this.checkedStatus?.length === 0 && this.checkedJobTitle?.length === 0){
        this.cleatBtn = false;
      }
    }

    if(this.componentName === 'candidateFreezeMaster'){
      if (this.checkedCandidateLevel.length === 0 && this.checkedStatus?.length === 0 && this.checkedJobTitle?.length === 0){
        this.cleatBtn = false;
      }
    }

    if(this.componentName === 'demandMaster'){
      if (this.checkedDepartment.length === 0 && this.checkedDemandStatus?.length === 0 && this.checkedDemandJobStatus?.length === 0){
        this.cleatBtn = false;
      }
    }

    if(this.componentName === 'jobMaster'){
      if (this.checkedLocation?.length === 0 && this.checkedDepartment.length === 0 && this.checkedJobTitle?.length === 0 && this.checkedStatus?.length === 0){
        this.cleatBtn = false;
      }
    }

    if(this.componentName === 'interviewSchedule'){
      if (this.checkedJobTitle?.length === 0 && this.checkedStatus.length === 0 ){
        this.cleatBtn = false;
      }
    }

    if(this.componentName === 'offerstatus'){
      if (this.checkedJobTitle?.length === 0 && this.checkedStatus.length === 0 ){
        this.cleatBtn = false;
      }
    }

    if(this.componentName === 'dashboardJobs'){
      if (this.checkedJobTitle?.length === 0 && this.checkedStatus.length === 0 ){
        this.cleatBtn = false;
      }
    }

    if(this.componentName === 'dashboardJoinees'){
      if (this.checkedJobTitle?.length === 0){
        this.cleatBtn = false;
      }
    }

    if(this.componentName === 'dashboardInterviews'){
      if (this.checkedJobTitle?.length === 0 && this.checkedDepartment.length === 0 ){
        this.cleatBtn = false;
      }
    }

    if(this.componentName === 'interviews-panel'){
      if (this.checkedStatus?.length === 0 && this.checkedDepartment.length === 0 ){
        this.cleatBtn = false;
      }
    }

    if(this.componentName === 'upcomming-interviews-panel'){
      if (this.checkedStatus?.length === 0 && this.checkedDepartment.length === 0 ){
        this.cleatBtn = false;
      }
    }
    
  }
  
  filterBtn(toggle: string) {
    this.showFilter = !this.showFilter;
  }

  clearAll() {
    this.selectedLocation = null;
    this.selectedJobTitle = null;
    this.selectedDepartment = null;
    this.selectedStatus = null;
    this.selectedDemandStatus = null;
    this.selectedDemandJobStatus = null;
    this.selectedLevel = null;
    this.selectedRoll = null;
    this.checkedLocation = [];
    this.checkedDepartment = [];
    this.checkedCandidateLevel = [];
    this.checkedDemandJobStatus = [];
    this.checkedDemandStatus = [];
    this.checkedJobTitle = [];
    this.checkedStatus = [];
    this.checkedRoll = [];
    // this.showFilter = !this.showFilter;
    // this.filterChange.emit('');

    const body = {
      type: 'clear',
      filterData: this.getFilteredData()
    };
    this.filterChange.emit(body);
    this.searchText = "";
    this.cleatBtn = false;
  }


  selectedTypeRemove(env: any, array: any, type: string) {
    if (type === 'location') {
      if (env === null) {
        array.splice(array.length - 1)
      }
    }

    // jobTitle
    if (type === 'jobTitle') {
      if (env === null) {
        array.splice(array.length - 1)
      }
    }

    // department
    if (type === 'department') {
      if (env === null) {
        array.splice(array.length - 1)
      }
    }

    // status
    if (type === 'status') {
      if (env === null) {
        array.splice(array.length - 1)
      }
    }


    // demandStatus
    if (type === 'demandStatus') {
      if (env === null) {
        array.splice(array.length - 1)
      }
    }

    // demandJobStatus
    if (type === 'demandJobStatus') {
      if (env === null) {
        array.splice(array.length - 1)
      }
    }

    // level
    if (type === 'level') {
      if (env === null) {
        array.splice(array.length - 1)
      }
    }

    // roll
    if (type === 'roll') {
      if (env === null) {
        // array.splice(array.length - 1);

      }
    }

    let filterArray = this.getFilteredData();
    this.filterChange.emit(filterArray);
  }







}
