export const environment = {
  production: true,
  version: '2023.1',
  baseUrl: 'https://recruit.beinex.com/',
  cookieDomain:'https://recruit.beinex.com/',
  //  baseUrl: 'https://recruit-staging.beinex.com/',
  // cookieDomain:'https://recruit-staging.beinex.com/',
  apiVersion: 'api/v1/',
  clientId: 'bf48d57c-6b5c-4222-9868-757a6da3c74a'
};
