import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import { CandidateMasterService } from 'src/app/modules/user/services/candidate-master.service';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent {
  @ViewChild('fileInput') fileInput!: ElementRef;
  fileArray: any[] = [];
  deletedFiles: any[] = [];
  isLoading = false;
  template: any;

  constructor(private commonService: CommonService, private candidateApiService: CandidateMasterService,
    @Inject(MAT_DIALOG_DATA) public data: any, private toast: ToastrService,
    public dialogRef: MatDialogRef<ImportComponent>) { }

  ngOnInit(): void {
    this.templateDownload();
  }


  templateDownload(){
  //  this.template = 'https://beinexmicrosoft-my.sharepoint.com/personal/vennila_v_beinex_com/_layouts/15/download.aspx?e=qi0owd&share=ES12ovbyxxxJufMf86yd0KMBBE-bw2wc-9xxGoDLQf7BIw';
  this.template = 'assets/files/Candidate import template.xlsx';
  }

  onFileChange(env: any, type: string) {
    let files: any[] = [];
    this.fileArray = [];
    switch (type) {
      case 'drop':
        files = env;
        break;

      case 'select':
        files = env.files
        break;

      default:
        break;
    }

    let fileTypes = ['.xls', '.xlsx', 'text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', '.csv']
    let invC = true
    for (let i = 0; i < files.length; i++) {
      const element = files[i];
      if (!fileTypes.includes(element.type)) {
        invC = false;
      }
    }
    if (!invC) {
      this.toast.warning('Invalid doc');
      return;
    }

    this.fileArray = [...this.fileArray, ...files];
    this.fileInput.nativeElement.value = '';
  }

  removeFile(i: number) {
    if (this.fileArray[i]?.id) {
      this.deletedFiles.push(this.fileArray[i]?.id);
    }
    this.fileArray.splice(i, 1);
  }


  importCandidate() {
    this.isLoading = true;
    if (this.fileArray?.length !== 0) {
      let formData = new FormData();
      for (let i = 0; i < this.fileArray.filter(e => { return e?.id == undefined }).length; i++) {
        const element = this.fileArray.filter(e => { return e?.id == undefined })[i];
        formData.append(`file`, element)
      }
      this.candidateApiService.importCandidate(formData).pipe(finalize(() => {
        this.isLoading = false;
      }))
        .subscribe((res: any) => {
          if (res.status == 'Success') {
            this.toast.success(res.message);
            this.dialogRef.close(true);
            this.isLoading = false;
          }
        }, (err:any) => {
          if (err.error.status == 'failed') {
            this.toast.error(err.error?.message);
            this.isLoading = false;
          }
        })
    } else {
      this.toast.error('Upload a document');
      this.isLoading = false;
    }
  }

}
