import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { editDelete, TableOption } from 'src/app/core/interface/table';
import { InterviewScheduleService } from 'src/app/modules/user/services/interview-schedule-api.service';

@Component({
  selector: 'app-interview-details',
  templateUrl: './interview-details.component.html',
  styleUrls: ['./interview-details.component.scss'],
})
export class InterviewDetailsComponent {
  interviewDetails!: any;
  panel_list:any = [];
  interviewStatusList = [
    { status: 'In Progress', color: '#17a2b8' },
    { status: 'Pending', color: '#ffc107' },
    { status: 'Shortlist', color: '#007bff' },
    { status: 'Completed', color: '#28a745' },
    { status: 'On hold', color: '#6c757d' },
    { status: 'Rejected', color: '#dc3545' }
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public datas: any,
    private interviewDetailService: InterviewScheduleService
  ) { }

  ngOnInit(): void {
    this.getInterviewDetails();
  }

  getInterviewDetails() {
    this.interviewDetailService
      .getInterviewDetailsById(this.datas)
      .subscribe((res) => {
        let tableColor = this.interviewStatusList.filter((item: any) => {
          if (item.status == res.interview_status) {
            return item.color;
          }
        })

        this.interviewDetails = {
          id: res?.id,
          jobTitle: res.candidate.job_openings.job_title,
          candidate: res.candidate.name,
          status: {
            label: res.interview_status,
            color: tableColor[0]?.color
          },
          email: res.candidate?.email,
          phone: res.candidate?.phone,
          department: res.candidate?.department,
          source: res.candidate?.source?.name,
        }
        this.panel_list.push({
          level: res.panel_list[0].level,
          interviewer: res.panel_list[0].interviewer,
          optInterviewer: res.panel_list[0].optional_interviewer,
          type: res.panel_list[0].type,
          date: res.panel_list[0].date,
          start_time: res.panel_list[0].start_time
        })
      })


  }

tableTitle = ['Level', 'Interviewer', 'Opt.Interviewer', 'Type', 'Date', 'Time'];

  // interviewDetails = [
  //   {
  //     Interviewer: 'lorem ',
  //     Leval: 1,
  //     Time: '11:00 AM',
  //     Date: '22 feb',
  //   },
  //   {
  //     Interviewer: 'lorem ',
  //     Leval: 2,
  //     Time: '11:00 AM',
  //     Date: '22 feb',
  //   },
  //   {
  //     Interviewer: 'lorem ',
  //     Leval: 3,
  //     Time: '11:00 AM',
  //     Date: '22 feb',
  //   },
  // ];
}
