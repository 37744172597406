import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import { ValidationService } from 'src/app/core/services/common/validation.service';
import { InterviewScheduleService } from 'src/app/modules/user/services/interview-schedule-api.service';
import { OfferStatusApiService } from 'src/app/modules/user/services/offer-status-api.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { SearchableSelectComponent } from '../searchable-select/searchable-select.component';

@Component({
  selector: 'app-add-offerstatus',
  templateUrl: './add-offerstatus.component.html',
  styleUrls: ['./add-offerstatus.component.scss']
})
export class AddOfferstatusComponent implements OnInit {

  offerStatusForm!: FormGroup;
  isLoading = false;
  isEdit = false;
  selectedUser: any;
  jobTitleList: any[] = [];
  statusList = ['Accepted', 'Declined', 'Revoked', 'Offered', 'Selected'];
  currencyList = ['SAR', 'AED', 'USD', 'EU', 'QAR', 'INR'];
  OfferCandidateList: any[] = [];
  public minDate: string = '';
  public tomorowDate: string = '';
  filterArray: any = {
    "job_position": [],
    "job_opening_status": [],
  }
  public isDeclined: boolean = false;
  isLoadingEmp: boolean = false;
  public formSubmitted: boolean = false;
  constructor(private commonService: CommonService, private formBuilder: FormBuilder, private toast: ToastrService,
    public dialogRef: MatDialogRef<AddOfferstatusComponent>, private OfferStatusService: OfferStatusApiService, private interviewApiService: InterviewScheduleService,
    @Inject(MAT_DIALOG_DATA) public offerId: any) { }

  ngOnInit(): void {
    this.initForm();
    this.getoffercandidatelist();
    this.getCurrentDate();
    this.offerStatusForm.controls['offer_status'].setValue(this.statusList[3]);
    if (this.offerId) {
      this.isEdit = true;
      this.getofferDetails();
    }
  }


  initForm(): void {
    this.offerStatusForm = this.formBuilder.group({
      id: new FormControl(''),
      candidate: new FormControl('', Validators.required),
      first_name: new FormControl('', Validators.required),
      email: new FormControl(null),
      phone: new FormControl(''),
      offer_date: new FormControl(null),
      job_title: new FormControl(null),
      offer_status: new FormControl(null, Validators.required),
      date_of_joining: new FormControl(null),
      candidate_name: ('')
    })
  }

  getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1; // Months are 0-based
    const day = today.getDate();
    const tomorrow = today.getDate() + 1;
    this.minDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    this.tomorowDate = `${year}-${month.toString().padStart(2, '0')}-${tomorrow.toString().padStart(2, '0')}`;
  }

  getoffercandidatelist() {
    this.isLoadingEmp = true;
    this.interviewApiService.getInterviewCandidateList().subscribe((res: any) => {
      this.OfferCandidateList = res;
      this.isLoadingEmp = false;
    })
  }


  getSelectedItem(env: any) {
    this.offerStatusForm.controls['email'].setValue(env.email);
    this.offerStatusForm.controls['phone'].setValue(env.phone);
    this.offerStatusForm.controls['job_title'].setValue(env.job_openings.job_title);
    this.offerStatusForm.controls['candidate'].setValue(env.id);
    this.offerStatusForm.controls['first_name'].setValue(env.name);
  }


  getofferDetails(): void {
    this.OfferStatusService.getofferDetailsById(this.offerId).subscribe((res: any) => {
      this.selectedUser = res;
      const convertedOfferDate = this.convertDate(res?.offer_date);
      const convertedJoinDate = this.convertDate(res?.date_of_join);    
      this.offerStatusForm.patchValue({
        id: res.id,
        candidate: res.candidate.id,
        first_name: res.candidate.name,
        email: res.candidate.email,
        phone: res.candidate.phone,
        candidate_name: res.candidate.name,
        date_of_joining: convertedJoinDate,
        job_title: res.candidate.job_position,
        offer_date: convertedOfferDate,
        offer_status: res?.offer_status,
      })
    })
  }


  addClient() {
    if (!this.isEdit) {
      this.offerStatusForm.markAllAsTouched();
      if (this.offerStatusForm.valid) {
        this.formSubmitted = true;  
        this.isLoading = true;
        let c_data = this.offerStatusForm.value;
        const data = {
          "candidate": c_data.candidate,
          "name": c_data.first_name,
          "email": c_data.email,
          "phone": c_data.phone,
          "offer_date": c_data.offer_date,
          "offer_status": c_data.offer_status,
          "date_of_join": c_data.date_of_joining
        }
        this.OfferStatusService.addclient(data).pipe(finalize(() => {
          this.isLoading = false;
        })
        )
          .subscribe((res: any) => {
            if (res.status = 'success') {
              this.toast.success(res.message);
              this.dialogRef.close(true);
              this.formSubmitted = false;  
            }
          },
            (err: any) => {
              this.toast.error(err?.error?.message);
              this.formSubmitted = false;  
            })
      }
    } else {
      this.editOffer();
    }

  }

  editOffer() {
    this.offerStatusForm.markAllAsTouched();
    const data = this.offerStatusForm.value;
    this.formSubmitted = true;  
    const body = {
      "candidate": data.candidate,
      "name": data.first_name,
      "email": data.email,
      "phone": data.phone,
      "offer_date": data.offer_date,
      "offer_status": data.offer_status,
      "date_of_join": data.date_of_joining
    }

    this.OfferStatusService.editofferDetails(body, data.id).pipe(finalize(() => { this.isLoading = false; })).subscribe(res => {
      if (res.status = 'success') {
        this.toast.success(res.message);
        this.dialogRef.close(true);
        this.formSubmitted = false;  
      }
    })
  }


  convertDate(originalDate: string) {
    const [year, month, day] = originalDate.split('-');
    const formattedDate = new Date(`${day}-${month}-${year}`);
    const result = formattedDate.toISOString().split('T')[0];
    return result;
  }

  checkingOfferedDate(env:any){
    this.tomorowDate = env?.value;
  }

  getStatusCheck(env:any) {
    if (env === 'Declined') {
      this.isDeclined = true;
      this.offerStatusForm.get('date_of_joining')!.clearValidators();
      this.offerStatusForm.get('date_of_joining')!.updateValueAndValidity();
      this.offerStatusForm.get('offer_date')!.clearValidators();
      this.offerStatusForm.get('offer_date')!.updateValueAndValidity();
      this.offerStatusForm.controls['offer_date'].reset();
      this.offerStatusForm.controls['date_of_joining'].reset();
    } else {
      this.isDeclined = false;  
      this.offerStatusForm.get('date_of_joining')!.setValidators(Validators.required);
      this.offerStatusForm.get('date_of_joining')!.updateValueAndValidity();
      this.offerStatusForm.get('offer_date')!.setValidators(Validators.required);
      this.offerStatusForm.get('offer_date')!.updateValueAndValidity();
    }
    
  }
}
