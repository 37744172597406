import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FunctionHelperService } from 'src/app/core/helper/function.helper.service';

@Component({
  selector: 'app-view-professional-details',
  templateUrl: './view-professional-details.component.html',
  styleUrls: ['./view-professional-details.component.scss']
})
export class ViewProfessionalDetailsComponent {

  loggedUserType: any;


  constructor(public dialogRef: MatDialogRef<ViewProfessionalDetailsComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  
  private helper: FunctionHelperService,
   ) { }

   ngOnInit() {
    this.loggedUserType = this.helper.userData?.user_type;
   }

}
