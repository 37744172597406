<div class="bnx-modal bnx-scroll-box">
  <div class="bnx-modal__close-btn" [mat-dialog-close]="false">
    <svg width="15" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times"
      class="svg-inline--fa fa-times fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
      <path fill="currentColor"
        d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z">
      </path>
    </svg>
  </div>

  <div class="bnx-modal__title">
    <h2 class="bnx-modal__heading">Guidelines</h2>
  </div>

  <div class="bnx-view-modal__body modal_scroll bnx-scroll-box">
    <div class="bnx-modal__wrapper">
      <div class="bnx-form__row">

        <div class="bnx-subtitle">
          <h3 class="bnx-form__titlelabel">Below are the guidelines and best practice for successful interviews. </h3>
        </div>


        <div class="bnx-bodytitle">
          <label class="bnx-main-label">1.	Make a Questionnaire which directly relate to the Job role and technical expectation</label>
          <div class="bnx-bodytitle__body">
            <ul class="bnx-sub-label">
              <li>Pre-set a questionnaire with relevant questions which helps you pick a right question on the flow.</li>
              <li>Have case studies and hands on task questions ready. It helps you to shuffle task every time, to ensure our tasks are not getting leaked to other known candidates.</li>
            </ul>
          </div>
        </div>

        <div class="bnx-bodytitle">
          <label class="bnx-main-label">2.	Review the Candidate's Resume in detail before schedule time.</label>
          <div class="bnx-bodytitle__body">
            <ul class="bnx-sub-label">
              <li>It will help you to frame your questions accordingly and ensures a productive interview session.</li>
              <li>It saves your time and can help you to avoid any potentially embarrassing questions based on a misread of the resume during the interview.</li>
            </ul>
          </div>
        </div>

        <div class="bnx-bodytitle">
          <label class="bnx-main-label">3.	Give a roadmap for the interview process as you get started</label>
          <div class="bnx-bodytitle__body">
            <ul class="bnx-sub-label">
              <li>Begin with a brief description of the company and the job duties.</li>
              <li>Then let the candidate knows the structure of the session. Providing this structure early on sets up the parameters of the interview, keeps you both focused, and gives the candidate an idea of what to expect.</li>
              <li>Allow the candidates to ask question at the end of the interview.</li>
            </ul>
          </div>
        </div>

        <div class="bnx-bodytitle">
          <label class="bnx-main-label">4.	Time management during the Interview Process</label>
          <div class="bnx-bodytitle__body">
            <ul class="bnx-sub-label">
              <li>Interviewer should talk only about 30 percent of the time. Pre-set questionnaire will help to get to all of your questions and that you haven't missed anything to avoid the need for additional follow up.</li>
              <li>Allow the rest 70% of the time to the candidates to describe their skills, answers your questions in detail etc.</li>
            </ul>
          </div>
        </div>

        <div class="bnx-bodytitle">
          <label class="bnx-main-label">5.	Ask Behavioural Questions to know how they acted in a specific situation</label>
          <div class="bnx-bodytitle__body">
            <div class="bnx-bodytitle__subbodytitle"><label class="bnx-form__titlelabel">Example</label></div>
            <ul class="bnx-sub-label">
              <li>Give us an example of a goal you failed to meet, and how you handled the situation.</li>
              <li>Tell us about a time when you solved a problem at your job that wasn't part of your job description.</li>
            </ul>

            <ul class="titleul">You can also give some real time situations and ask them how they will react.</ul>
          </div>
        </div>

        <div class="bnx-bodytitle">
          <label class="bnx-main-label">6.	Extend Professional Courtesies</label>
          <div class="bnx-bodytitle__body">
            <ul class="bnx-sub-label">
              <li>Interviewers are the Brand Ambassadors of Beinex, we should make sure our actions reflect this. Be polite and courteous across the session. Candidates will evaluate the culture of Beinex through the behaviour and attitude of the interviewer.</li>
              <li>If the Candidate is arrogant or with unacceptable attitude, instead of creating an argument we can amicably stop the interview session and reject the candidate from further rounds.</li>
              <li>When we are connecting virtually, It is always advisable to go on Video to have better conversation.</li>
            </ul>
          </div>
        </div>

        <div class="bnx-bodytitle">
          <label class="bnx-main-label">7.	Do not discuss about the salary expectations</label>
          <div class="bnx-bodytitle__body">
            <ul class="bnx-sub-label">
              <li>Technical interviews sessions are not appropriate for Salary discussion. It makes the candidates more rigid about their expectations at the time of final discussion with HR.</li>
              <li>Salary discussions are strictly reserved for the HRs to negotiate at the final level.</li>
            </ul>
          </div>
        </div>




      </div>
    </div>
  </div>



</div>
