import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import { ValidationService } from 'src/app/core/services/common/validation.service';
import { CandidateMasterService } from 'src/app/modules/user/services/candidate-master.service';
import { CommonService } from 'src/app/shared/service/common.service';
@Component({
  selector: 'app-candidate-details-add',
  templateUrl: './candidate-details-add.component.html',
  styleUrls: ['./candidate-details-add.component.scss']
})
export class CandidateDetailsAddComponent {
  candidateForm!: FormGroup;
  isLoading = false;
  isEdit = false;
  jobTitleList: any[] = [];
  sourceList:any[] = [];
  expList: any[] = [];
  fileArray: any[] = [];
  deletedFiles: any[] = [];
  genderList = ['Male', 'Female',  'Others', ];
  statusList = ['Pending'];
  selectedJob:any;
  employeeList:any[] = [];
  beinexEmployee:any = [];
  showEmployee:boolean = false;
  showReferral: boolean = false;
  public formSubmitted: boolean = false;
  public resumeDoc: boolean = false;
  public disableURL: boolean = false;

  constructor(private commonService: CommonService, private formBuilder: FormBuilder, private toast: ToastrService,
    public dialogRef: MatDialogRef<CandidateDetailsAddComponent>,private candidateApiService: CandidateMasterService) { }

  ngOnInit(): void {
    this.initForm();
    this.getJobPosition();
    this.getBeinexEmployee();
    this.getCandidateSource();
    // this.getCandidateExperience();
  }


  initForm(): void {
    this.candidateForm = this.formBuilder.group({
      id: new FormControl(''),
      first_name: new FormControl('', [Validators.required, ValidationService.nameValidator]),
      last_name: new FormControl('', [Validators.required, ValidationService.nameValidator]),
      email: new FormControl('', [Validators.required, ValidationService.emailValidator]),
      phone: new FormControl('', [Validators.required, ValidationService.phoneNumberValidator]),
      gender: new FormControl(null, Validators.required),
      years_of_exp: new FormControl(null, [Validators.required,ValidationService.experisenceValidator,Validators.maxLength(3), Validators.pattern(/^\d+(\.\d{1,1})?$/)]),
      job_position: new FormControl(null ,Validators.required),
      source: new FormControl(null, Validators.required),
      status: new FormControl(null),
      document: new FormControl(''),
      empName: new FormControl(null),
      referralName: new FormControl(null),
      jobId: new FormControl(null),
      jobDept: new FormControl(null),
      hr_screening: new FormControl(null,Validators.required),
      resumeURL: new FormControl(null,[ValidationService.resumeURL])
    })
  }

  getJobPosition(): void {
    this.commonService.getCandidateJobOpenings().subscribe((result:any) => {
      console.log(result);
      result.forEach((element:any) => {
        element.name = element?.years_of_exp === null ? element.job_position?.name :  element.job_position?.name + ' With ' + element.years_of_exp + ' Years Exp '
      });
      this.jobTitleList = result;

    })
  }

  getCandidateSource(): void {
    this.commonService.getCandidateSource().subscribe((result:any) => {
      this.sourceList = result;
    })
  }

    getBeinexEmployee(): void {
    this.commonService.getBeinexEmployee().subscribe((result:any) => {
      this.beinexEmployee = result;
    })
  }


  getJobDetails(data:any){
    this.candidateForm.patchValue({
      jobId: data.job_position?.id,
      jobDept: data?.department
    })

  }

  onFileChange(env: any, type: string) {
    let files: any[] = [];
    this.fileArray = [];
    const maxFileSize = 10.2 * 1024 * 1024; // 10 MB
    switch (type) {
      case 'drop':
        files = env;
        break;

      case 'click':
        files = env.files
        break;

      default:
        break;
    }

    let fileTypes = ['application/pdf', '.pdf']
    for (let i = 0; i < files.length; i++) {
      const element = files[i];
      if (!fileTypes.includes(element.type)) {
        this.toast.warning('Please provide the document in the PDF format.');
        return;
      }
      if (files[i]?.size >= maxFileSize) {
        this.toast.warning('File Size Exceeded..');
        return;
      }
    }

    this.fileArray = [...this.fileArray, ...files];
    this.candidateForm.controls['document'].setValue(this.fileArray.filter(e => { return e?.id != undefined }));
    this.disableURL = true;
    this.resumeDoc  = false;
    this.candidateForm.controls["resumeURL"].reset();
    this.candidateForm.controls["resumeURL"].clearValidators();
  }


  removeFile(i: number) {
    if (this.fileArray[i]?.id) {
      this.deletedFiles.push(this.fileArray[i]?.id);
    }
    this.fileArray.splice(i, 1);
    this.disableURL = false;
    this.resumeDoc  = false;
    this.candidateForm.controls["resumeURL"].setValidators(Validators.required);
  }


  addedURL(env: any) {
    console.log(env.target.value);
    if(env.target.value !== '') {
      this.resumeDoc = true;
      this.disableURL = false;
      this.candidateForm.controls["resumeURL"].setValidators(Validators.required);
    } else {
      this.resumeDoc  = false;
      this.disableURL = false;
      this.candidateForm.controls["resumeURL"].clearValidators();
      this.candidateForm.controls["resumeURL"].updateValueAndValidity();
    }
  }


  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'e'  || event.key === '-') {
      event.preventDefault();
    }
  }

  removeJobId(){
    const job = this.candidateForm.value.job_position;
    if (job === null){
      this.candidateForm.controls['jobDept'].reset();
      this.candidateForm.controls['jobId'].reset();
    }
    
  }


  selectOption(env:any){
    if (env.name === 'Beinexian') {
      this.showEmployee = true;
      this.getBeinexEmployee();
      this.candidateForm.controls["empName"].setValidators(Validators.required);
    } else {
      this.showEmployee = false;
      this.candidateForm.controls["empName"].clearValidators()
    }

    if (env.name == 'External Referral') {
      this.showReferral = true;
      this.candidateForm.controls["referralName"].setValidators(Validators.required);
    } else{
      this.showReferral = false;
      this.candidateForm.controls["referralName"].clearValidators();
    }

  }


  addCandidate(){  
    if (!this.isEdit){
      if(this.candidateForm.valid) {
        if (this.fileArray.length === 0 && (this.candidateForm.value?.resumeURL === null || this.candidateForm.value?.resumeURL === '')) {
          this.toast.error('Resume is required');
          return;
        }
        this.isLoading = true;
        let formData = new FormData();
        let c_data = this.candidateForm.value;

        let emp_name = null
        let others = null

        if (c_data.source?.name === 'Beinexian'){
          emp_name = c_data.empName
        } else {
          emp_name = null
        }

        if (c_data.source?.name === 'External Referral'){
          others = c_data.referralName
        } else {
          others = null
        }
        this.formSubmitted = true;  
        const data = {
        "first_name": c_data.first_name,
        "last_name": c_data.last_name,
        "email": c_data.email,
        "is_active": 0,
        "phone": c_data.phone,
        "job_openings": Number(c_data.job_position),
        "source": Number(c_data.source.id),
        "source_employee": emp_name,
        "referral_institute": others,
        "experience": Number(c_data.years_of_exp),
        "gender": c_data.gender,
        "status": 5,
        "hr_feedback": c_data.hr_screening,
        "resume_link": c_data.resumeURL
        }
        formData.append('data', JSON.stringify(data));
        for (let i = 0; i < this.fileArray.filter(e => { return e?.id == undefined }).length; i++) {
          const element = this.fileArray.filter(e => { return e?.id == undefined })[i];
          formData.append(`resume`, element);
        }

        this.candidateApiService.addCandidate(formData).pipe(finalize(() => {this.isLoading = false;})).subscribe((res:any) => {
          console.log(res, 'res');
          if (res.status = 'success') {
            this.formSubmitted = false;
            this.toast.success(res.message);
            this.dialogRef.close(true);
            } else {
              setTimeout(() => {
                this.addCandidate();
              }, 10000);
            }
          },
          (err:any)=>{
            console.log(err, 'error');
            this.toast.error(err?.error?.message);
            this.formSubmitted = false;
          })
      } else {
        this.toast.warning('Please fill all mandatory field');
        this.isLoading = false;
      }
    }
  }
}
