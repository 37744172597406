import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiEndPoints } from 'src/app/core/constants/constants';
import { HttpService } from 'src/app/core/services/common/HttpService';
@Injectable({
  providedIn: 'root'
})
export class DemandApiService {

  constructor(private httpService : HttpService) { }

  addDemandDetails(data:any): Observable<any> {
    return this.httpService.post(ApiEndPoints.addDemandDetails,data);
  }

  getDemandList(filterArray: any,offset:any,limit:any,token:any): Observable<any> {
    return this.httpService.get(`${ApiEndPoints.demandList}?filter=${JSON.stringify(filterArray)}&offset=${offset}&limit=${limit}&token=${token}`);
  }

  updateEmailStatus(data:any,id:string): Observable<any> {
    return this.httpService.patch(`${ApiEndPoints.updateEmailStatus}/${id}`,data);
  }

  getDemandViewDetails(id:any) {
    return this.httpService.get(`${ApiEndPoints.demandViewDetails}/${id}`);
  }

  editDemandDetails(data:any,id:string): Observable<any> {
    return this.httpService.patch(`${ApiEndPoints.editDemandDetails}/${id}`,data);
  }

  deleteDemandDetails(id:any) {
    return this.httpService.delete(`${ApiEndPoints.deleteDemandDetails}/${id}`);
  }

}
