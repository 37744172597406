import { Component } from '@angular/core';
import { AppStore } from './store/app.store';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'main';

  textInput: string = '';
  AppStore = AppStore;

  constructor() {}
  
}
