import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {ApexAxisChartSeries,ApexTitleSubtitle,ApexDataLabels,ApexChart,ChartComponent, ApexPlotOptions, ApexLegend,} from 'ng-apexcharts';
import { AdminApiService } from '../../services/admin-api.service';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  dataLabels: ApexDataLabels | any;
  title: ApexTitleSubtitle | any;
  legend:ApexLegend | any;
  plotOptions: ApexPlotOptions |any;
};
@Component({
  selector: 'app-source-month',
  templateUrl: './source-month.component.html',
  styleUrls: ['./source-month.component.scss'],
})
export class SourceMonthComponent {

  @Input() selectedYear!: any;
  @ViewChild('chart') chart!: ChartComponent;
  public chartOptions!: Partial<ChartOptions>;
  @Input() isLoading:boolean =false;
  @Output() setLoading = new EventEmitter<any>();

  filterArray:any = {
    "year": []
  }
  constructor(private AdminApiService: AdminApiService) {}

  ngOnInit(): void {
    this.getSourceCards('');
  }

  ngOnChanges(): void {
    this.getSourceCards('');
  }

  getSourceCards(filterArray:any){
    this.isLoading = true;
    this.filterArray = filterArray;
    if(filterArray == ''){
      filterArray = {
        "year": [this.selectedYear]
      }
    }
    this.AdminApiService.getSourceGraph(filterArray).subscribe((res:any) =>{
      this.isLoading = false;
      this.setLoading.emit(this.isLoading);
      let details = {
        series: res?.series,
        chart: {
          height: 250,
          type: 'heatmap',
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },

        plotOptions: {
          heatmap: {
            shadeIntensity: 0.5,
            colorScale: {
              ranges: [
                {
                  from: -30,
                  to: 0,
                  name: "Nil",
                  color: "#D4F4F9"
                },
                {
                  from: 1,
                  to: 20,
                  name: "Low",
                  color: "#39C0EA"
                },
                {
                  from: 21,
                  to: 45,
                  name: "Medium",
                  color: "#1D99D3"
                },
                {
                  from: 46,
                  to: 55,
                  name: "High",
                  color: "#0071BC"
                }
              ]
            }
          }
        },
        legend: {
          position: 'bottom',
      },

    }
      this.chartOptions = details;
    })
  }
}
