import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/app/core/constants/constants';
import { editDelete, TableOption } from 'src/app/core/interface/table';
@Component({
  selector: 'app-table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.scss'],
})
export class TableViewComponent implements OnInit {
  @Input() data!: User[] | any[];
  @Input() tableTitle!: any[];
  @Input() tableKeys!: any[];
  @Input() isLoading!: boolean;
  @Input() tableOption!: TableOption;
  @Input() tableEditDelele!: editDelete;
  @Input() totalPages!: number;
  @Input() checkboxrequired!: boolean;
  @Output() event = new EventEmitter<any>();
  @Output() checkChange:any =  new EventEmitter();
  @Input() setDefault!: boolean;

  selection:any = new SelectionModel<any>(true, []);
  dataSource!: any;

  ngOnInit(): void {

  }


  selectOption(i: any, item: any, row: any) {
    const emitId = {
      index: i,
      action: 'action',
      id: item.id,
      row: row,
    };
    this.event.emit(emitId);
  }

  rendererList(list: any, i: number) {
    return list.filter((el: any) => {
      if (el.condition) {

        const condition = el.condition;
        return condition.value.includes(this.data[i][condition.field]);
      } else {
        return el;
      }
    });
  }

  viewDetails(i: any, row: any) {
    const emitId = {
      index: i,
      action: 'viewDetail',
      row: row,
    };
    this.event.emit(emitId);
  }

  viewInfo(i: any, row: any) {
    const emitId = {
      index: i,
      action: 'viewInfo',
      row: row,
    };
    this.event.emit(emitId);
  }

  editRow(i: any, row: any) {
    const emitId = {
      index: i,
      action: 'edit',
      row: row,
    };
    this.event.emit(emitId);
  }
  deleteRow(i: any, row: any) {
    const emitId = {
      index: i,
      action: 'delete',
      row: row,
    };
    this.event.emit(emitId);
  }

  getPage(env: any) {
    this.event.emit(env);
  }

  tableOptionCondition(status: any) {
    if (this.tableOption.optionModeVisible) {
      return this.tableOption.optionModeVisible.value.includes(status);
    }
    else {
      return false;
    }
  }

  emitData(i: any, row: any) {
    const emitId = {
      index: i,
      action: 'cell_click',
      row: row,
    };
    this.event.emit(emitId);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(rows: any) {
    const numSelected = this.selection.selected.length;
    const numRows = rows?.length;
    return numSelected === numRows;
  }


  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows(rows: any) {
    if (this.isAllSelected(rows)) {
      this.selection.clear();
      return;
    }
    this.selection.select(rows.id);
  }

  getChange(){
    this.checkChange.emit({
      type:'checkedData',
      dataChecked:this.selection.selected
    })
  }




}
