import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexStroke,
  ApexGrid,
  ApexFill,
  ApexMarkers,
  ApexYAxis,
} from 'ng-apexcharts';
export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  dataLabels: ApexDataLabels | any;
  grid: ApexGrid | any;
  fill: ApexFill | any;
  markers: ApexMarkers | any;
  yaxis: ApexYAxis | any;
  stroke: ApexStroke | any;
  title: ApexTitleSubtitle | any;
};
import { AdminApiService } from '../../services/admin-api.service';

@Component({
  selector: 'app-recruitment-chart',
  templateUrl: './recruitment-chart.component.html',
  styleUrls: ['./recruitment-chart.component.scss'],
})
export class RecruitmentChartComponent {

  @Input() selectedYear!: string |any;
  @ViewChild('chart') chart!: ChartComponent;
  public chartOptions!: Partial<ChartOptions>;

  @Input() isLoading:boolean =false;
  @Output() setLoading = new EventEmitter<any>();

  filterArray:any = {
    "year": []
  }

  constructor(private AdminApiService: AdminApiService) {}

  ngOnInit(): void {
    this.getRecruitmentCharts('');
  }

  ngOnChanges(): void {
    this.getRecruitmentCharts('');
  }

  getRecruitmentCharts(filterArray:any){
    this.isLoading = true;
    const series:any = [];
    let details: any;
    this.filterArray = filterArray;
    if(filterArray == ''){
      filterArray = {
        "year": [this.selectedYear]
      }
    }
    this.AdminApiService.getRecruitmentGraph(filterArray).subscribe((res:any) =>{
      this.isLoading = false;
      this.setLoading.emit(this.isLoading);
      series.push(res?.series);
      details = {
        series: series,
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        stroke: {
          width: 4,
          curve: 'smooth',
        },
        xaxis:{
          ...res.xaxis,
          label:{
            style: {
              colors: '#FF5733', 
            },
          }
        } ,
        markers: {
          size: 6,
          colors: ['#3EA2E5'],
          strokeColors: '#e3f4ff',
          strokeWidth: 3,
          hover: {
            size: 7,
          },
        },
        yaxis: {
          min: 0,
          max: 50,
        },
      }
      this.chartOptions = details;
    })
  }
}
