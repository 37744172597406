<div class="bnx-modal">
  <div class="bnx-modal__close-btn" [mat-dialog-close]="false">
    <svg width="15" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times"
       class="svg-inline--fa fa-times fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
       <path fill="currentColor"
          d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z">
       </path>
    </svg>
  </div>

  <div class="bnx-modal__title">
    <h2 class="bnx-modal__heading">User Add</h2>
  </div>

  <div class="bnx-view-modal__body">
    <div class="bnx-modal__wrapper">
      <form class="bnx-form">
        <div class="bnx-form__row">
          <div class="bnx-form__col">
            <div class="bnx-form__label">Job Title</div>
            <div class="bnx-form__group">
              <div class="bnx-currency-item">
                <select class="bnx-form__input" formControlName="currency">
                  <option *ngFor="let item of currencyList;let i=index" [value]="item" >{{item}}</option>
                </select>
              </div>
            </div>
          </div>

          <div class="bnx-form__col">
            <div class="bnx-form__label">Department</div>
            <div class="bnx-currency-item">
              <select class="bnx-form__input" formControlName="currency">
                <option *ngFor="let item of currencyList;let i=index" [value]="item" >{{item}}</option>
              </select>
            </div>
          </div>

          <div class="bnx-form__col">
            <div class="bnx-form__label">Recruiter</div>
            <div class="bnx-currency-item">
              <select class="bnx-form__input" formControlName="currency">
                <option *ngFor="let item of currencyList;let i=index" [value]="item" >{{item}}</option>
              </select>
            </div>
          </div>

          <div class="bnx-form__col">
            <div class="bnx-form__group">
              <input class="bnx-form__input" placeholder="name" formControlName="expense_date" type="date" />
              <div class="bnx-form__label">Target Date</div>
            </div>
          </div>

          <div class="bnx-form__col">
            <div class="bnx-form__label">Location</div>
            <div class="bnx-currency-item">
              <select class="bnx-form__input" formControlName="currency">
                <option *ngFor="let item of currencyList;let i=index" [value]="item" >{{item}}</option>
              </select>
            </div>
          </div>

          <div class="bnx-form__col">
            <div class="bnx-form__label">Status</div>
            <div class="bnx-currency-item">
              <select class="bnx-form__input" formControlName="currency">
                <option *ngFor="let item of currencyList;let i=index" [value]="item" >{{item}}</option>
              </select>
            </div>
          </div>


        </div>
      </form>
      <div class="submit">
        <button class="bnx-btn" (click)="addClient()">{{isLoading ? isEdit ? 'Updating ...':'Adding ...':isEdit ? 'Update Client':'Add Client'}}</button>
        <button class="bnx-btn bnx-btn-border">Cancel</button>
      </div>
    </div>
  </div>

</div>
