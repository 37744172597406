import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-success-alert',
  templateUrl: './success-alert.component.html',
  styleUrls: ['./success-alert.component.scss']
})
export class SuccessAlertComponent {
  // message: string = '';

  constructor(public dialogRef: MatDialogRef<SuccessAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: successmDialogModel) {
    // Update view with given values
    // this.message = data.message;

  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

}

export class successmDialogModel {
  constructor(public message: string) {
  }
}
