import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AdminApiService } from '../../services/admin-api.service';

@Component({
  selector: 'app-department-hiring',
  templateUrl: './department-hiring.component.html',
  styleUrls: ['./department-hiring.component.scss']
})
export class DepartmentHiringComponent {

  @Input() selectedYear!: string |any;
  @Input() progressDetails: any[] = [];
  yearList = ['2023','2022'];
  monthList = [{id: 1, value: 'January'}, {id: 2, value: 'February'}, {id: 3, value: 'March'}, {id: 4, value: 'April'}, {id: 5, value: 'May'},{id: 6, value: 'June'},
  {id: 7, value: 'July'},{id: 8, value: 'August'}, {id: 9, value: 'September'}, {id: 10, value: 'October'}, { id: 11, value: 'November' }, { id: 12, value: 'December' }]
  currentMonth:any = [];
  filterArray:any = {
    "year": [],
    "month": [],
  }

  constructor( private AdminApiService : AdminApiService) {}

  ngOnInit(): void {
    this.getChartFilter('');
  }

  ngOnChanges(): void {
    this.getChartFilter('');
  }

  getChartFilter(filterArray:any){
    let month = [];
    if (this.currentMonth?.length === 0) {
      month = []
    } else {
      month.push(this.currentMonth?.id);
    }

    this.filterArray = filterArray;
    if(filterArray == ''){
      filterArray = {
        "year": [this.selectedYear],
        "month": month,
      }
    }
    this.AdminApiService.getDepartmentHiring(filterArray).subscribe((res:any) =>{
      this.progressDetails = res.department_hiring;

      console.log(this.progressDetails);

    })
  }

  changeYearMonth(env:any){
    let filterMonth = [];
    if((this.currentMonth?.length === 0) || (this.currentMonth === null) || (this.currentMonth === undefined)){
      filterMonth = []
    } else{
      filterMonth.push(this.currentMonth?.id);
    }
    this.filterArray = {
      "year": [this.selectedYear],
      "month":filterMonth
    }
    this.getChartFilter(this.filterArray);
  }
}
