import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { LoginModule } from './modules/login/login.module';
import { ValidateComponent } from './authentification/validate/validate.component';
import { AdminModule } from './modules/admin/admin.module';
import { UserModule } from './modules/user/user.module';
import { SharedModule } from './shared/shared.module';
import { InterceptorService } from './core/interceptor/interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';


let msalInstance: IPublicClientApplication | undefined = undefined;

export function MSALInstanceFactory(): IPublicClientApplication {
  msalInstance = msalInstance ?? new PublicClientApplication({
    auth: {
      // clientId: 'bf48d57c-6b5c-4222-9868-757a6da3c74a', //prod
      // clientId: '7cf604e5-3afd-44c5-8c12-275234a55ae0', //staging
      clientId: environment.clientId,
      authority: 'https://login.microsoftonline.com/1a08dfdb-faef-4883-94b8-41c24c35f994',
      redirectUri: environment.baseUrl
      // redirectUri: 'https://recruit.beinex.com', //prod
      // redirectUri: 'http://localhost:4200', //local
      // redirectUri: 'https://recruit-staging.beinex.com', //stag
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage ,
    }
  });

  return msalInstance;
}
@NgModule({
  declarations: [
    AppComponent,
    ValidateComponent

  ],
  imports: [
    CoreModule,
    SharedModule,
    LoginModule,
    AdminModule,
    BrowserAnimationsModule,// required for toastr
    ToastrModule.forRoot({// required for toastr
      timeOut: 3000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      countDuplicates: false,
      closeButton: true
    }),
    BrowserModule,
    AppRoutingModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    MsalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
