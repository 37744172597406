import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import { CandidateMasterService } from 'src/app/modules/user/services/candidate-master.service';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-profile-image-add',
  templateUrl: './profile-image-add.component.html',
  styleUrls: ['./profile-image-add.component.scss']
})
export class ProfileImageAddComponent {
  profileFileUpload!: FormGroup;
  fileArray: any[] = [];
  isLoading = false;
  imgeBinary: any;
  imageUrl: any = '';
  errorMsg: boolean = false;
  editFile: boolean = true;
  removeUpload: boolean = false;

  constructor(private commonService: CommonService, public candidateService: CandidateMasterService,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any, private toast: ToastrService, public fb: FormBuilder,
    public dialogRef: MatDialogRef<ProfileImageAddComponent>) { }

  ngOnInit(): void {
    this.initForm();
  }


  initForm() {
    this.profileFileUpload = this.fb.group({
      filUpload: new FormControl(''),
    });
  }


  onFileChange(files: any) {
    const reader = new FileReader();
    this.fileArray = files;

    const file = this.fileArray[0].file;
    this.imgeBinary = file;
    if (file.size < 512000) {
      if (file) {
        reader.readAsDataURL(file);

        // When file uploads set it to file formcontrol
        reader.onload = () => {
          this.imageUrl = reader.result;
          this.profileFileUpload.patchValue({
            file: reader.result,
          });
          this.editFile = false;
          this.removeUpload = true;
        };
        // ChangeDetectorRef since file is loading outside the zone
        this.cd.markForCheck();
      }
    } else {
      this.errorMsg = true;

      setTimeout(() => {
        this.errorMsg = false;
      }, 3000);

    }
    // let files: any[] = [];
    // switch (type) {
    //   case 'drop':
    //     files = env;
    //     break;

    //   case 'click':
    //     files = env.files
    //     break;

    //   default:
    //     break;
    // }

    // let fileTypes = ['.xls', '.xlsx', 'application/pdf', '.pdf', 'text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', '.csv', '.doc', '.docx', '.xml', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
    // let invC = true
    // for (let i = 0; i < files.length; i++) {
    //   const element = files[i];
    //   if (!fileTypes.includes(element.type)) {
    //     invC = false;
    //   }
    // }
    // if (!invC) {
    //   this.toast.warning('Invalid doc');
    //   return;
    // }

    // this.fileArray = [...this.fileArray, ...files];
  }


  uploadFile(event: any) {
    const reader = new FileReader(); // HTML5 FileReader API
    const file = event.target.files[0];
    this.imgeBinary = event.target.files[0];
    if (file.size < 512000) {
      if (event.target.files && event.target.files[0]) {
        reader.readAsDataURL(file);

        // When file uploads set it to file formcontrol
        reader.onload = () => {
          this.imageUrl = reader.result;
          this.profileFileUpload.patchValue({
            file: reader.result,
          });
          this.editFile = false;
          this.removeUpload = true;
        };
        // ChangeDetectorRef since file is loading outside the zone
        this.cd.markForCheck();
      }
    } else {
      this.errorMsg = true;

      setTimeout(() => {
        this.errorMsg = false;
      }, 3000);

    }
  }


  removeUploadedFile() {
    this.imageUrl = '';
  }



  uploadImage() {
    let formData = new FormData();
    formData.append('data', this.imgeBinary);
    this.candidateService.uploadCandidateImage(formData,this.data).pipe(finalize(() => {
      this.isLoading = false;
    }))
      .subscribe((res: any) => {
        if (res.status == 'success') {
          this.toast.success(res.message);
          this.dialogRef.close(true);
        }
      }, (err: any) => {
        console.log(err);
        if (err.error.status == 'failed') {
          this.toast.error(err.error?.message);
        }
      })
  }

}
