import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiEndPoints } from 'src/app/core/constants/constants';
import { HttpService } from 'src/app/core/services/common/HttpService';

@Injectable({
  providedIn: 'root'
})
export class AdminApiService {

  constructor(private http : HttpService) { }

  interviewSheduleList(data:any){
    return this.http.get(ApiEndPoints.interviewList)
  }

  getDepartmentCards(filterArray:any): Observable<any> {
    return this.http.get(`${ApiEndPoints.chartFilter}?request_for=cards&filter=${JSON.stringify(filterArray)}`);
  }

  getSourceGraph(filterArray:any): Observable<any> {
    return this.http.get(`${ApiEndPoints.sourceMonth}?filter=${JSON.stringify(filterArray)}`);
  }

  getRecruitmentGraph(filterArray:any): Observable<any> {
    return this.http.get(`${ApiEndPoints.recuritmentChart}?filter=${JSON.stringify(filterArray)}`);
  }


  getDepartmentHiring(filterArray:any): Observable<any> {
    return this.http.get(`${ApiEndPoints.chartFilter}?request_for=department_hiring&filter=${JSON.stringify(filterArray)}`);
  }

  getInterviewCount(filterArray:any,token:any): Observable<any> {
    return this.http.get(`${ApiEndPoints.interviewCount}?filter=${JSON.stringify(filterArray)}&token=${token}`);
  }

  getInterviewList(filterArray:any,offset:any,limit:any,token:any): Observable<any> {
    return this.http.get(`${ApiEndPoints.interviewsList}?filter=${JSON.stringify(filterArray)}&offset=${offset}&limit=${limit}&token=${token}`);
  }

  getUpcommingInterviewsList(filterArray:any,offset:any,limit:any,token:any): Observable<any> {
    return this.http.get(`${ApiEndPoints.upcomminginterviewsList}?filter=${JSON.stringify(filterArray)}&offset=${offset}&limit=${limit}&token=${token}`);
  }
}
