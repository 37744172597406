<div class="bnx-common__heading-wrp ">
  <div class="bnx-common__subhead">Candidate Inflow Channel Stats</div>
</div>

<div class="bnx-source-body">
  <div class="bnx-source-body__card-body">
    <div class="bnx-source-body__wrapper">

      <apx-chart [series]="chartOptions?.series"  [legend]="chartOptions?.legend" [chart]="chartOptions?.chart" [dataLabels]="chartOptions?.dataLabels" [title]="chartOptions?.title" [plotOptions]="chartOptions?.plotOptions"></apx-chart>

    </div>
  </div>
</div>
