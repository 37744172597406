import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../service/login.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FunctionHelperService } from 'src/app/core/helper/function.helper.service';
import { loginUrls, returnUrl, userRole } from 'src/app/core/constants/constants';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-candidate-login-page',
  templateUrl: './candidate-login-page.component.html',
  styleUrls: ['./candidate-login-page.component.scss']
})
export class CandidateLoginPageComponent implements OnInit {

  CandidateloginForm!: FormGroup;
  show_button: Boolean = false;
  show_eye: Boolean = false;
  forgot_password: boolean = false;
  add_username:boolean = false;
  isLoading = false;

  constructor(public login: LoginService, private fb: FormBuilder, private toster: ToastrService,
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    private helper: FunctionHelperService) { }

  ngOnInit(): void {
    this.setForm();
  }

  setForm() {
    this.CandidateloginForm = this.fb.group({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      new_password: new FormControl('', Validators.required),
      confirm_password: new FormControl('', Validators.required),
      email_otp: new FormControl(''),
      username: new FormControl('', Validators.required),
    });
  }

  // loginMicrosoft() {
  //   this.login.loginSaml().subscribe((res: any) => {
  //     // if (res.status === ApiStatus.ok) {
  //       window.open(res.url, '_self');
  //   //  }
  //   });
  // }

  showPassword() {
    this.show_button = !this.show_button;
    this.show_eye = !this.show_eye;
  }

  sendOtp() {
    this.login.email_otp = this.CandidateloginForm.controls['username'].value;
    if (this.CandidateloginForm.controls['username'].value !== '') {
      this.isLoading = true;
      let formData = new FormData();
      formData.append('email', this.login.email_otp);
      this.login.loginCandidatePassword(formData).pipe(
        finalize(() => {
          this.isLoading = false;
        })
      ).subscribe((res) => {
        this.toster.success(res.message);
        this.add_username = false;
      },(err =>{
        this.toster.error(err.error?.message);
      }))
    }
    else {
      this.toster.warning('Email Id is required');
    }

  }

  addUsername(){
    this.add_username = !this.add_username;
    this.forgot_password =! this.forgot_password;
    const data = this.CandidateloginForm.value
    this.CandidateloginForm.controls['username'].patchValue(data.email);

  }

  back(){
    this.add_username = !this.add_username;
    this.forgot_password =! this.forgot_password;
  }


  onLogin() {
    const data = this.CandidateloginForm.value;
    if (data.email !== '' && data.password !== '') {
      let formData = new FormData();
      formData.append('email', data.email);
      formData.append('password', data.password);

      this.login.login(formData).subscribe((res: any) => {
        let returnUrlValue = this._activatedRoute.snapshot.queryParamMap.get(returnUrl);
        const data: any = { 'key': res.key, 'user_name': res.user_name, 'candidate_id': res.candidate_id,'user_type': res.user_type, 'status': 200,'user_id': res.user_id };
        this.helper.setUser(data);
          this.toster.success(res?.message);
          if(data.user_type === 'HR'){
            this.router.navigate([returnUrlValue || loginUrls.dashboard]);
          }
          else if (data.user_type === 'Interviewer'){
            this.router.navigate([returnUrlValue || loginUrls.employee]);
          }
          else if (data.user_type === 'SLT CEO' || data.user_type === 'SLT Directors'){
            this.router.navigate([returnUrlValue || loginUrls.dashboard]);
          }
          else if (data.user_type === 'CANDIDATE'){
            this.router.navigate([returnUrlValue || loginUrls.candidateProfile]);
          }
      },(err =>{
        this.toster.error(err.error?.message);
      }))
    }
    else {
      this.toster.warning('Fields are required');
    }
  }

  onSubmit() {
    const data = this.CandidateloginForm.value;
    if (data.new_password !== '' && data.confirm_password !== '') {
      if (data.new_password === data.confirm_password) {

        let formData = new FormData();
        formData.append('email', data.username);
        formData.append('otp', data.email_otp);
        formData.append('password', data.new_password);
        formData.append('confirm_password', data.confirm_password);

        this.login.candidateChangePassword(formData).subscribe((res) => {
          this.toster.success(res);
          this.forgot_password = !this.forgot_password;
          this.CandidateloginForm.reset();
        },
        (err) => {
          this.toster.error(err.error?.message);
        })
      }
      else {
        this.toster.warning('Password should be same');
      }
    }
    else {
      this.toster.warning('Fields are required');
    }
  }


}
