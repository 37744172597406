import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';
import { ContractCommonService } from 'src/app/modules/user/services/contract-common.service';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-searchable-select',
  templateUrl: './searchable-select.component.html',
  styleUrls: ['./searchable-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchableSelectComponent),
      multi: true,
    },
  ],
})
export class SearchableSelectComponent implements ControlValueAccessor {
  title: string = '';
  @Input() data: any[] = [];
  @Input() nameField: any = '';
  @Input() validationMsg: any = '';
  @Input() idField!: any;
  @Input() clientName!: any;
  @Input() select_!: boolean;
  @Input() id: any;
  @Input() placeholder!: string;
  searchFilter: String | null = '';
  errorShow = false;
  @Input() formGroup!: FormGroup;
  @Input() formName!: string;
  @Input() modalName!: any;
  isEdit = false;
  @Input() asterRisk = false;
  @Input() isloading: boolean = false;

  dataFilterCtrl: FormControl<string | null> = new FormControl<string>('');
  @Output() event = new EventEmitter();
  @Output() selecteditem = new EventEmitter();

  @ViewChild('input', { static: false }) input: ElementRef | any;

  onChange: any = () => {};
  onTouch: any = () => {};
  value: any = 'Select';
  shown = false;
  _select = false;

  @Input() isAdd: boolean = false;

  constructor(
    private ele: ElementRef,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private contractService: ContractCommonService,
    private commonService: CommonService
  ) {}
  writeValue(obj: any): void {}
  setDisabledState?(isDisabled: boolean): void {}
  ngOnInit() {
    this.title = this.placeholder;
    this.initForm();

    this.commonService.getId().subscribe((res) => {
      if (res === 'patch_') {
        this._select = true;
        this.select_ = true;
        this.isEdit = true;
      } else if (res === 'tab_change') {
        this.isEdit = false;
        this._select = false;
        this.title = this.placeholder;
      }
    });

    // this.contractService.getId()
    // .subscribe(res=>{
    //    this.select_

    // })
  }

  ngOnChanges() {
    if (this.isEdit) {
      this.asterRisk = false;
      this.title = this.placeholder;
    }

    if (!this.select_) {
      this.title = this.placeholder;
    }

    this.dataFilterCtrl.valueChanges.subscribe((val: any) => {
      this.searchFilter = val;
    });
  }

  initForm() {
    this.formGroup = this.fb.group({
      formName: new FormControl(),
    });
  }
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  select(item: any,nameField?: any) {
    this.asterRisk = false;
    this.event.emit({
      id: item[this.idField],
      name: item[this.nameField],
      nameField : nameField
    });
    this.selecteditem.emit(item);
    this.title = item[this.nameField];
    this._select = true;
    this.select_ = true;
    this.shown = false;
    this.isEdit = false;
    this.asterRisk = false;
  }
  show() {
    if (this.id !== '') {
      this.errorShow = false;
      this.shown = this.shown ? false : true;
      setTimeout(() => {
        this.input.nativeElement.focus();
      }, 200);
    } else {
      this.errorShow = true;
    }
  }
  @HostListener('document:click', ['$event']) onClick(e: any) {
    if (!this.ele.nativeElement.contains(e.target)) {
      this.shown = false;
    }
  }

  addItem() {
    if (this.modalName) {
      this.openCommonModal();
    } else {
      // let dialogRef = this.dialog.open(AddProjectsComponent, {
      //   width: '650px',
      //   panelClass: 'warning-dialog',
      //   data: {
      //     clientName: this.clientName,
      //     clientId: this.id,
      //   },
      // });

      // dialogRef.afterClosed().subscribe((res) => {
      //   if (res) {
      //     this.contractService.setChange('api_updated');
      //   }
      // });
    }
  }

  openCommonModal() {
    // let dialogRef = this.dialog.open(CommonModalComponent, {
    //   width: this.modalName.modalWidth,
    //   data: {
    //     modal: this.modalName,
    //     populateField: this.clientName,
    //     id: this.id,
    //   },
    // });

    // dialogRef.afterClosed().subscribe((res) => {
    //   if (res) {
    //     this.contractService.setChange(this.modalName.id);
    //   }
    // });
  }
}
