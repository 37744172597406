import { Component } from '@angular/core';
import { AdminApiService } from '../../services/admin-api.service';
import { DatePipe } from '@angular/common';
import { TableOption, editDelete } from 'src/app/core/interface/table';
import { finalize } from 'rxjs';
import { FunctionHelperService } from 'src/app/core/helper/function.helper.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { DownloadService } from 'src/app/core/services/common/download.service';

@Component({
  selector: 'app-upcomming-interviews-panel',
  templateUrl: './upcomming-interviews-panel.component.html',
  styleUrls: ['./upcomming-interviews-panel.component.scss']
})
export class UpcommingInterviewsPanelComponent {

  setDefaultPage: boolean =false;
  tableEditDelele: editDelete = {
    edit: false,
    delete: false,
  };
  tableTitle = [
    'Candidate Name',
    'Department',
    'Job Title',
    'Scheduled',
    'Status',
    'Level',
    ''
  ];

  isLoading: boolean = false;
  tableKey = [
    'candidateName',
    'department',
    'jobTitle',
     'scheduled',
     'status',
     'level',
     ''
    ];

    tableOption: TableOption = {
      viewMode: true,
      optionMode: false,
      infoMode: false,
    }

  filterArray: any = {
    "department": [],
    "job_opening_status": [],
  }
  totalPages!: number;
    tableLimit = 10;
    page = 1;

  candidateList = []
  interviewStatusList = [
    { status: 'In Progress', color: '#17a2b8' },
    { status: 'Pending', color: '#ffc107' },
    { status: 'Shortlist', color: '#007bff' },
    { status: 'Completed', color: '#28a745' },
    { status: 'On hold', color: '#6c757d' },
    { status: 'Rejected', color: '#dc3545' }
  ];

  constructor(private adminApiService:AdminApiService, private router: Router,private datePipe :DatePipe, private helper: FunctionHelperService,
    private downloadService: DownloadService
  ){}


  ngOnInit(): void {
    this.getInterviewUpcommingList('')
  }

  getChange(env: any) {
    switch (env.action) {
      case 'pageLimit':
        this.tableLimit = env.pageLimit;
        this.getInterviewUpcommingList(this.filterArray);
        break;
        case 'page':
        this.page = env.page;
        this.getInterviewUpcommingList(this.filterArray);
        break;
        case 'viewDetail':
          this.getDetails(env?.row?.candidate_id);
          break;
      default:
        break;
    }
  }

  getInterviewUpcommingList(filterArray: any): void {
    const token = this.helper.userData.key;
      this.filterArray = filterArray;
      if (filterArray == '') {
        filterArray = {
          "job_opening_status": [],
          "department": [],
        }
      }
      this.isLoading = true;
      this.adminApiService.getUpcommingInterviewsList(filterArray, (this.page - 1) * this.tableLimit, this.tableLimit, token)
        .pipe(
          finalize(() => { this.isLoading = false; })
        )
         .subscribe((res: any) => {
           this.isLoading = false;
           this.setDefaultPage = false;
           this.totalPages = res?.count % this.tableLimit == 0 ? Math.trunc(res?.count / this.tableLimit) : Math.trunc(res.count / this.tableLimit) + 1;
           this.candidateList = res.results.map((item: any) => {
            let tableColor = this.interviewStatusList.filter((res: any) => {
              if (res.status == item.status) {
                return res.color;
              }
            })
            return {
              id: item?.id,
              jobTitle: item.candidate.job_openings.job_title,
              candidateName: item.candidate.name,
              candidate_id: item.candidate.id,
              department: item.candidate.department,
              scheduled: item.date,
              status: {
                label: item?.status,
                color: tableColor[0]?.color
              },
              level: item.level
            }
          })
        })
    }


  getDetails(id:any){
    this.router.navigate(['/main/user/interviewer-candidate-panel'], { queryParams: { id: id } });
    // this.router.navigate(['/main/user/interview-panel'],{ queryParams: { id: id }});
  }

  getFilteredChange(env:any){
    this.tableLimit = 10;
        this.page = 1;
        this.setDefaultPage = true
    switch (env.type) {
      case 'search':
        this.getInterviewUpcommingList(env.filterData);
        break;
      case 'filter':
        this.getInterviewUpcommingList(env.filterData);
        break;
      case 'clear':
        this.getInterviewUpcommingList(env.filterData);
        break;
      default:
        break;
    }
    
  }

    downloadExcel(filterArray: any): void{
      const token = this.helper.userData.key;
      if(filterArray == ''){
        filterArray = {
          "location" : [],
          "job_position" : [],
          "job_opening_status" : [],
         }
      }
      
      const apiUrl = environment.baseUrl + environment.apiVersion;
      this.downloadService
        .download(
          `${apiUrl}interviewer-upcoming-interview-export/xls_download?filter=${JSON.stringify(filterArray)+'&token='+token}`
        )
        .subscribe((data) => {
          this.downloadService.createATagAndClickDownload(data, {
            name: 'Upcomming Interview List.xls',
          });
        });

      // window.open(apiUrl + 'interviewer-upcoming-interview-export/xls_download?filter='+ JSON.stringify(filterArray)+'&token='+token);
      }


}
