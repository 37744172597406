<div class="login-wrappper-main">
  <div class="login-wrappper-main__login-left">
    <div class="login-wrappper-main__content">
      <h3>Welcome to</h3>
      <h2>Beinex Recruitment Portal</h2>
    </div>
  </div>
  <div class="login-wrappper-main__login-right">
    <div class="login-wrappper-main__login-content">
      <img class="login-wrappper-main__logo" src="assets/images/logo.svg" alt="Beinex Logo"/>
        <div class="login-wrappper-main__login-micro">
          <div class="login-wrappper-main__microsoft-logo">
            <img src="assets/images/microsoft-logo.svg" alt="Microsoft Logo">
          </div>
          <div class="login-wrappper-main__login-btn">
            <button (click)="loginMicrosoft()">Login with your Microsoft Account <span><img src="assets/images/right-arrow.svg" alt="right arrow"></span></button>
          </div>
        </div>
        <div class="login-wrappper-main__superadmin">
            <!-- <button routerLink="/login/login1">Login Super Admin <span><img src="assets/images/right-arrow.svg" alt="right arrow"></span></button> -->
        </div>
    </div>
  </div>
</div>
