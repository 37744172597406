
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiStatus, loginUrls, userRole } from 'src/app/core/constants/constants';
import { FunctionHelperService } from 'src/app/core/helper/function.helper.service';
import { LoginService } from '../../service/login.service';

@Component({
  selector: 'app-sub-login',
  templateUrl: './sub-login.component.html',
  styleUrls: ['./sub-login.component.scss']
})
export class SubLoginComponent implements OnInit{

  public loginForm! : FormGroup;
  constructor(
    private formBuilder : FormBuilder,
    private login : LoginService,
    private _activatedRoute : ActivatedRoute,
    private router: Router,
    private toster : ToastrService,
    private helper:FunctionHelperService
    )
    {}
  ngOnInit(): void {
    this.setForm();
  }


  setForm() {
    this.loginForm = this.formBuilder.group({
      email: [''],
      password: [''],
    });
  }
  onSubmit(){
    this.login.login(this.loginForm.value).subscribe((res: any) => {
      // if (res.status === ApiStatus.ok) {

      let returnUrlValue =  this._activatedRoute.snapshot.queryParamMap.get(returnUrl);

      const data:any = {'key': res.key,'user_name': res.user_name, 'user_type': res.user_type,'status': 200, 'user_id': res.user_id};
      this.helper.setUser(data);
      if (res.user_type === userRole.admin || res.user_type === userRole.hr || res.user_type === userRole.director || res.user_type === userRole.ceo)  {
        this.toster.success(res?.message)
        this.router.navigate([returnUrlValue || loginUrls.dashboard]);
      }else if(res.user_type === "Interviewer"){
        this.router.navigate([returnUrlValue || loginUrls.employee]);
      }
      else {
        this.toster.error(res?.message ||  res?.detail);
      }

    },(err:any)=>{
      this.toster.error(err?.error?.message);
    })
  }

}
export const returnUrl = 'return'
