import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { loginUrls } from '../constants/constants';
import { FunctionHelperService } from '../helper/function.helper.service';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionGuard implements CanActivate {

  constructor(
    private _router: Router,
    private _helper: FunctionHelperService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this._helper.userData?.user_type ==="HR" && !state.url.includes('home')) {
        return true;
      }
      else if ((this._helper.userData?.user_type ==="SLT CEO" || this._helper.userData?.user_type ==="SLT Directors" ) && !state.url.includes('user-manage') && !state.url.includes('home')){
        return true;
      }
      else if (this._helper.userData?.user_type ==="Interviewer" && state.url.includes('home') && !state.url.includes('index')){
        return true;
      }
      else if (this._helper.userData?.user_type ==="Interviewer" && state.url.includes('interview-panel')){
        return true;
      }
      else if (this._helper.userData?.user_type==="CANDIDATE" && !state.url.includes('home') && !state.url.includes('index') && !state.url.includes('candidate') && !state.url.includes('demand') && !state.url.includes('user-manage')
      && !state.url.includes('job-master') && !state.url.includes('interview-schedule') &&  !state.url.includes('offer-status')) {
        return true;
      }
      else
       {
        this._router.navigate([loginUrls.login]);
          return false;
      }
  }

}
