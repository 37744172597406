<div class="bnx-modal">
  <div class="bnx-modal__close-btn" [mat-dialog-close]="false">
    <svg width="15" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times"
      class="svg-inline--fa fa-times fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
      <path fill="currentColor"
        d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z">
      </path>
    </svg>
  </div>

  <div class="bnx-modal__title">
    <h2 class="bnx-modal__heading">Candidate Name : {{candidate || '-'}}</h2>
  </div>

  <div class="bnx-view-modal__body pt-3 pb-3">
    <div class="bnx-modal__wrapper">
      <div class="bnx-table-view">
        <table  class="bnx-table-wrapper__bnx-table bnx-scroll-box">
          <thead class="bnx-thead">
            <tr>
              <th *ngFor="let title of tableTitle">{{title}}</th>
            </tr>
          </thead>
          <tbody class="bnx-body">
            <tr *ngFor="let item of historyDetails ; let i=index">
              <td>{{item?.date | date: 'dd-MMM-yyyy'}}</td>
              <td>{{item?.jobTitle}}</td>
            </tr>

          </tbody>

        </table>
        <div *ngIf="(historyDetails[0]?.date === undefined) && (historyDetails[0]?.jobTitle === undefined)" class="no-data">
          No Data Found
        </div>
        </div>
    </div>
  </div>
</div>
