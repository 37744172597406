import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiEndPoints } from 'src/app/core/constants/constants';
import { HttpService } from 'src/app/core/services/common/HttpService';
import { environment } from 'src/environments/environment.prod';



@Injectable({
  providedIn: 'root'
})
export class CommonService {


  status = new BehaviorSubject<any>('');
  notifList:any[]=[];
  notifyLoading =false;
  slt_directors:boolean = false;

  headerCalender: boolean = false;
  // id list ->>
  // patch_
  // id_for_country
  // id_for_client

  constructor(
    private httpService : HttpService,
    public router: Router,
  ) { }

  getId(): Observable<any> {
    return this.status;
  }

  setId(data: any): void {
    this.status.next(data);
  }

  getNotificationList(): Observable<any> {
    return this.httpService.get(ApiEndPoints.notifList);
  }

  notifMarkAllRead(): Observable<any> {
    return this.httpService.get(ApiEndPoints.markAsRead);
  }

  notifCount(): Observable<any> {
    return this.httpService.get(ApiEndPoints.notifCount);
  }

  claerAll(): Observable<any> {
    return this.httpService.get(ApiEndPoints.clearAll);
  }

  downloadExpenseHistory(id: any) {
    return (
      `${environment.baseUrl}${environment.apiVersion}expense-history/${id}/download`
    );
  }

  downloadContractHistory(id: any) {
    return (
      `${environment.baseUrl}${environment.apiVersion}contract-history/${id}/download`
    );
  }


  genericCreate(endpoint:string,data:any): Observable<any> {
    return this.httpService.post(endpoint,data);
  }
  genericRead(endpoint:string): Observable<any> {
    return this.httpService.get(endpoint);
  }


  getJobPosition(): Observable<any> {
    return this.httpService.get(`${ApiEndPoints.jobPositionList}?filter={%22q%22:%22%22}`);
  }


  getDepartmentList(): Observable<any> {
    return this.httpService.get(`${ApiEndPoints.departmentList}?filter={%22q%22:%22%22}`);
  }

  getJobStatus(): Observable<any> {
    return this.httpService.get(ApiEndPoints.jobStatus);
  }

  getJRecruiterList(filterArray:any): Observable<any> {
    return this.httpService.get(`${ApiEndPoints.recruiterList}?filter=${JSON.stringify(filterArray)}`);
  }

  getCountryList(): Observable<any> {
    return this.httpService.get(`${ApiEndPoints.countryList}?filter={%22q%22:%22%22}`);
  }

  getSkillsList():  Observable<any> {
    return this.httpService.get(`${ApiEndPoints.skillList}?filter={%22q%22:%22%22}`);
  }

  getCandidateSource(): Observable<any> {
    return this.httpService.get(ApiEndPoints.candidateSource);
  }

  getCandidateStatus(): Observable<any> {
    return this.httpService.get(ApiEndPoints.candidateStatus);
  }

  getCandidateExp(): Observable<any> {
    return this.httpService.get(ApiEndPoints.candidateExp);
  }

  getCandidateJobOpenings(): Observable<any> {
    return this.httpService.get(ApiEndPoints.candidateJobOpening);
  }

  getNoticePeriodList():  Observable<any> {
    return this.httpService.get(ApiEndPoints.noticePeriodList);
  }

  getQualificationList(): Observable<any>{
    return this.httpService.get(ApiEndPoints.qualificationList);
  }

  getCertificationList(): Observable<any>{
    return this.httpService.get(ApiEndPoints.certificationList);
  }

  genericPut(endpoint:string,id:string, data?: any): Observable<any> {
    return this.httpService.put(`${endpoint}/${id}`,data);
  }
  genericDelete(endpoint:string,id:string): Observable<any> {
    return this.httpService.delete(`${endpoint}/${id}`);
  }

  getCurrencyList(): Observable<any>{
    return this.httpService.get(ApiEndPoints.currentList);
  }
  genericDetails(endpoint:string,id:any){
    return this.httpService.get(`${endpoint}/${id}`);
  }

  getRoles():  Observable<any> {
    return this.httpService.get(ApiEndPoints.roleList);
  }

  getBeinexEmployee(): Observable<any>{
    return this.httpService.get(ApiEndPoints.beinexEmployees);
  }

  getDepartmentCards(filterArray:any): Observable<any> {
    return this.httpService.get(`${ApiEndPoints.chartFilter}?request_for=cards&filter=${JSON.stringify(filterArray)}`);
  }
}
