import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import { FunctionHelperService } from 'src/app/core/helper/function.helper.service';
import { CandidateMasterService } from 'src/app/modules/user/services/candidate-master.service';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-candidate-feedback',
  templateUrl: './candidate-feedback.component.html',
  styleUrls: ['./candidate-feedback.component.scss']
})
export class CandidateFeedbackComponent {

  candidateFeedbackForm!: FormGroup;
  isLoading = false;
  fileArray: any[] = [];
  fileArray2: any[] = [];
  deletedFiles: any[] = [];
  deletedFiles2: any[] = [];
  panelID: any;
  hideDetails:boolean = false;
  isEdit : boolean = false;
  id : any;
  loggedUserType: any;
  communication = ['Fluent', 'Proficient', 'Intermediate'];
  attitude = ['Good', 'Average', 'Poor'];
  rating = ['1 - Unacceptable', '2 - Poor', '3 - Good', '4 - Excellent', '5 - Outstanding'];
  status = ['Shortlist', 'On hold', 'Rejected', 'Pending' , 'Completed', 'In Progress'];

  constructor(private commonService: CommonService, private formBuilder: FormBuilder, private toast: ToastrService,
    private helper: FunctionHelperService,
    public dialogRef: MatDialogRef<CandidateFeedbackComponent>, @Inject(MAT_DIALOG_DATA) public panelData: any, private candidateApiService: CandidateMasterService,

   ) { }

  ngOnInit(): void {
    this.initForm();
    this.hideMachineDetails(this.panelData);
    this.getFeedbackEditDetails();
    this.loggedUserType = this.helper.userData?.user_type;
  }

  getFeedbackEditDetails() {
    let res = this.panelData;
    let attitude = res?.attitude;
    let updatedRemark = res?.remarks;
    
    if(attitude === "-") {
      this.isEdit = false;
    } else {
      this.isEdit = true;
    }

    if (res.remarks.includes('<br>')) {
      updatedRemark = res.remarks.replace(/<br\s*\/?>/g, '\n');
    }

    this.candidateFeedbackForm.patchValue({
      communication: res.communication,
      attitude : res.attitude || null,
      rating : res.rating === 1 ? '1 - Unacceptable' : res.rating === 2 ? '2 - Poor' : res.rating === 3 ? '3 - Good' : res.rating === 4 ? '4 - Excellent' : res.rating === 5 ? '5 - Outstanding' : 0 ,
      status : res.status.label,
      remark : updatedRemark,
      negotiable_ctc: res?.negotiable_ctc
    })

  }

  initForm(): void {
    this.candidateFeedbackForm = this.formBuilder.group({
      id: new FormControl(''),
      communication: new FormControl(null, Validators.required),
      attitude: new FormControl(null, Validators.required),
      rating: new FormControl(null, Validators.required),
      status: new FormControl(null, Validators.required),
      machinetestUrl: new FormControl(''),
      remark: new FormControl('', [Validators.required]),
      documentOutput: new FormControl(''),
      documentSOW: new FormControl(''),
      level: new FormControl(null),
      negotiable_ctc: new FormControl()
    })
  }


  onFileChange(env: any, type: string) {
    let files: any[] = [];
    switch (type) {
      case 'drop':
        files = env;
        break;

      case 'click':
        files = env.files
        break;

      default:
        break;
    }

    let fileTypes = ['.xls', '.xlsx', 'application/pdf', '.pdf', 'text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', '.csv', '.doc', '.docx', '.xml', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
    let invC = true
    for (let i = 0; i < files.length; i++) {
      const element = files[i];
      if (!fileTypes.includes(element.type)) {
        invC = false;
      }
    }
    if (!invC) {
      this.toast.warning('Invalid doc');
      return;
    }

    this.fileArray = [...this.fileArray, ...files];
    this.candidateFeedbackForm.controls['documentOutput'].setValue(this.fileArray.filter(e => { return e?.id != undefined }));
  }

  removeFile(i: number) {
    if (this.fileArray[i]?.id) {
      this.deletedFiles.push(this.fileArray[i]?.id);
    }
    this.fileArray.splice(i, 1);
  }


  onFileOutput(env: any, type: string) {
    let files2: any[] = [];
    switch (type) {
      case 'drop':
        files2 = env;
        break;

      case 'click':
        files2 = env.files
        break;

      default:
        break;
    }

    let fileTypes = ['.xls', '.xlsx', 'application/pdf', '.pdf', 'text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', '.csv', '.doc', '.docx', '.xml', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
    let invC = true
    for (let i = 0; i < files2.length; i++) {
      const element = files2[i];
      if (!fileTypes.includes(element.type)) {
        invC = false;
      }
    }
    if (!invC) {
      this.toast.warning('Invalid doc');
      return;
    }

    this.fileArray2 = [...this.fileArray2, ...files2];
    this.candidateFeedbackForm.controls['documentSOW'].setValue(this.fileArray2.filter(e => { return e?.id != undefined }));
  }


  removeFile2(i: number) {
    if (this.fileArray2[i]?.id) {
      this.deletedFiles2.push(this.fileArray2[i]?.id);
    }
    this.fileArray2.splice(i, 1);
  }


  hideMachineDetails(data:any){
    this.panelID = data.id;
    if (data.level !== 'L0'){
      this.hideDetails = true;
    }

  }


  submit() {
    if (!this.isEdit) {
    this.candidateFeedbackForm.markAllAsTouched();
    if (this.candidateFeedbackForm.valid) {
      this.isLoading = true;
      let formData = new FormData();
      let c_data = this.candidateFeedbackForm.value;
      const data = {
        "communication": c_data.communication,
        "attitude": c_data.attitude,
        "rating": c_data?.rating?.split(" - ")[0],
        "remarks": c_data.remark,
        "status": c_data.status,
        "negotiable_ctc": c_data?.negotiable_ctc
      }
      formData.append('data', JSON.stringify(data));

      for (let i = 0; i < this.fileArray.filter(e => { return e?.id == undefined }).length; i++) {
        const element = this.fileArray.filter(e => { return e?.id == undefined })[i];
        formData.append(`machine_test_sow`, element);
      }

      for (let i = 0; i < this.fileArray2.filter(e => { return e?.id == undefined }).length; i++) {
        const element2 = this.fileArray2.filter(e => { return e?.id == undefined })[i];
        formData.append(`machine_test_output`, element2);
      }

      this.candidateApiService.updateCandidatePanel(formData, this.panelID).pipe(finalize(() => {
        this.isLoading = false;
      })
      ).subscribe((res: any) => {
          if (res.status = 'success') {
            this.toast.success(res.message);
            this.dialogRef.close(true);
          }
    });
  }
} else {
  this.editFeedbackSubmit();
}
}

editFeedbackSubmit() {

  this.candidateFeedbackForm.markAllAsTouched();
    if (this.candidateFeedbackForm.valid) {
      this.isLoading = true;
      let formData = new FormData();
      let c_data = this.candidateFeedbackForm.value;
      const data = {
        "communication": c_data.communication,
        "attitude": c_data.attitude,
        "rating": c_data?.rating?.split(" - ")[0],
        "remarks": c_data.remark,
        "status": c_data.status,
        "negotiable_ctc": c_data?.negotiable_ctc
      }
      formData.append('data', JSON.stringify(data));
      this.candidateApiService.editCandidatePanel(formData, this.panelID).pipe(finalize(() => {
        this.isLoading = false;
      })
      ).subscribe((res: any) => {
          if (res.status = 'success') {
            this.toast.success(res.message);
            this.dialogRef.close(true);
          }
    });
  }


}

}
