import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CandidateMasterService } from 'src/app/modules/user/services/candidate-master.service';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-status-update',
  templateUrl: './status-update.component.html',
  styleUrls: ['./status-update.component.scss']
})
export class StatusUpdateComponent {
  statusList: any[] = [];
  status: any;
  reason: string = '';

  constructor(private commonService: CommonService,
    private candidateApiService: CandidateMasterService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: ToastrService,
    public dialogRef: MatDialogRef<StatusUpdateComponent>) { }

  ngOnInit(): void {
    this.getCandidateStatus();
  }

  getCandidateStatus(): void {
    this.commonService.getCandidateStatus().subscribe((result: any) => {
      this.statusList = result;
      this.statusList.find((item) => {
        if (item.name === this.data.status?.label) {
          this.status = item.id;
        }
      })
    })
  }

  updateStatus(): void {
    let payload = new FormData();
    payload.append('status', this.status);
    this.candidateApiService.editCandidateStatus(payload, this.data.id).subscribe((result) => {
      if (result.status = 'success') {
        this.toast.success(result.message);
        this.dialogRef.close(true);
      }
    })
  }

  addReason(): void {
    if(this.reason !== ''){
    this.dialogRef.close(this.reason);
    }
    else {
      this.toast.warning('Reason is required');
    }
  }
}
