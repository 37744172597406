import { Component, Inject} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CandidateMasterService } from 'src/app/modules/user/services/candidate-master.service';

@Component({
  selector: 'app-view-history',
  templateUrl: './view-history.component.html',
  styleUrls: ['./view-history.component.scss']
})
export class ViewHistoryComponent {

  historyDetails:any = [];
  candidate: any = '';

  constructor(@Inject(MAT_DIALOG_DATA) public history: any,private candidateService:CandidateMasterService){}


  ngOnInit(): void {
    this.getHistory();
  }


  getHistory(){
    const id = this.history.id;
    this.candidateService.getCandateHistory(id).subscribe((res) => {
      this.candidate = res[0]?.candidate;
      this.historyDetails.push({
        date: res[0]?.date,
        jobTitle: res[0]?.job_title,
      })
    })
  }
  tableTitle = ['Date', 'Job Title'];
}
