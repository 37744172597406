<div class="job-listing" >
    <ng-container *ngIf="!isLoading">
    <div class="job-listing__outer " [ngClass]="{'email-template':tabKey =='addTemplate'}">
        <ng-container *ngFor="let item of data ;let i = index">
            <div class="job-listing__tile">
                <div class="job-listing__tile-inner">

                    <div class="job-listing__text-sec" *ngFor=" let key of settingDetail.tableKey">
                        <div class="job-listing__tile-head">
                            {{key?.label}}
                        </div>
                        <div
                            [ngClass]="{'job-listing__bubble-txt':tabKey=='addSkill'&& key.key=='name','job-listing__id':tabKey!='addSkill'}">
                            {{item[key?.key]}}
                        </div>
                    </div>
                    <!-- <div class="job-listing__text-sec">
                <div class="job-listing__tile-head">
                   skill name
                </div>
                <div  [ngClass]="{'job-listing__bubble-txt':tabKey=='addSkill','job-listing__id':tabKey!='addSkill'}">
                    {{item.name}}
                </div>
            </div> -->
                </div>
                <div class="job-listing__tile-btns">
                    <a class="job-listing__btn" (click)="editRow(i,item)">
                        <em class="bnx-icon bnx-icon--edit"></em>
                    </a>
                    <a class="job-listing__btn" (click)="deleteRow(i,item)">
                        <em class="bnx-icon bnx-icon--trash"></em>
                    </a>
                </div>
            </div>
        </ng-container>
    </div>
</ng-container>
<div class="no-data" *ngIf="isLoading">
     Loading ...
</div>
<div class="no-data" *ngIf="data.length == 0 && !isLoading">
    No Data Found
</div>
</div>
<app-pagination [hidden]="data.length == 0 && !isLoading" class="" [_totalPage]="totalPages" (change)="getPage($event)"></app-pagination>
