import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { catchError, finalize, of } from 'rxjs';
import { ApiStatus, modalData } from 'src/app/core/constants/constants';
import { GeneralSettingsService } from 'src/app/modules/admin/services/general-settings.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared/widget/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-add-new-setting-details',
  templateUrl: './add-new-setting-details.component.html',
  styleUrls: ['./add-new-setting-details.component.scss']
})
export class AddNewSettingDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('input', { static: false }) input!: ElementRef;
  commonForm!: FormGroup;
  templateForm!: FormGroup;
  loading = false;
  page = 1;
  tableLimit = 10;
  totalPages!: number;
  isLoading = false;
  modal!: string
  formName!: string;
  contentHeight: any;
  levelCount: number = 0;
  levelRoundList: any[] = []
  settingsListItem: any[] = []
  step: number = -1;
  isTemplate: boolean = true;
  jobDetails: any
  filterArray: any = {
    "q": '',
  }
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private toast: ToastrService,
    public commonService: CommonService,
    public dialog: MatDialog,
    private settingService: GeneralSettingsService,
    public dialogRef: MatDialogRef<AddNewSettingDetailsComponent>
  ) { }

//   const name = document.getElementsByClassName('cdk-global-overlay-wrapper');
//  name.classList.add('your-class-name');

  ngOnInit(): void {
    this.initForm();
    if (this.data?.id) {
      if (this.data.modal.id == 'addJobtitle') {
        this.getJobDetails(this.data);
      } else {
        this.getPatchValue(this.data);
      }
    }
    this.getAllList()
    if (this.data.modal.id == 'addJobtitle') {
      this.getLevelRoundList()
      this.initLevels()
      this.commonForm.controls["name"].setValidators(Validators.required);
      this.commonForm.controls['levelCount'].setValue(1)
    }

  }
  initForm() {
    if (this.data?.modal?.id !== 'addTemplate') {
      this.commonForm = this.fb.group(this.data?.modal?.formControls);
    }
     else if (this.data?.modal?.id === 'addTemplate') {
      this.templateForm = this.fb.group({
        title: new FormControl('', Validators.required),
        content: new FormControl('', Validators.required),
      })
    }
  }

  initLevels() {
    this.getLevels().push(this.addLevelRound())
  }
  removeLevel(i: number) {
    this.getLevels().removeAt(i);
  }
  getLevels() {
    return this.commonForm?.controls['levels'] as FormArray
  }
  addLevelRound() {
    return this.fb.group({
      level: ['L1', Validators.required],
      interview_round: [null, Validators.required],
      levelNaration: ['']
    })
  }
  getAllList() {
    this.isLoading = true;
    let filterArray = {
      'q':''
    }
    this.settingService.getList(this.data.modal.listEndPoint,filterArray,(this.page - 1) * this.tableLimit,this.tableLimit
      )
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((res: any) => {
        if (ApiStatus.ok) {
          this.totalPages =
            res.count % this.tableLimit == 0
              ? Math.trunc(res?.count / this.tableLimit)
              : Math.trunc(res?.count / this.tableLimit) + 1;
          this.settingsListItem = res?.results;
        }
      });
  }
  submit() {
    if (this.data.modal.submit == "Update") {
      this.update();
      return;
    }
    if (this.data.modal.id === 'addTemplate') {
      this.templateForm.markAllAsTouched();
      if (this.templateForm.valid) {
        this.loading = true;
        this.commonService.genericCreate(this.data?.modal?.apiEndPoint, this.templateForm.getRawValue())
          .pipe(
            finalize(() => this.loading = false),
            catchError((err) => {
              this.toast.warning(err.error.message);
              return of(false)
            })
          )
          .subscribe(res => {
            if (res.status === 'success') {
              this.toast.success(res.message);
              this.templateForm.reset();
              this.dialogRef.close();
              this.getAllList();
            }
          })
      }
    }
    else if (this.data.modal.id == 'addJobtitle') {
      this.commonForm.markAllAsTouched();
      if (this.commonForm.valid) {
      const newArray = this.commonForm.controls['levels'].value.map((obj: any) => {
        const { level, interview_round } = obj;
        return { level, interview_round };
      });
      let payload = { name: this.commonForm.controls['name'].value, levels: newArray }
      if (this.commonForm.valid) {
        this.loading = true;
        let formData = new FormData();
        const data = {
          ...payload
        }
        formData.append('data', JSON.stringify(data));
        this.commonService.genericCreate(this.data?.modal?.apiEndPoint, formData)
          .pipe(
            finalize(() => this.loading = false),
            catchError((err) => {
              this.toast.warning(err.error.message);
              return of(false)
            })
          )
          .subscribe(res => {
            if (res.status === 'success') {
              this.toast.success(res.message);
              this.clearForm();
              const levelsArray = this.getLevels();
              levelsArray.clear();
              this.dialogRef.close();
            }
          })
      }
    }
    } else {
      this.commonForm.markAllAsTouched();
      if (this.commonForm.valid) {
        this.loading = true;
        this.commonService.genericCreate(this.data.modal.apiEndPoint, this.commonForm.getRawValue())
          .pipe(
            finalize(() => this.loading = false),
            catchError((err) => {
              this.toast.warning(err.error.message);
              return of(false)
            })
          )
          .subscribe(res => {
            if (res.status === 'success') {
              this.toast.success(res.message);
              this.clearForm();
              this.getAllList()
            }
          })
      }
    }
  }
  update() {
    if (this.data.modal.id == 'addJobtitle') {
      const newArray = this.commonForm.controls['levels'].value.map((obj: any) => {
        const { level, interview_round } = obj;
        return { level, interview_round };
      });
      let payload = { name: this.commonForm.controls['name'].value, levels: newArray }
      this.commonForm.markAllAsTouched();
      if (this.commonForm.valid) {
        this.loading = true;
        let formData = new FormData();
        const data = {
          ...payload
        }
        formData.append('data', JSON.stringify(data));
        this.commonService.genericPut(this.data.modal.updateEndPoint, this.data.id, formData)
          .pipe(
            finalize(() => this.loading = false),
            catchError((err) => {
              this.toast.warning(err.error.message);
              return of(false)
            })
          )
          .subscribe(res => {
            if (res.status === 'success') {
              this.toast.success(res.message);
              this.clearForm();
              const levelsArray = this.getLevels();
              levelsArray.clear();
              this.dialogRef.close();
            }
          })
      }
    } else if (this.data.modal.id !== 'addTemplate') {
      this.commonForm.markAllAsTouched();
      if (this.commonForm.valid) {
        this.loading = true;
        this.commonService.genericPut(this.data.modal.updateEndPoint, this.data.id, this.commonForm.value)
          .pipe(
            finalize(() => this.loading = false),
            catchError((err) => {
              this.toast.warning(err.error.message);
              return of(false)
            })
          )
          .subscribe(res => {
            if (res.status === 'success') {
              this.toast.success(res.message);
              this.clearForm();
              this.getAllList()
              if (!this.data.modal.modalUpdate) {
                this.dialogRef.close()
              }
              this.data.modal.submit = "Add";
              this.data.modal.title = this.data.modal.title.replace("Edit", "Add");

            }
          })
      }
    }
    else if (this.data.modal.id === 'addTemplate') {
      if (this.templateForm.valid) {
        this.loading = true;
        this.commonService.genericPut(this.data.modal.updateEndPoint, this.data.id, this.templateForm.value)
          .pipe(
            finalize(() => this.loading = false),
            catchError((err) => {
              this.toast.warning(err.error.message);
              return of(false)
            })
          )
          .subscribe(res => {
            if (res.status === 'success') {
              this.toast.success(res.message);
              this.templateForm.reset();
              this.dialogRef.close()
              this.data.modal.submit = "Add";
              this.getAllList();
              this.data.modal.title = this.data.modal.title.replace("Edit", "Add");

            }
          })
      }
    }
  }
  getJobDetails(data: any) {
    this.commonService.genericDetails(this.data.modal?.jobPositionDetails, data.id)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((res: any) => {
        if (ApiStatus.ok) {
          this.jobDetails = res
          this.patchjobPositionDetails()
        }
      });
  }
  patchjobPositionDetails() {
    const jobTitleDetailsArray = this.getLevels();
    jobTitleDetailsArray.removeAt(0)
    this.commonForm.controls['levelCount'].setValue(this.jobDetails[0].level_count);
    this.levelCount=this.jobDetails[0]?.level_count
    if (this.jobDetails[0]?.levels !== null) {
      this.jobDetails[0]?.levels.forEach((jobTitle: any) => {
        const jobTitleFormGroup = this.addLevelRound();
        jobTitleFormGroup.patchValue({
          level: jobTitle?.level,
          interview_round: jobTitle?.interview_round.round_id,
          levelNaration: jobTitle?.interview_round.content
        });
        jobTitleDetailsArray.push(jobTitleFormGroup);
      });
    } else {
      this.getLevelRoundList()
      this.initLevels()
      this.commonForm.controls["name"].setValidators(Validators.required);
      this.commonForm.controls['levelCount'].setValue(1)
    }
  }
  getUpdate(env: any) {
    this.data.modal.formfields.forEach((item: any) => {
      this.data.modal.formControls[item.field].patchValue(env[item.field])
    })
    this.data.modal.submit = "Update";
    this.data.modal.modalUpdate = true;
    this.data.id = env.id
    this.data.modal.title = this.data.modal.title.replace("Add", "Edit");
    this.input.nativeElement.focus()
  }
  getPatchValue(data: any) {
    if (this.data.modal.id === 'addTemplate') {
      data.list.forEach((element: any) => {
        if (data.id === element?.id) {
          this.templateForm.patchValue({
            title: element?.title,
            content: element?.content
          })
        }
      });
    }
    this.data.modal.submit = "Update";
    this.data.id = data.id
    this.data.modal.title = this.data.modal.title.replace("Add", "Edit");
  }


  deleteItem(env: any) {
    this.commonService.genericDelete(this.data.modal.deleteEndPoint, env.id).pipe(
      catchError((err) => {
        this.toast.warning(err.error.message);
        return of(false)
      })
    )
      .subscribe((res: any) => {
        if (res.status === 'success') {
          this.toast.success(res.message);
          this.dialogRef.close()
        }
      }, (error: any) => {
        this.toast.error(error.message);
      })
  }
  confirmDialog(data: any): void {
    const message = `Do you really wish to proceed with the deletion of this record?`;
    const dialogData = new ConfirmDialogModel("Confirm Action", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "500px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.deleteItem(data);
      }
    });
  }
  clearForm() {
    this.commonForm?.reset();
    if (this.data.populateField) {
      this.commonForm.controls[this.data.modal.populateField].patchValue(this.data.populateField);
    }
  }
  closeModal() {
    this.data.modal.submit = 'Add'
    this.data.modal.title = this.data.modal.title.replace("Edit", "Add");
    this.dialogRef.close()
    this.data.modal.modalUpdate = false;
    const levelsArray = this.getLevels();
    levelsArray?.clear();
  }
  setLevelCount(event: any) {
    let inputvalue = this.commonForm.controls[this.data.modal.formfields[1].field].value;
    switch (event) {
      case 'input':
        if (inputvalue != '') {
          inputvalue = parseInt(inputvalue)
          if (this.levelCount < inputvalue) {
            let value = inputvalue - this.levelCount
            for (let index = 1; index <= value; index++) {
              let length = this.getLevels().length
              if (inputvalue > length)
                this.initLevels()
            }
          } else {
            let value = this.levelCount - inputvalue
            for (let index = 1; index <= value; index++) {
              let length = this.getLevels().length
              if (length > 1)
                this.removeLevel(this.getLevels().length - 1)
            }
          }
        }
        break;
      case 'increment':
        this.levelCount = inputvalue
        this.levelCount++
        if (this.levelCount > 1) {
          this.initLevels()
        }
        this.commonForm.controls[this.data.modal.formfields[1].field].setValue(this.levelCount);
        break
      case 'decrement':
        this.levelCount = this.commonForm.controls[this.data.modal.formfields[1].field].value;
        if (this.levelCount > 1) {
          this.levelCount--
          this.removeLevel(this.getLevels().length - 1)
          this.commonForm.controls[this.data.modal.formfields[1].field].setValue(this.levelCount);
        }
        break
      default:
        break;
    }
    const levels = this.getLevels().controls;
    levels.forEach((level: any, index) => {
      level.controls['level'].setValue(`L${index + 1}`);
    });
    if (inputvalue != '') {
      this.levelCount = this.commonForm.controls[this.data.modal.formfields[1].field].value;
    } else {
      this.commonForm.controls[this.data.modal.formfields[1].field].setValue(this.getLevels().length);
    }
  }
  // setup(index: any) {
  //   this.step = index
  // }
  getLevelRoundList() {
    this.settingService
      .getLevelList(
        this.data.modal.levelDropdownList)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((res: any) => {
        if (ApiStatus.ok) {
          this.levelRoundList = res;
        }
      });
  }
  setTemplateNote(index: any, env: any) {
    const selectedValue = env.controls['interview_round'].value
    let selecteditem = this.levelRoundList.filter((item: any) => item.id === selectedValue)
    env.controls['levelNaration'].setValue(selecteditem[0].content)
    this.step = index;
  }

  onKeyDown(event:any){
    const keyCode = event.keyCode || event.which;
    if (keyCode < 48 || keyCode > 57) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  ngOnDestroy(): void {
    this.clearForm();
    this.closeModal()
  }
}


