<!-- <div class="bnx-main-header">
  <div class="bnx-main-header__title">
    <h3>Beinex Recruitment Portal</h3>
  </div>
  <div class="bnx-main-header__right-sec">
    <div class="bnx-form__group">
      <div class="bnx-currency-item">
       <ng-select [items]="yearList" [(ngModel)]="currentYear" (change)="changeYear($event)"></ng-select>
      </div>
    </div>
  </div>
</div> -->

<!-- BNX SHORT DECS -->
<div class="bnx-home">
  <div class="bnx-home__wrapper">
    <div
      class="bnx-home__row"
      style="
        background-image: url(../../../../../assets/images/interviewer-bg.png);
      "
    >
      <!-- DETAILS -->
      <div class="bnx-home__content">
        <h4>Interviewer Dashboard</h4>
        <p>
          Know how many Interviews have been carried out and how many are
          scheduled to take place
        </p>
      </div>
      <!-- DETAILS -->
      <div class="bnx-home-wrapper__row">
        <!-- DETAILS -->
        <div class="bnx-home-wrapper__div">
          <div class="bnx-home-wrapper__card">
            <div class="bnx-home-wrapper__card-body">
              <div class="bnx-home-wrapper__card-details">
                <h2>{{ interviews ?? 0 }}</h2>
                <h4>Interviews</h4>
              </div>
              <div class="bnx-home-wrapper__head">
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="24" cy="24" r="24" fill="#39C0EA" />
                  <path
                    d="M16.0235 32.4161C18.2457 32.4161 20.0471 30.6147 20.0471 28.3926C20.0471 26.1704 18.2457 24.369 16.0235 24.369C13.8014 24.369 12 26.1704 12 28.3926C12 30.6147 13.8014 32.4161 16.0235 32.4161Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M31.8707 32.4503C33.3914 32.4503 34.6242 31.2176 34.6242 29.6969C34.6242 28.1761 33.3914 26.9434 31.8707 26.9434C30.35 26.9434 29.1172 28.1761 29.1172 29.6969C29.1172 31.2176 30.35 32.4503 31.8707 32.4503Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.4199 21.7188V16H22.7626L25.8691 21.7188H15.4199Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.4551 22.4244C14.8556 21.2948 17.6797 21.5772 19.7977 23.0598C22.057 24.6837 23.1866 27.4372 22.763 30.1907H27.211"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22.5508 27.0146H28.199"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M25.8694 21.719H29.9643C32.9296 21.719 35.2595 23.4841 35.2595 26.8024"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M30.082 21.719V19.4598C30.082 18.1889 30.9293 17.1299 31.9883 17.1299"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <!-- DETAILS -->

        <!-- DETAILS -->
        <div class="bnx-home-wrapper__div">
          <div class="bnx-home-wrapper__card">
            <div class="bnx-home-wrapper__card-body">
              <div class="bnx-home-wrapper__card-details">
                
                <h2>{{ upcomingInterviews ?? 0}}</h2>
                <h4>Upcoming Interviews</h4>
              </div>
              <div class="bnx-home-wrapper__head">
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="24" cy="24" r="24" fill="#39C0EA" />
                  <path
                    d="M16.0235 32.4161C18.2457 32.4161 20.0471 30.6147 20.0471 28.3926C20.0471 26.1704 18.2457 24.369 16.0235 24.369C13.8014 24.369 12 26.1704 12 28.3926C12 30.6147 13.8014 32.4161 16.0235 32.4161Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M31.8707 32.4503C33.3914 32.4503 34.6242 31.2176 34.6242 29.6969C34.6242 28.1761 33.3914 26.9434 31.8707 26.9434C30.35 26.9434 29.1172 28.1761 29.1172 29.6969C29.1172 31.2176 30.35 32.4503 31.8707 32.4503Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.4199 21.7188V16H22.7626L25.8691 21.7188H15.4199Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.4551 22.4244C14.8556 21.2948 17.6797 21.5772 19.7977 23.0598C22.057 24.6837 23.1866 27.4372 22.763 30.1907H27.211"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22.5508 27.0146H28.199"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M25.8694 21.719H29.9643C32.9296 21.719 35.2595 23.4841 35.2595 26.8024"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M30.082 21.719V19.4598C30.082 18.1889 30.9293 17.1299 31.9883 17.1299"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <!-- DETAILS -->
      </div>
    </div>
  </div>
  <!-- BNX SHORT DECS -->

  <!-- BNX TABLE TABE -->
  <div class="bnx-table-tab">
    <div class="common-mat-tab">
      <mat-tab-group
        class="common-mat-tab"
        animationDuration="0ms"
        class="bnx-mat-tab"
        mat-stretch-tabs="false"
        mat-align-tabs="start"
      >
        <mat-tab label="Interviews"
          ><div><app-interviews-panel></app-interviews-panel></div
        ></mat-tab>
        <mat-tab label="Upcoming Interviews">
          <div>
            <app-upcomming-interviews-panel></app-upcomming-interviews-panel>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <!-- BNX TABLE TABE -->
</div>
