import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContractCommonService {

  Id = new BehaviorSubject<any>('');
  projectAdd = new BehaviorSubject<any>('');
  selectedTabIndex = 0;
  contractListData = new BehaviorSubject<any>({});
  selectedId!:string;
  modalId:any;

  getId(): Observable<any> {
    return this.Id;
  }

  setId(data: any): void {
    this.Id.next(data);
  }

  getChange(): Observable<any> {
    return this.projectAdd;
  }

  setChange(data: any): void {
    this.projectAdd.next(data);
  }

  getData(): Observable<any> {
    return this.contractListData;
  }

  setData(data: any): void {
    this.contractListData.next(data);
  }


}
