<div class="bnx-modal">
    <div class="bnx-modal__close-btn" [mat-dialog-close]="false">
      <svg width="15" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times"
         class="svg-inline--fa fa-times fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
         <path fill="currentColor"
            d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z">
         </path>
      </svg>
    </div>
  
    <div class="bnx-modal__title">
      <h2 class="bnx-modal__heading">Feedback</h2>
    </div>
  
    <div class="bnx-view-modal__body">
      <form [formGroup]="hrFeedbackForm" class="w-100">
      <div class="bnx-modal__wrapper">
          <div class="bnx-form__row">
            <div class="bnx-form__col">
              <div class="bnx-form__group">
                <div class="bnx-currency-item required">
                  <ng-select class="recNgclass" [items]="communication" bindLabel="name" placeholder="Communication"  formControlName="communication"></ng-select>
                </div>
                <error-msg [control]="hrFeedbackForm.controls['communication']"></error-msg>
              </div>
            </div>
  
            <div class="bnx-form__col">
              <div class="bnx-form__group">
                <div class="bnx-currency-item required">
                  <ng-select class="recNgclass" [items]="status" bindLabel="name" placeholder="Status" formControlName="status"></ng-select>
                </div>
                <error-msg [control]="hrFeedbackForm.controls['status']"></error-msg>
              </div>
            </div>
  
  
            <div class="bnx-form__col">
              <div class="bnx-form__group">
                <div class="bnx-currency-item required">
                  <ng-select class="recNgclass" [items]="attitude" bindLabel="name" placeholder="Attitude" formControlName="attitude"></ng-select>
                </div>
                <error-msg [control]="hrFeedbackForm.controls['attitude']"></error-msg>
              </div>
            </div>
  
            <div class="bnx-form__col">
              <div class="bnx-form__group">
                <div class="bnx-currency-item required">
                  <ng-select class="recNgclass" [items]="rating" bindLabel="name" placeholder="Rating" formControlName="rating"></ng-select>
                </div>
                <error-msg [control]="hrFeedbackForm.controls['rating']"></error-msg>
              </div>
            </div>


            <div class="bnx-form__col">
                <div class="bnx-form__group required">
                  <input class="bnx-form__input" formControlName="date" placeholder="date" type="date" />
                  <div class="bnx-form__label">Date <span class="bnx-form__aster-risk">*</span></div>
                  <error-msg [control]="hrFeedbackForm.controls['date']"></error-msg>
                </div>
            </div>

            <div class="bnx-form__col">
                <div class="bnx-form__group required">
                    <ng-select class="select-etc" placeholder="Add HR" (change)="selectedType($event)"
                    [items]="hrList" bindLabel="full_name.name" bindValue="id"
                    formControlName="interviewer" [dropdownPosition]="'bottom'" [multiple]="true"></ng-select>
                    <error-msg [control]="hrFeedbackForm.controls['interviewer']"></error-msg>
                </div>
            </div>


  
            <div class="bnx-form full-width">
              <div class="bnx-form__group required">
                <textarea class="bnx-form__input comment" formControlName="remark" style="resize: none;" name="" id="" cols="20" rows="6"></textarea>
                <div class="bnx-form__label">HR feedback <span class="bnx-form__aster-risk">*</span></div>
                <error-msg [control]="hrFeedbackForm.controls['remark']"></error-msg>
              </div>
            </div>
  
          </div>
        <div class="submit">
          <button class="bnx-btn" (click)="submit()">{{isLoading ? 'Adding ...':'Submit'}}</button>
  
          <button class="bnx-btn bnx-btn-border" [mat-dialog-close]="false">Cancel</button>
        </div>
      </div>
    </form>
    </div>
  
  </div>
  