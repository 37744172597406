import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, finalize, of } from 'rxjs';
import { FunctionHelperService } from 'src/app/core/helper/function.helper.service';
import { LoginService } from 'src/app/modules/login/service/login.service';
import { CommonService } from '../../service/common.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LogoutConfirmComponent } from '../../widget/logout-confirm/logout-confirm.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  notifCount: number = 0;
  showAddEntry: boolean = true;
  notifRun = true;
  loggedUserName: string = '';
  newNotification: boolean = false;
  loggedUserType: any;
  currentMonth: any = '';
  currentYear: string | number = '';
  previousYear: string | number = '';
  deapartmentCards: any;

  filterArray: any = {
    "year": [this.currentYear],
    "month": [],
  }

  constructor(
    private helper: FunctionHelperService,
    private loginService: LoginService,
    public router: Router,
    public commonService: CommonService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.loggedUserName = this.helper.userData.user_name;
    this.loggedUserType = this.helper.userData?.user_type;
    this.getNewNotification();
    this.currentYear = new Date().getFullYear()
    this.previousYear = this.currentYear - 1
    this.currentMonth = new Date().toLocaleString('default', { month: 'short' });
  }

  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmComponent, {
      maxWidth: "500px"
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        const type = this.helper.userData;
        this.loginService.logout().subscribe((res) => {
          this.helper.deleteCookie().then(() => {
            if (type.user_type === 'CANDIDATE'){
              this.router.navigateByUrl('login');
            } else{
            this.router.navigateByUrl('login');
            }
          })
        })
      }
    });
  }

  notificationCloseEvent() {
    this.newNotification = false;
    this.commonService.notifMarkAllRead()
      .subscribe(() => {
      });
  }

  notificationOpenEvent() {
    this.commonService.notifList = [];
    this.commonService.notifyLoading = true;
    this.commonService.getNotificationList()
      .pipe(
        finalize(() => this.commonService.notifyLoading = false)
      )
      .subscribe((res: any) => {
        this.getNotification(res);
        this.commonService.notifyLoading = true;
        this.commonService.notifList = res;
      })
  }

  getNotifCount() {
    this.commonService.notifCount()
      .pipe(
        catchError(e => {

          if (e.status === 401) {
            this.notifRun = false;
          }
          return of(false)
        })
      )
      .subscribe((res: { count: number; }) => {
        this.notifCount = res.count;
      })
  }

  getNewNotification() {
    this.commonService.notifyLoading = true;
    this.commonService.getNotificationList()
      .pipe(
        finalize(() => this.commonService.notifyLoading = false)
      )
      .subscribe((res: any[]) => {
        const hasUnread = res.some((item) => item.unread);
        if (hasUnread) {
          this.newNotification = true;
        } else {
          this.newNotification = false;
        }
      })
  }


  getNotification(data:any){
    data.forEach((element:any) => {
      const cleanedString = element.verb.replace(/<\/?(b|p)>/g, '');
      const length = cleanedString.length;
      element.notifLength = length;
    });
  }


}
