import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationService } from 'src/app/core/services/common/validation.service';

@Component({
  selector: 'error-msg',
  templateUrl: './error-msg.component.html',
  styleUrls: ['./error-msg.component.scss']
})
export class ErrorMsgComponent {

  @Input() control!: any;
  @Input() labelName: string = "";

  constructor() { }

  ngOnInit(): void {
  }

  get errorMessage(): boolean {

    for (const propertyName in this.control.errors) {
      
      if (this.control.errors.hasOwnProperty(propertyName) && this.control.touched) {
        return ValidationService.getValidationErrorMessage(propertyName, this.control.errors[propertyName], this.labelName);
      }

    }
    return false;
  }
}
