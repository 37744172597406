import { Component, ElementRef, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { finalize, catchError, of } from 'rxjs';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-common-modal',
  templateUrl: './common-modal.component.html',
  styleUrls: ['./common-modal.component.scss']
})
export class CommonModalComponent {
  commonForm!:FormGroup;
  loading =false;
  modal!:string
  formName!:string;
  contentHeight:any;
  searchTerm='';


  dataFilterCtrl:FormControl<string | null> = new FormControl<string>('');
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb:FormBuilder,
    public commonService: CommonService,
    public dialogRef: MatDialogRef<CommonModalComponent>,
    private toast:ToastrService,
    private ele: ElementRef,
  ){
  }

  ngOnInit(){
    this.initForm();
    this.dataFilterCtrl.valueChanges.subscribe((val: any) => {
      this.searchTerm = val
    })
  }

  initForm(){
    this.commonForm = this.fb.group(this.data.modal.formControls);
    if(this.data.populateField){
      this.commonForm.controls[this.data.modal.populateField].patchValue(this.data.populateField);
    }

    if(this.data.modal.submit ==='Update')
    {
      switch (this.data.modal.id) {
        case 'addContractStatus':
          this.commonService.setId('patch_');
          break;

        case 'addCountryCrud':
          this.commonService.setId('patch_');
          break;

        case 'addProjectManager':
          this.commonService.setId('patch_');
          break;

        case 'addAccountManager':
          this.commonService.setId('patch_');
          break;

        default:
          break;
      }
    }
    if(this.data.modal.dropDownListApi)
    {
      this.commonService.genericRead(this.data.modal.dropDownListApi)
      .subscribe(res=>{
        this.data.modal.dropDownList = res;
      })
    }
  }

  clearForm(){
    this.commonForm.reset();
    if(this.data.populateField){
      this.commonForm.controls[this.data.modal.populateField].patchValue(this.data.populateField);
    }
  }

  submit(){
    this.commonForm.markAllAsTouched();
    if(this.data.modal.submit == "Update"){
      this.update();
      return;
    }
    if(this.commonForm.valid){
       this.loading = true;
       if(this.data.populateField){
        this.commonForm.controls[this.data.modal.populateField].setValue(this.data.id);
       }

       if(this.data.modal.id !=='addAccountManager' && this.data.modal.id !=='addProjectManager')
       {

        this.commonService.genericCreate(this.data.modal.apiEndPoint,this.commonForm.getRawValue())
        .pipe(
          finalize(()=>this.loading=false),
          catchError((err)=>{
            this.toast.warning(err.error.message);
            return of(false)})
        )
        .subscribe(res=>{
          if(res.status==='success')
          {
            this.toast.success(res.message);
            this.clearForm();
            this.dialogRef.close(true);
          }
        })

      }else{
        this.commonForm.controls['action_type'].setValue('ADD');
        this.commonService.genericPut(this.data.modal.apiEndPoint,this.commonForm.controls['id'].value,this.commonForm.value)
        .pipe(
          finalize(()=>this.loading=false),
          catchError((err)=>{
            this.toast.warning(err.error.message);
            return of(false)})
        )
        .subscribe(res=>{
          if(res.status==='success')
          {
            this.toast.success(res.message);
            this.clearForm();
            this.dialogRef.close(true);
          }
        })
      }

    }
  }

  update(): void{

    if(this.commonForm.valid){
      this.loading = true;

      if(this.data.modal.id ==='addAccountManager' || this.data.modal.id ==='addProjectManager')
      {
        this.data.id = this.commonForm.value.id;
      }
      this.commonService.genericPut(this.data.modal.updateEndPoint,this.data.id,this.commonForm.value)
        .pipe(
          finalize(()=>this.loading=false),
          catchError((err)=>{
            this.toast.warning(err.error.message);
            return of(false)})
        )
        .subscribe(res=>{
          if(res.status==='success')
          {
            this.toast.success(res.message);
            this.clearForm();
            this.data.modal.submit = "Add";
            this.data.modal.title = this.data.modal.title.replace("Edit", "Add");
            this.dialogRef.close(true);
          }
        })
    }
  }

  getSelected(env:any){
    let formName =  this.data.modal.formfields.filter((v:any)=>{ return v.mode !== undefined})[0].field;
    this

}
}
