import { FormArray, FormControl, Validators } from '@angular/forms';
import { from } from 'rxjs';

export enum ApiEndPoints {
  loginSaml = 'auth/login-saml',
  //login
  login = 'login/',
  logout = 'auth/logout',
  jobPositionList = 'job-position/list',
  departmentList = 'department/list',
  jobStatus = 'job-opening-status/list',
  recruiterList = 'user/list',
  countryList = 'country/list',
  skillList = 'skill/list',
  noticePeriodList = 'notice_period/list',
  qualificationList = 'qualifications/list',
  certificationList = 'candidate-certifications/list',
  loginCandidatePassword = 'forgot-password',
  addContract = 'contract/create',

  //job
  addJobDetails = 'job_openings/create',
  editJobDetails = 'job_openings/update',
  deleteJobDetails = 'job_openings/delete',
  jobList = 'job_openings/list',
  jobViewDetails = 'job_openings/list',

  //Demand
  addDemandDetails = 'job_demand/create',
  demandList = 'job_demand/list',
  updateEmailStatus = 'job_demand/status-update',
  demandViewDetails = 'job-demand/list',
  editDemandDetails = 'job_demand/update',
  deleteDemandDetails = 'job-demand/delete',
  // candidate master
  candidateList = 'candidate/list',
  freezeList = 'freezed-candidate/list',
  editCandidateStatus = 'candidate-status-change/update',
  candidateDetails = 'candidate/details',
  editCandidateDetails = 'candidate/update',
  candidatePanel = 'interview-panel',
  candidateInterviewStatus = 'interview/level-status-list',
  updateCandidatePanel = 'interview-feedback/update',
  candidateAssignment = 'assignment/create',
  candidateSubmitAssignment = 'candidate-assignment/create',
  candidateAssignmentList = 'assignment/list',
  candidateHistory = 'candidate/cool-off-period/details',
  candidateDocumentList = 'candidate-documents/details',
  documentUpload = 'candidate/document-upload',
  currentList = 'currency/list',
  importCandidate = 'candidate/import',
  getprofileImage = 'candidate/profile-pic-upload/list',
  uploadProfileImage = 'candidate/profile-pic-upload',
  beinexEmployees = 'beinex-employee/list',
  editHrFeedback = 'hr-feedback/update',
  // Notification
  notifList = 'notification/dropdown/list',
  markAsRead = 'notification/mark_read/',
  notifCount = 'notification-count/',
  clearAll = 'notification/clear/',
  candidateSource = 'source/list',
  candidateStatus = 'candidate_status/list',
  candidateExp = 'experience/list',
  candidateJobOpening = 'jobopening-dropdown/list',
  addCandidate = 'candidate/create',
  inactiveCandidate = 'candidate/delete',
  activeCandidate = 'unfreeze-candidate',
  docCandidateLinks = 'candidate/resume',
  //User
  roleList = 'license-type/list',
  addUser = 'user/create',
  updateUser = 'user/update',
  userAction = 'user/user-actions/',
  userList = 'user/list',
  userAzure = 'user-azure-sso',

  // interview
  interviewCount = 'interview-chart/list',
  interviewList = 'interview/list',
  interviewsList = 'interviewer/interview/list',
  upcomminginterviewsList = 'interviewer/upcoming-interview/list',
  upcommingInterviewList = 'upcoming-interview/list',
  upcommingJoineeList = 'upcoming-joinees/list',
  //Interview schedule
  interviewCandidate = 'interview-candidates/list',
  addInterviewScheduleDetail = 'interview/create',
  interviewScheduleList = 'interview/list',
  interviewScheduleDetail = 'interview/detail',
  editInterviewSchedule = 'interview-update',
  deleteInterviewDetails = 'interview-delete',
  storeMeetingID = 'interview-notificationid/update',
  interviewTypeList = 'job-position/detail/list',
  editInterviewStatus = 'interview-status/update',
  rejectionMail = 'send-interview-rejection/email',

  //Offer Status
  OfferstatusList = 'offer/list',
  offerstatusDelete = 'offer-delete',
  offerstatusAdd = 'offer-status/create',
  OfferstatusEdit = 'offer-update',
  offerstatusDetail = 'offer-detail',
  // home page chart

  chartFilter = 'chart-filter/list',
  sourceMonth = 'heat-map/generate',
  recuritmentChart = 'simple-line-chart/generate',

  //export
  jobOpeningExport = 'job-export/xls_download'
}
export const returnUrl = 'returnUrl';

export const userRole = {
  admin: 'ADMIN',
  hr: 'HR',
  sales: 'SALES',
  approver: 'APPROVER',
  operation: 'OPERATION',
  candidate: 'CANDIDATE',
  interviewer: 'Interviewer',
  ceo: 'SLT CEO',
  director: 'SLT Directors',
};

export const loginUrls = {
  dashboard: '/main/admin/index',
  employee: '/main/admin/home',
  // manager: '/task-entry',
  login: '/login',
  candidateProfile: '/main/user/interview-panel',
  // logout: '/logout',
  // redirect: '/redirect'
};

export const secretKeyVariable = {
  recruitSecretKey: 'BeiNex-RecruitMentPortal@2023-10',
};

export enum ApiStatus {
  ok = 200,
  serverDown = 500,
  created = 201,
  unAuthorized = 401,
  badRequest = 400,
  noContent = 204,
  forbidden = 403,
  notFound = 404,
  notAcceptable = 406,
  conflict = 409,
  unprocessableEntity = 422,
  failedDependency = 424,
  timeOut = 504,
  corsOrigin = 0,
  failed = 'failed',
}

export enum LocalStorageKey {
  userLogin = 'clientDetails',
  // timeDetails = 'timeDetails',
  // userDetails = 'userDetails',
  // sessionId = 'sessionid',
  // validatedData = 'validatedData',
  // usersDetails = 'userDetail',
  // gridDataPreview = 'gridDataPreview',
  // issueDetails = 'issueDetails',
  // controlDetails = 'controlDetails',
  // isUpdate = 'isUpdate',
  // notificationId = 'notificationId',
  // userType = 'userType',
  // customField = 'customField',
  // builderChanged = 'builderChanged',
}

// SIDE BAR MENUS
export const sideBarMenus = [
  {
    number: 1,
    name: 'Home',
    icons: 'assets/images/icons-svg/home.svg',
    route: '/main/admin/home',
    isActive: true,
    sub_menu: [],
  },
  {
    number: 2,
    name: 'Dashboard',
    icons: 'bnx-icon bnx-icon--dashboard',
    route: '/main/admin/index',
    sub_menu: [],
  },
  {
    number: 3,
    name: 'User Management',
    icons: 'bnx-icon bnx-icon--invite-user',
    route: '/main/user/user-management',
    sub_menu: [],
  },
  {
    number: 4,
    name: 'Candidate Master',
    icons: 'bnx-icon bnx-icon--three-user',
    route: '/main/user/candidate-master',
    sub_menu: [],
  },
  {
    number: 5,
    name: 'Demand Master',
    icons: 'bnx-icon bnx-icon--activity',
    route: '/main/user/demand-master',
    sub_menu: [],
  },
  {
    number: 6,
    name: 'Job Master',
    icons: 'bnx-icon bnx-icon--employment-2',
    route: '/main/user/job-master',
    sub_menu: [],
  },
  {
    number: 7,
    name: 'Interview Scheduled',
    icons: 'bnx-icon bnx-icon--calendar',
    route: '/main/user/interview-schedule',
    sub_menu: [],
  },
  {
    number: 8,
    name: 'Offer Status',
    icons: 'bnx-icon bnx-icon--discount',
    route: '/main/user/offer-status',
    sub_menu: [],
  },
  {
    number: 9,
    name: 'General Settings',
    icons: 'bnx-icon bnx-icon--dashboard',
    sub_menu: [
      {
        sub_name: 'Client Type',
        route: '/main/settings/list/clientType',
      },
    ],
  },
];
// HR SIDEBAR MENUS
export const HRSideBar = [
  {
    number: 1,
    name: 'Dashboard',
    icons: 'bnx-icon bnx-icon--dashboard',
    route: '/main/admin/index',
    isActive: true,
    sub_menu: [],
  },
  {
    number: 2,
    name: 'User Management',
    icons: 'bnx-icon bnx-icon--invite-user',
    route: '/main/user/user-management',
    sub_menu: [],
  },
  {
    number: 3,
    name: 'Candidate Master',
    icons: 'bnx-icon bnx-icon--three-user',
    route: '/main/user/candidate-master',
    sub_menu: [],
  },
  {
    number: 4,
    name: 'Demand Master',
    icons: 'bnx-icon bnx-icon--activity',
    route: '/main/user/demand-master',
    sub_menu: [],
  },
  {
    number: 5,
    name: 'Job Master',
    icons: 'bnx-icon bnx-icon--employment-2',
    route: '/main/user/job-master',
    sub_menu: [],
  },
  {
    number: 6,
    name: 'Interview Scheduled',
    icons: 'bnx-icon bnx-icon--calendar',
    route: '/main/user/interview-schedule',
    sub_menu: [],
  },
  {
    number: 7,
    name: 'Offer Status',
    icons: 'bnx-icon bnx-icon--discount',
    route: '/main/user/offer-status',
    sub_menu: [],
  },
  // {
  //   number: 8,
  //   name: 'General Settings',
  //   icons: 'bnx-icon bnx-icon--dashboard',
  //   sub_menu: [
  //     {
  //       sub_name: 'Skills',
  //       route: '/main/settings/list/skills',
  //     },
  //     {
  //       sub_name: 'Job Title',
  //       route: '/main/settings/list/jobtitle',
  //     },
  //     {
  //       sub_name: 'Location',
  //       route: '/main/settings/list/location',
  //     },
  //     {
  //       sub_name: 'Department',
  //       route: '/main/settings/list/department',
  //     },
  //   ],
  // },
];

export const modalData = {
  skills: {
    listTitle: 'Skills List',
    formfields: [
      {
        title: 'Skills',
        placeholder: 'Enter skill name',
        field: 'name',
        type: 'text',
      },
    ],
    formControls: {
      name: new FormControl('', Validators.required),
    },
    modalWidth: '450px',
    title: 'Add Skills',
    submit: 'Add',
    modalUpdate: false,
    apiEndPoint: 'skill/create',
    updateEndPoint: 'skill/update',
    deleteEndPoint: 'skill/delete',
    listEndPoint: 'skill/list',
    id: 'addSkill',
    tableKey: [
      { label: 'Id', key: 'id' },
      { label: 'Skill name', key: 'name' },
    ],
  },

  jobtitle: {
    listTitle: 'Job Title',
    formfields: [
      {
        title: '',
        placeholder: 'Enter Job Title',
        field: 'name',
        type: 'text',
      },
      {
        title: '',
        placeholder: '',
        field: 'levelCount',
        type: 'number',
      },
    ],
    formControls: {
      name: new FormControl('', Validators.required),
      levelCount: new FormControl(0, Validators.required),
      levels: new FormArray([]),
    },
    modalWidth: '450px',
    title: 'Add Job Title',
    submit: 'Add',
    apiEndPoint: 'job-position/create',
    updateEndPoint: 'job-position/update',
    deleteEndPoint: 'job-position/delete',
    listEndPoint: 'job-position/list',
    jobPositionDetails: 'job-position/detail/list',
    levelDropdownList: 'interview-round/template/list',
    id: 'addJobtitle',
    tableKey: [
      { label: 'Id', key: 'id' },
      { label: 'Job Title', key: 'name' },
    ],
  },
  location: {
    listTitle: 'Location',
    formfields: [
      {
        title: 'Location',
        placeholder: 'Enter Location',
        field: 'name',
        type: 'text',
      },
    ],
    formControls: {
      name: new FormControl('', Validators.required),
    },
    modalWidth: '450px',
    title: 'Add Location',
    submit: 'Add',
    modalUpdate: false,
    apiEndPoint: 'country/create',
    updateEndPoint: 'country/update',
    deleteEndPoint: 'country/delete',
    listEndPoint: 'country/list',
    id: 'addLocation',
    tableKey: [
      { label: 'Id', key: 'id' },
      { label: 'Location', key: 'name' },
    ],
  },

  department: {
    listTitle: 'Department',
    formfields: [
      {
        title: 'Department',
        placeholder: 'Enter Department',
        field: 'name',
        type: 'text',
      },
    ],
    formControls: {
      name: new FormControl('', Validators.required),
    },
    modalWidth: '450px',
    title: 'Add Department',
    submit: 'Add',
    modalUpdate: false,
    apiEndPoint: 'department/create',
    updateEndPoint: 'department/update',
    deleteEndPoint: 'department/delete',
    listEndPoint: 'department/list',
    id: 'addDepartment',
    tableKey: [
      { label: 'Id', key: 'id' },
      { label: 'Department', key: 'name' },
    ],
  },

  template: {
    listTitle: 'Template list',
    modalWidth: '450px',
    title: 'Add New Template',
    submit: 'Save',
    apiEndPoint: 'interview-round/template/create',
    updateEndPoint: 'interview-round/template/update',
    deleteEndPoint: 'interview-round/template/delete',
    listEndPoint: 'interview-round/template/list',
    id: 'addTemplate',
    tableKey: [
      { label: 'Id', key: 'id' },
      { label: 'Round name', key: 'title' },
      { label: 'Message', key: 'content' },
    ],
  },
};
export const InterviewerSideBar = [
  {
    number: 1,
    name: 'Home',
    icons: 'bnx-icon bnx-icon--home',
    route: '/main/admin/home',
    isActive: true,
    sub_menu: [],
  },
];

export const CandidateSideBar = [
  {
    number: 1,
    name: 'Profile',
    icons: 'bnx-icon bnx-icon--invite-user',
    route: '/main/user/interview-panel',
    isActive: true,
    sub_menu: [],
  },
];

export const SLTCEOSideBar = [
  {
    number: 1,
    name: 'Dashboard',
    icons: 'bnx-icon bnx-icon--dashboard',
    route: '/main/admin/index',
    isActive: true,
    sub_menu: [],
  },
  {
    number: 2,
    name: 'Candidate Master',
    icons: 'bnx-icon bnx-icon--three-user',
    route: '/main/user/candidate-master',
    sub_menu: [],
  },
  {
    number: 3,
    name: 'Demand Master',
    icons: 'bnx-icon bnx-icon--activity',
    route: '/main/user/demand-master',
    sub_menu: [],
  },
  {
    number: 4,
    name: 'Job Master',
    icons: 'bnx-icon bnx-icon--employment-2',
    route: '/main/user/job-master',
    sub_menu: [],
  },
  {
    number: 5,
    name: 'Interview Scheduled',
    icons: 'bnx-icon bnx-icon--calendar',
    route: '/main/user/interview-schedule',
    sub_menu: [],
  },
  {
    number: 6,
    name: 'Offer Status',
    icons: 'bnx-icon bnx-icon--discount',
    route: '/main/user/offer-status',
    sub_menu: [],
  },
];

export interface User {
  clientname: string;
  projectname: string;
  region: string;
  project_manager: string;
  po_contract_status: any;
  // noResource:number,
  // id:number,
}
export interface UserManage {
  name: string;
  email: string;
  roll: string;
  status: string;
}


export const adminActionList = [
  {
    id: 'id_export_excel',
    name: 'Export',
    submenu: [
      {
        id: 'id_export_all',
        name: 'Export All (.xlsx)',
      },
      {
        id: 'id_export_selected',
        name: 'Export Selected (.xlsx)',
      },
    ],
  },
];