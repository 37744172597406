import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CandidateLoginPageComponent } from './pages/candidate-login-page/candidate-login-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { SubLoginComponent } from './pages/sub-login/sub-login.component';

const routes: Routes = [
    {
        path: '',
        component: LoginPageComponent
    },
    {
        path: 'login1',
        component: CandidateLoginPageComponent
    },
    {
        path: 'candidate-login',
        component: CandidateLoginPageComponent
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LoginRoutingModule { }
