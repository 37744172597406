<div class="bnx-logout pt-5 pb-5">
    <div class="bnx-common__subhead">
        <svg width="55" viewBox="0 0 81 82" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="40.5" cy="40.832" r="40.5" fill="#0480F2" />
            <path d="M25.8896 44.0975L36.776 54.7481L56.1699 33.6836" stroke="white" stroke-width="2"
                stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    </div>

    <div class="bnx-logout__subhead">Do you really want to logout?</div>

    <div class="bnx-logout__footer">
        <button class="bnx-btn bnx-btn-border" (click)="onConfirm()">Yes</button>
        <button class="bnx-btn bnx-btn-border" (click)="onDismiss()">No</button>
    </div>
</div>