import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {
  mode =1;
  pageArr!: any[];
  initialCount=1;
  selectedValue!: number;
  initialPage=1;
  pseudoI!: number;
  pageLimit:any = 10;

  selectedCity: any;
  pageLimits:number[] = [
    10,
    25,
    50,
    100
  ];


  @Output() change = new EventEmitter();
  @Input() _totalPage!:number;
  @Input() setDefault!: boolean;

  constructor(private activateRoute: ActivatedRoute,) {}
  ngOnInit(){
    this.activateRoute.queryParams.subscribe((res: any) => {
      this.initialPage = res['page'] ?? 1;
      this.gotoPage(this.initialPage, true)
    })
    this.paginationArray();
  }

  ngOnChanges(change: SimpleChanges){
   if(change['setDefault']?.currentValue){
    this.initialPage = 1;
    this.gotoPage(this.initialPage, true);
   }

   if(change['_totalPage']?.currentValue){
    this.initialPage = 1;
    this.gotoPage(this.initialPage, true);
   }
    this.paginationArray();
  }

  onPrevious() {
    if (this.initialPage > 1) {
      this.initialPage--;
      this.gotoPage(this.initialPage, true);
    }
    this.change.emit({
      action:'page',
      page:this.initialPage
    });
    // this.gridApi.paginationGoToNextPage();
  }


  changePageLimit(){
    this.change.emit({
      action:'pageLimit',
      pageLimit:this.pageLimit.$ngOptionLabel
    });
}

  onNext() {
    if (this.initialPage < this._totalPage) {
      this.initialPage++;
      this.gotoPage(this.initialPage, true);
    }
    this.change.emit({
      action:'page',
      page:this.initialPage
    });
  }

   //  SWITCH pageArr ARRAY MODE
   paginationArray() {
    switch (this.mode) {
      case 1:
        this.pageArr = [
          {
            page: this.initialCount,
            selected: this.initialCount === this.initialPage
          },
          {
            page: this.initialCount + 1,
            selected: this.initialCount + 1 === this.initialPage
          },
          {
            page: this.initialCount + 2,
            selected: this.initialCount + 2 === this.initialPage
          },

          {
            page: '...',
            selected: false
          },
          {
            page: this._totalPage,
            selected: this.initialPage === this._totalPage
          }
        ];
        break;

      case 2:
        this.pageArr = [
          {
            page: this.initialCount,
            selected: String(this.initialCount) === String(this.initialPage)
          },
          {
            page: '...',
            selected: false
          },
          {
            page: this.selectedValue,
            selected: String(this.selectedValue) === String(this.initialPage)
          },
          {
            page: '...',
            selected: false
          },
          {
            page: this._totalPage,
            selected: String(this.initialPage) === String(this._totalPage)
          }
        ];
        break;

      case 3:
        this.pageArr = [
          {
            page: this.initialCount,
            selected: String(this.initialCount) === String(this.initialPage)
          },
          {
            page: '...',
            selected: false
          },
          {
            page: this._totalPage - 2,
            selected: String((this._totalPage - 2)) === String(this.initialPage)
          },
          {
            page: this._totalPage - 1,
            selected: String((this._totalPage - 1)) === String(this.initialPage)
          },
          {
            page: this._totalPage,
            selected: String(this.initialPage) === String(this._totalPage)
          }
        ];
        break;

      default:
        break;
    }

    //IF TOTAL PAGE IS LESS THAN 8
    if (this._totalPage < 6) {
      this.pageArr = [];

      for (let i = 1; i <= this._totalPage; i++) {
        this.pageArr.push({
          page:i,
          selected: this.initialPage === i
        });
      }
    }
  }

  gotoPage(item: number, navigation?: boolean) {
    if (item > 0) {
      this.initialPage = item;
      if (item <= this.initialCount + 2) {
        this.mode = 1;
        this.paginationArray();
      } else if (item > this.initialCount + 1 && item < this._totalPage - 1 ) {
        this.selectedValue = item;
        this.mode = 2;
        this.paginationArray();
        this.pseudoI = 3;
      } else {
        this.mode = 3;
        this.paginationArray();
        this.pseudoI = 5 - (this._totalPage - item);
      }

      this.change.emit({
        action:'page',
        page:this.initialPage
      });

      if (!navigation) {
        // this.change.emit({
        //   action:'page',
        //   page:this.initialPage
        // });
      }
    }

  }
}
