import { Component } from '@angular/core';

@Component({
  selector: 'app-offerstatus-view',
  templateUrl: './offerstatus-view.component.html',
  styleUrls: ['./offerstatus-view.component.scss']
})
export class OfferstatusViewComponent {

}
