<apx-chart
  [series]="chartOptions?.series"
  [chart]="chartOptions?.chart"
  [xaxis]="chartOptions?.xaxis"
  [dataLabels]="chartOptions?.dataLabels"
  [grid]="chartOptions?.grid"
  [stroke]="chartOptions?.stroke"
  [title]="chartOptions?.title"
  [markers]="chartOptions?.markers"
  [yaxis]="chartOptions?.yaxis"
  [fill]="chartOptions?.fill"
></apx-chart>
