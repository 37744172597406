import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './layouts/footer/footer.component';
import { FullComponent } from './layouts/full/full.component';
import { HeaderComponent } from './layouts/header/header.component';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import {MatMenuModule} from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { TableViewComponent } from './widget/table-view/table-view.component';
import {MatButtonModule} from '@angular/material/button';
import { FilterComponent } from './widget/filter/filter.component';
import { UserManageModalComponent } from './widget/modals/user-manage-modal/user-manage-modal.component';
import {MatDialogModule} from '@angular/material/dialog';
import { JobviewDetailsComponent } from './widget/modals/jobview-details/jobview-details.component';
import { ErrorMsgComponent } from './widget/error-msg/error-msg.component';
import { JobAddComponent } from './widget/modals/job-add/job-add.component';
import { CandidateDetailsAddComponent } from './widget/modals/candidate-details-add/candidate-details-add.component';
import { SearchableSelectComponent } from './widget/modals/searchable-select/searchable-select.component';
import { CoreModule } from "../core/core.module";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatChipsModule} from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { InterviewDetailsComponent } from './widget/modals/interview-details/interview-details.component';
import { InterviewAddComponent } from './widget/modals/interview-add/interview-add.component';
import { ConfirmDialogComponent } from './widget/confirm-dialog/confirm-dialog.component';
import { AddOfferstatusComponent } from './widget/modals/add-offerstatus/add-offerstatus.component';
import { OfferstatusViewComponent } from './widget/modals/offerstatus-view/offerstatus-view.component';
import { PaginationComponent } from './widget/pagination/pagination.component';
import { CandidateFeedbackComponent } from './widget/modals/candidate-feedback/candidate-feedback.component';
import { StatusUpdateComponent } from './widget/modals/status-update/status-update.component';
import { DemandAddComponent } from './widget/modals/demand-add/demand-add.component';
import { DemandDetailsComponent } from './widget/modals/demand-details/demand-details.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgSelectModule } from '@ng-select/ng-select';
import { NotificationComponent } from './widget/notification/notification.component';
import { CandidateAssignmentComponent } from './widget/modals/candidate-assignment/candidate-assignment.component';
import { ViewHistoryComponent } from './widget/modals/view-history/view-history.component';
import { CommonModalComponent } from './widget/modals/common-modal/common-modal.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { ImportComponent } from './widget/modals/import/import.component';
import { GuidelineModalComponent } from './widget/modals/guideline-modal/guideline-modal.component';
import { DocumentViewerComponent } from './widget/modals/document-viewer/document-viewer.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { TableLoaderComponent } from './widget/table-loader/table-loader.component';
import { ProfileImageAddComponent } from './widget/modals/profile-image-add/profile-image-add.component';
import { InterviewStatusComponent } from './widget/modals/interview-status/interview-status.component';
import { SuccessAlertComponent } from './widget/success-alert/success-alert.component';
import { InterviewCancelComponent } from './widget/modals/interview-cancel/interview-cancel.component';
import { LogoutConfirmComponent } from './widget/logout-confirm/logout-confirm.component';
import { HrFeedbackComponent } from './widget/modals/hr-feedback/hr-feedback.component';
import { ViewProfessionalDetailsComponent } from './widget/modals/view-professional-details/view-professional-details.component';

@NgModule({
    declarations: [
        FooterComponent,
        FullComponent,
        HeaderComponent,
        SidebarComponent,
        TableViewComponent,
        FilterComponent,
        UserManageModalComponent,
        CandidateDetailsAddComponent,
        JobviewDetailsComponent,
        ErrorMsgComponent,
        JobAddComponent,
        SearchableSelectComponent,
        CandidateDetailsAddComponent,
        InterviewDetailsComponent,
        InterviewAddComponent,
        ConfirmDialogComponent,
        AddOfferstatusComponent,
        OfferstatusViewComponent,
        PaginationComponent,
        CandidateFeedbackComponent,
        StatusUpdateComponent,
        DemandAddComponent,
        DemandDetailsComponent,
        NotificationComponent,
        CandidateAssignmentComponent,
        ViewHistoryComponent,
        CommonModalComponent,
        ImportComponent,
        GuidelineModalComponent,
        DocumentViewerComponent,
        TableLoaderComponent,
        ProfileImageAddComponent,
        InterviewStatusComponent,
        SuccessAlertComponent,
        InterviewCancelComponent,
        LogoutConfirmComponent,
        HrFeedbackComponent,
        ViewProfessionalDetailsComponent

    ],
    exports: [
        TableViewComponent,
        FilterComponent,
        SearchableSelectComponent,
        ErrorMsgComponent,
        ConfirmDialogComponent,
        PaginationComponent,
        NotificationComponent,
        TableLoaderComponent
    ],
    imports: [
        CommonModule,
        MatMenuModule,
        RouterModule,
        MatButtonModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        MatIconModule,
        MatChipsModule,
        ScrollingModule,
        MatTooltipModule,
        NgSelectModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        NgxDocViewerModule
    ]


})
export class SharedModule { }
