import { TableOption, editDelete } from './../../../../core/interface/table';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { JobApiService } from 'src/app/modules/user/services/job-api.service';
import { catchError, finalize, of } from 'rxjs';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from 'src/app/shared/widget/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { JobAddComponent } from 'src/app/shared/widget/modals/job-add/job-add.component';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { ApiEndPoints, adminActionList } from 'src/app/core/constants/constants';
import { FunctionHelperService } from 'src/app/core/helper/function.helper.service';
import { DownloadService } from 'src/app/core/services/common/download.service';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-opening-jobs',
  templateUrl: './opening-jobs.component.html',
  styleUrls: ['./opening-jobs.component.scss'],
})
export class OpeningJobsComponent {
  checkedData = [];
  jobOpeningsList = [];
  isLoading: boolean = false;
  totalPages!: number;
  tableLimit = 10;
  page = 1;
  tableTitle = ['', 'Job Title', 'Department', 'Job Position', 'Project Name', 'Client Name', 'Date', ''];
  tableKey = ['', 'jobTitle', 'department', 'job_position', 'client_name', 'project_name', 'date', ''];
  setDefaultPage: boolean =false;
  adminActionList: { id: string; name: string; }[] = adminActionList;
  jobStatusList: any[] = [];
  tableEditDelele: editDelete = {
    edit: false,
    delete: false,
  };

  tableOption: TableOption = {
    viewMode: false,
    optionMode: true,
    infoMode: false,
    optionList: [
      {
        id: 'id_edit',
        title: 'Edit',
        svg: 'assets/images/icons-svg/edit-action.svg',
      },
      {
        id: 'id_delete',
        title: 'Delete',
        svg: 'assets/images/icons-svg/delete.svg',
      }
    ]
  };

  filterArray: any = {
    "jobTitle": [],
    "department": [],
    "date": [],
    "job_opening_status": []
  };

  constructor(
    private dialog: MatDialog,
    private toast: ToastrService,
    private jobApiService: JobApiService,
    private datePipe: DatePipe,
    private helper: FunctionHelperService,
    private downloadService: DownloadService,
    private commonService: CommonService
  ) { 
  }
  ngOnInit() {
    this.getJobOpeningStatusList();
    setTimeout(() => {
      this.getOpeningJobList('');
    }, 200);
   
  }

  getJobOpeningStatusList(): void {
    this.commonService.getJobStatus().subscribe((result) => {
      this.jobStatusList = result;
    })
  }

  getOpeningJobList(filterArray: any): void {
    this.filterArray = filterArray;
    const inProgessID = this.jobStatusList?.find((x:any) => x.name === 'In progress');
    const holdID = this.jobStatusList?.find((x:any) => x.name === 'Hold');
    const ids = [inProgessID?.id, holdID?.id];
    if (filterArray === '') {
      filterArray = {
        jobTitle: [],
        department: [],
        date: [],     
        job_opening_status: ids    
      };
    } else {
      filterArray.job_opening_status = ids
    }
    this.isLoading = true;
    this.jobApiService
      .getJobList(
        filterArray, (this.page - 1) * this.tableLimit,
        this.tableLimit
      )
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((res: any) => {
        this.setDefaultPage = false;
        this.isLoading = false;
        this.totalPages =
          res?.count % this.tableLimit == 0
            ? Math.trunc(res?.count / this.tableLimit)
            : Math.trunc(res.count / this.tableLimit) + 1;
        this.totalPages = this.totalPages > 3 ? 3 : this.totalPages;
        this.jobOpeningsList = res.results.map((item: any) => {
          return {
            id: item?.id,
            jobTitle: item.job_position?.name,
            department: item?.department?.name,
            job_position: item.job_position?.name,
            project_name: item.project_name,
            client_name: item.client_name,
            assigned_recruiter: item.assigned_recruiter?.name,
            date: item.target_date,
            // date: this.datePipe.transform(item.target_date, 'dd MMM yyyy'),
          };
        });

        this.tableKey = ['jobTitle', 'department', 'job_position', 'client_name',
          'project_name', 'date'];
      });
  }

  getChange(env: any) {
    console.log(env);
    
    switch (env.action) {
      case 'action':
        switch (env.id) {
          case 'id_edit':
            this.editJob(env.row);
            break;
          case 'id_delete':
            this.confirmDialog(env);
            break;
          default:
            break;
        }
        break;
      case 'pageLimit':
        this.tableLimit = env.pageLimit;
        this.getOpeningJobList(this.filterArray);
        break;
      case 'page':
        this.page = env.page;
        this.getOpeningJobList(this.filterArray);
        break;

      default:
        break;
    }
  }


  customEvent(env: any) {
    this.checkedData = env;
  }

  confirmDialog(data: any): void {
    const message = `Do you really wish to proceed with the deletion of this record?`;
    const dialogData = new ConfirmDialogModel('Confirm Action', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '500px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.deleteJob(data);
      }
    });
  }
  deleteJob(data: any): void {
    this.jobApiService.deleteJobDetails(data?.row?.id).pipe(
      catchError((err) => {
        this.toast.warning(err.error.message);
        return of(false)
      })
    ).subscribe((res) => {
      if (res.status === 'success') {
        this.toast.success(res.message);
        this.getOpeningJobList('');
      }
    });
  }
  editJob(row: any) {
    let dialogRef = this.dialog.open(JobAddComponent, {
      width: '500px',
      data: row,
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.getOpeningJobList('');
      }
    });
  }


  downloadExcel(filterArray: any): void {
    if (filterArray.actionFrom === 'table' && filterArray.type === 'export') {
      if (filterArray.selectedItem === 'id_export_selected') {
        if (filterArray.checkedData?.length === 0) {
          this.toast.warning('No Row Selected. Please Select Row');
          window.scrollTo(0, 0);
          return;
        }
        this.exportList(filterArray.checkedData, filterArray.filters);
      } else {
        this.exportList([], filterArray.filters);
      }
    }
  }

  getFilteredChange(env:any){
    this.tableLimit = 10;
    this.page = 1;
    this.setDefaultPage = true
    switch (env.type) {
      case 'search':
        this.getOpeningJobList(env.filterData);
        break;
      case 'filter':
        this.getOpeningJobList(env.filterData);
        break;
      case 'clear':
        this.getOpeningJobList(env.filterData);
        break;
      default:
        break;
    }
    
  }

  exportList(checkedData?: any[], filters?: any) {
    let filtersArray: any;
    const uuid: string[] = [];
    if (checkedData) {
      checkedData?.forEach((data: any) => {
        uuid.push(data);
      });
    }

    filtersArray = {
      "job_position": filters.job_position ||[],
      "department": filters?.department || [],
      "job_opening_status": filters?.job_opening_status || [],
      "dashboard": 1,
      "q": filters.q ||'',
      "ids": uuid,
      "offset": (this.page - 1) * this.tableLimit,
      "limit": this.tableLimit,
      }

    const apiUrl = environment.baseUrl + environment.apiVersion;
    this.downloadService
        .download(
          `${apiUrl}${ApiEndPoints.jobOpeningExport}?filter=${JSON.stringify(filtersArray)+'&token='+this.helper.userData.key}`
        )
        .subscribe((data) => {
          this.downloadService.createATagAndClickDownload(data, {
            name: 'Job Data List.xls',
          });
        });

    // let apiUrl = environment.baseUrl + environment.apiVersion + `${ApiEndPoints.jobOpeningExport}`;
    // const url = `${apiUrl}?filter=` + JSON.stringify(filtersArray)+'&token='+this.helper.userData.key;
    // window.open(url);
  }

}
