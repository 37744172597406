<section class="bnx-side-wrapper">
  <!-- top section -->
  <div class="bnx-side-wrapper__top-section">
    <div class="bnx-side-wrapper__logo-area">
      <div class="bnx-side-wrapper__logo-3">
        <img class="bnx-side-wrapper__menu-name-main" src="/assets/images/logo-B-colored.svg" alt="">
        <img class="bnx-side-wrapper__menu-name full-logo" src="/assets/images/logo-colored.svg" alt="">
      </div>
    </div>

    <div class="bnx-side-wrapper__side-arrow">
      <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 10.332L5 5.83203L1 1.33203" stroke="#546473" stroke-width="2"/>
        </svg>
    </div>

  </div>

    <div class="bnx-side-wrapper__menu-group bnx-scroll-box">
      <!-- tab groups -->
      <div class="bnx-side-wrapper__tab-groups">
        <!-- PAYABLE LINKS -->
        <div class="bnx-side-wrapper__single-group">
          <ul class="bnx-side-wrapper__main-list" >
            <li class="bnx-side-wrapper__list" #itemEl  [ngClass]="{'active' : route.url === item.route}"  *ngFor="let item of sidebar_data;let i=index" (click)="selectNavbar()">

              <div class="bnx-side-wrapper__single-name dropdown-title" *ngIf="item.sub_menu.length == 0">
                <a [routerLink]="item.route" class="link_name">
                  <!-- <img src="{{item.icons}}" alt=""> -->
                  <em class="{{item.icons}}"></em>
               <span class="bnx-side-wrapper__menu-name" >{{item.name}}</span> </a>
              </div>

              <div *ngIf="item.sub_menu.length > 0" class="dropdown-title" (click)="showSubmenu(itemEl)">
                <a class="bnx-side-wrapper__link-div">
                  <div class="bnx-side-wrapper__link-name">
                    <!-- <img src="{{item.icons}}" alt=""> -->
                    <em class="bnx-icon bnx-icon--dashboard"></em>
                    <span class="bnx-side-wrapper__menu-name">{{item.name}}</span>
                  </div>

                  <div class="bnx-side-wrapper__rotate">
                 <span>
                  <svg class="arrow-rotate" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    width="12px" height="12px" fill="#fff" viewBox="0 0 451.846 451.847" style="enable-background:new 0 0 451.846 451.847;"
                    xml:space="preserve">
                  <g>
                    <path d="M345.441,248.292L151.154,442.573c-12.359,12.365-32.397,12.365-44.75,0c-12.354-12.354-12.354-32.391,0-44.744
                      L278.318,225.92L106.409,54.017c-12.354-12.359-12.354-32.394,0-44.748c12.354-12.359,32.391-12.359,44.75,0l194.287,194.284
                      c6.177,6.18,9.262,14.271,9.262,22.366C354.708,234.018,351.617,242.115,345.441,248.292z"/>
                  </g>
                  </svg>
                 </span>
                </div>
                </a>
              </div>

              <ul class="bnx-side-wrapper__sub-menu" [class.blank]="item.sub_menu.length == 0">
                <li *ngFor="let item_sub of item.sub_menu" routerLinkActive="active">
                  <a [routerLink]="[item_sub.route]">{{item_sub.sub_name}}</a>
                </li>
              </ul>


            </li>
         </ul>
         <div class="bnx-side-wrapper__bottom-list">
          <div class="bnx-side-wrapper__bottom-txt">
            Logged in as
          </div>
          <div class="bnx-side-wrapper__personouter">
            <div class="bnx-side-wrapper__personimg">
              <img src="assets/images/avatar.png"  alt=""  class="bnx-source-img__profile">
            </div>
            <div class="bnx-side-wrapper__person-details">
              <div  class="bnx-side-wrapper__person-bold" >{{helper.userData.user_name}}</div>
              <div class="bnx-side-wrapper__person-pos" *ngIf="helper.userData.user_type !== 'SLT Directors'"> {{helper.userData.user_type.toUpperCase()}}</div>
              <div class="bnx-side-wrapper__person-pos"*ngIf="helper.userData.user_type === 'SLT Directors'">SLT DIRECTOR</div>
            </div>
          </div>
          <ul class="bnx-side-wrapper__main-list" title="Settings">
            <li class="bnx-side-wrapper__list" [ngClass]="{'active' : settingShow === true}" >
              <div class="bnx-side-wrapper__single-name dropdown-title">
                <a class="link_name" (click)="toSettings()">
                  <em class="bnx-icon bnx-icon--settings" ></em>
                </a>
              </div>
            </li>
            <li class="bnx-side-wrapper__list" title="Logout">
              <div class="bnx-side-wrapper__single-name dropdown-title">
                <a class="link_name" (click)="logout()">
                  <em class="bnx-icon bnx-icon--exit"></em>
                    <span class="bnx-side-wrapper__menu-name">Logout</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
        </div>
    </div>

  </div>
</section>
