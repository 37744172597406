<div  class="bnx-notifications" >

  <div class="bnx-modal__close-btn notification-close" >
    <svg width="10" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times"
       class="svg-inline--fa fa-times fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
       <path fill="#7d7d7d"
          d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z">
       </path>
    </svg>
  </div>

    <div class="bnx-notifications__wrapper">
        <div (click)="$event.stopPropagation()" class="bnx-notifications__flex-betwen mt-2">
            <h4>Notifications</h4>
            <a [ngClass]="{'disable':commonService.notifList.length===0}" *ngIf="commonService.notifList.length !==0 && !commonService.notifyLoading" (click)="clearAllNotification()">Clear all</a>
        </div>

        <div class="bnx-notifications__main-body bnx-scroll-box">
            <div *ngFor="let notif of commonService.notifList;let i=index" [ngClass]="{'bnx-notifications__body-new':notif.unread}"
                class="bnx-notifications__body">
                <div class="bnx-notifications__content">
                    <div (click)="$event.stopPropagation()" class="bnx-notifications__right">
                        <div *ngIf="notif.unread" class="round blue"></div>
                    </div>
                    <div class="bnx-notifications__left">
                        <div >
                            <h3 [title]="notif.title">{{notif.title}}</h3>
                        </div>
                        <div (click)="$event.stopPropagation()">
                            <p class="bnx-notifications__left--desc-notif" [ngClass]="{ 'read-more' : notif.is_open}" [innerHTML]="notif.verb"></p>
                        </div>
                    </div>

                </div>

                <div (click)="$event.stopPropagation()" class="bnx-notifications__read-date">
                    <h5>{{notif.timestamp| date:'d-MMM-y'}}</h5>
                    <a (click)="expand(i)" *ngIf="notif.notifLength > 95">{{notif.is_open? 'Read less':'Read More..'}}</a>
                </div>
            </div>

            <div (click)="$event.stopPropagation()" *ngIf="commonService.notifList.length===0 && !commonService.notifyLoading" class="nodata">
                <p>No Notifications</p>
            </div>
            <div (click)="$event.stopPropagation()" *ngIf="commonService.notifyLoading" class="nodata">
                <app-table-loader></app-table-loader>
            </div>

        </div>


    </div>

</div>
