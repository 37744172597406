import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import { FunctionHelperService } from 'src/app/core/helper/function.helper.service';
import { editDelete, TableOption } from 'src/app/core/interface/table';
import { DownloadService } from 'src/app/core/services/common/download.service';
import { InterviewScheduleService } from 'src/app/modules/user/services/interview-schedule-api.service';
import { InterviewDetailsComponent } from 'src/app/shared/widget/modals/interview-details/interview-details.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-upcomming-interviews',
  templateUrl: './upcomming-interviews.component.html',
  styleUrls: ['./upcomming-interviews.component.scss'],
})
export class UpcommingInterviewsComponent {
  isLoading: boolean = false;
  upcommingJobList: any[] = [];
  totalPages!: number;
  tableLimit = 10;
  page = 1;
  tableTitle = ['Name', 'Email ID', 'Job Title','Department' ,'Date', 'Status', , ''];
  tableKey = ['name', 'emailID', 'jobTitle', 'department', 'date', 'status'];
  setDefaultPage: boolean =false;
  statusList = [
    { status: 'In Progress', color: '#17a2b8' },
    { status: 'Pending', color: '#ffc107' },
    { status: 'Shortlist', color: '#007bff' },
    { status: 'Completed', color: '#28a745' },
    { status: 'On hold', color: '#6c757d' },
    { status: 'Rejected', color: '#dc3545' }
  ];

  tableEditDelele: editDelete = {
    edit: false,
    delete: false,
  };

  tableOption: TableOption = {
    viewMode: false,
    infoMode: false,
    optionMode: false,
    optionList: [
      {
        id: 'id_approve_contract',
        title: 'Approve Contract',
        svg: 'assets/images/icons-svg/approved.svg',
        condition: {
          field: 'statusName',
          value: ['PENDING'],
        },
      },
      {
        id: 'id_view_history',
        title: 'View History',
        svg: 'assets/images/icons-svg/view-history.svg',
      },
      {
        id: 'id_edit_job',
        title: 'Edit Job',
        svg: 'assets/images/icons-svg/edit-action.svg',
      },
      {
        id: 'id_delete_job',
        title: 'Delete Job',
        svg: 'assets/images/icons-svg/delete.svg',
      },
    ],
  };

  constructor(
    private dialog: MatDialog,
    private InterviewScheduleService: InterviewScheduleService,
    private toast: ToastrService,
    private datePipe: DatePipe,
    private helper: FunctionHelperService,
    private downloadService: DownloadService

  ) {}

  ngOnInit(): void {
    this.getallUpcomming('');
  }

  getallUpcomming(filterArray: any): void {
    if (filterArray?.q !== ''){
      this.tableLimit = 10;
      this.page = 1;
    }
    if (filterArray == '') {
      filterArray = {
        "job_opening_status" : [],
        "jobTitile": [],
        "name": [],
        "emailID": [],
      };
    }
    this.isLoading = true;
    this.InterviewScheduleService.upcommingInterviewList (
      filterArray, (this.page - 1) * this.tableLimit,
      this.tableLimit
    )
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((res: any) => {
        this.isLoading = false;
        this.setDefaultPage = false;
        this.upcommingJobList = res.results;
        this.totalPages =
          res?.count % this.tableLimit == 0
            ? Math.trunc(res?.count / this.tableLimit)
            : Math.trunc(res.count / this.tableLimit) + 1;
        this.totalPages = this.totalPages > 3 ? 3 : this.totalPages;
        this.upcommingJobList = res.results.map((item: any) => {
          let tableColor = this.statusList.filter((res: any) => {
            if (res.status == item.status) {
              return res.color;
            }
          })

          return {
            id: item?.id,
            name: item?.candidate?.name,
            emailID: item?.candidate?.email,
            jobTitile: item?.candidate?.job_openings?.job_title,
            department: item?.candidate?.department,
            date: item?.date,
            // date: this.datePipe.transform(item?.date, 'dd MMM yyyy'),
            status: {
              label: item?.status,
              color: tableColor[0]?.color
            },
          };
        });

        this.tableKey = ['name', 'emailID', 'jobTitile', 'department', 'date', 'status'];
      });
  }

  getChange(env: any) {
    switch (env.action) {
      case 'viewDetail':
        this.viewDetails(env.row.id);
        break;

        case 'pageLimit':
        this.tableLimit = env.pageLimit;
        this.getallUpcomming('');
        break;
        case 'page':
        this.page = env.page;
        this.getallUpcomming('');
        break;
      default:
        break;
    }
  }

  getFilteredChange(env:any){
    this.tableLimit = 10;
        this.page = 1;
        this.setDefaultPage = true
    switch (env.type) {
      case 'search':
        this.getallUpcomming(env.filterData);
        break;
      case 'filter':
        this.getallUpcomming(env.filterData);
        break;
      case 'clear':
        this.getallUpcomming(env.filterData);
        break;
      default:
        break;
    }
    
  }


  viewDetails(id: any) {
    let dialogRef = this.dialog.open(InterviewDetailsComponent, {
      width: '550px',
      data: id,
    });
    this.InterviewScheduleService.getInterviewDetailsById(id);
  }

  downloadExcel(filterArray: any): void{
    if(filterArray == ''){
      filterArray = {
        "location" : [],
        "job_position" : [],
        "job_opening_status" : [],
       }
    }

    const apiUrl = environment.baseUrl + environment.apiVersion;
    this.downloadService
        .download(
          `${apiUrl}upcoming-interview-export/xls_download?filter=${JSON.stringify(filterArray)+'&token='+this.helper.userData.key}`
        )
        .subscribe((data) => {
          this.downloadService.createATagAndClickDownload(data, {
            name: 'Upcomming Interview Data List.xls',
          });
        });

    // let apiUrl = environment.baseUrl + environment.apiVersion;
    // window.open(apiUrl + 'upcoming-interview-export/xls_download?filter='+ JSON.stringify(filterArray)+'&token='+this.helper.userData.key);
    }




}
