import { HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { finalize, Observable, tap } from 'rxjs';
//import { FullService } from 'src/app/shared/layouts/full/full.service';
import { FunctionHelperService } from '../helper/function.helper.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService {

  constructor(
    private helper:FunctionHelperService,
    private router:Router,
    private toast:ToastrService,
    //private layoutService:FullService
  ) { }

  intercept(
    httpRequest: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    //this.layoutService.isLoading = true;
    if (!httpRequest.url.includes('https://graph.microsoft.com')){
    if(this.helper.token.key !==''){
      httpRequest = httpRequest.clone({
        setHeaders: {
          Accept: 'application/json',
          Authorization: `Token ${this.helper.token.key} `,
        }
      });
    }
  }
    return next.handle(httpRequest).pipe(
      // finalize(() => this.layoutService.isLoading = false),
      tap((event: any) => {
        if (event instanceof HttpResponse) {
        }
      },
        (error: any) => {
          if (error.status == 401) {
            console.warn('delete cookie from interceptor');
            this.helper.deleteCookie();
            this.toast.error('Unauthenticated...')
            this.router.navigateByUrl('login/');
          };
        }
      )
    )
  }
}
