import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { loginUrls, returnUrl, userRole } from 'src/app/core/constants/constants';
import { FunctionHelperService } from 'src/app/core/helper/function.helper.service';

@Component({
  selector: 'app-validate',
  templateUrl: './validate.component.html',
})

export class ValidateComponent {
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _helper: FunctionHelperService,
    private router: Router,) { }

  ngOnInit() {
    const key = this._activatedRoute.snapshot.queryParamMap.get('key');
    const username = this._activatedRoute.snapshot.queryParamMap.get('user_name');
    const role = this._activatedRoute.snapshot.queryParamMap.get('user_type');
    const user_id = this._activatedRoute.snapshot.queryParamMap.get('user_id');

    const data:any = {'key': key,'user_name': username,'user_type': role,'status': 200, 'user_id': user_id};
    this._helper.setUser(data);
    const returnUrlValue = this._activatedRoute.snapshot.queryParamMap.get(returnUrl);
    if ( role === userRole.admin || role === userRole.hr || role === userRole.director || role === userRole.ceo) {
      this.router.navigate ([returnUrlValue || loginUrls.dashboard]);
    } else if(role === "Interviewer"){
      this.router.navigate([returnUrlValue || loginUrls.employee]);
    }
  }
}
