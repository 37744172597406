<div class="bnx-modal">
  <div class="bnx-modal__close-btn" [mat-dialog-close]="false">
    <svg width="15" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times"
      class="svg-inline--fa fa-times fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
      <path fill="currentColor"
        d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z">
      </path>
    </svg>
  </div>


  <div class="bnx-modal__title">
    <h2 class="bnx-modal__heading">Bulk Import </h2>
  </div>
  <div class="bnx-view-modal__body">
    <div class="bnx-modal__wrapper">
      <div class="bnx-form__row">

        <div class="bnx-subtitle">
          <label class="bnx-form__label">Click the link below to download the template file. Make sure to save it to your local device: </label>

          <div class="bnx-subtitle__buttons">
            <img src="../../../../../assets/images/icons-svg/icon-park-solid_folder-download.svg" class="template" alt="" >
            <a href="{{template}}"  style="text-decoration: none; color: white;"><h3>Download Template</h3></a>
            <!-- target="_blank" -->
          </div>

          <div class="resume__update">
            <div class="bnx-form__label bnx-form__upload">Upload Document</div>

            <div class="bnx-dropzone" id="drop-zone" appFiledrop (filesChangeEmiter)="onFileChange($event,'drop')">
              <div class="bnx-dropzone__text-wrapper">
                <div class="bnx-dropzone__centered">
                  <img src="../../../../../assets/images/icons-svg/drophere.svg" alt="">
                  <input type="file" (change)="onFileChange($event.target,'select')"
                    accept="'.xls','.xlsx', 'text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', '.csv'"
                    name="file" id="file" #fileInput>
                  <label for="file"><span class="bnx-dropzone__textLink">Drag and drop your files here</span></label>
                  <label for="file"><span class="bnx-dropzone__textLink">Or</span></label>
                  <label for="file"><span class="bnx-dropzone__textLink browse"> <a>Browse</a> </span></label>
                </div>
              </div>
            </div>
            <div class="bnx-file-data">
              <div *ngFor="let file of fileArray;let i=index" class="bnx-file-data__card">
                <svg class="bnx-file-data__card__icon" xmlns="http://www.w3.org/2000/svg" width="15" height="19"
                  viewBox="0 0 15 19" fill="none">
                  <path
                    d="M14.0769 8.07993V16.0385C14.0769 16.5587 13.8703 17.0576 13.5024 17.4255C13.1345 17.7933 12.6356 18 12.1154 18H2.96154C2.44131 18 1.94238 17.7933 1.57452 17.4255C1.20666 17.0576 1 16.5587 1 16.0385V2.96154C1 2.44131 1.20666 1.94238 1.57452 1.57452C1.94238 1.20666 2.44131 1 2.96154 1H6.997C7.3437 1.00005 7.67619 1.13778 7.92137 1.38291L13.694 7.15555C13.9391 7.40074 14.0769 7.73323 14.0769 8.07993Z"
                    stroke="#005695" stroke-width="1.5" stroke-linejoin="round" />
                  <path
                    d="M7.53876 1.3269V6.23075C7.53876 6.57757 7.67654 6.91019 7.92178 7.15543C8.16702 7.40067 8.49963 7.53844 8.84645 7.53844H13.7503M4.26953 10.8077H10.808M4.26953 14.0769H10.808"
                    stroke="#005695" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

                <label class="bnx-file-data__card__text">{{file?.name ?? file?.document_name}}</label>
                <svg class="bnx-file-data__card__close" (click)="removeFile(i)" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24" width="24" height="24">
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </div>
            </div>
      </div>

      <div class="submit">
        <button class="bnx-btn bnx-btn-border" [mat-dialog-close]="false">Cancel</button>
        <button class="bnx-btn" (click)="importCandidate()" [disabled]="isLoading ? true : false">{{isLoading ? 'Uploading...' : 'Upload'}}</button>
      </div>
    </div>
  </div>




















</div>
