<div class="bnx-modal bnx-modal--modal2">
  <div class="bnx-modal__close-btn" [mat-dialog-close]="false">
    <svg width="15" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" class="svg-inline--fa fa-times fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
      <path
        fill="currentColor"
        d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
      ></path>
    </svg>
  </div>

    <div class="bnx-modal">
        <div class="bnx-head">

          <div class="bnx-modal__left-head">
            <div class="bnx-modal__head-icon">
              <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="37" cy="37" r="35.5" fill="#EDF7FF" stroke="#8FBADD" stroke-width="3"/>
                <circle cx="37.0002" cy="25.6946" r="14.3889" fill="#8FBADD"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2897 65.4054C11.6088 63.2655 10.667 60.8666 10.667 58.3331C10.667 49.3124 22.6061 41.9998 37.3337 41.9998C52.0613 41.9998 64.0003 49.3124 64.0003 58.3331C64.0003 60.2824 63.4428 62.1519 62.4201 63.8852C55.7912 70.155 46.8446 73.9997 37.0001 73.9997C27.9782 73.9997 19.7105 70.7707 13.2897 65.4054Z" fill="#8FBADD"/>
              </svg>
            </div>

            <div class="bnx-modal__head-title">
              <p>{{interviewDetails?.candidate}}</p>
              <h4>{{interviewDetails?.jobTitle}}</h4>
            </div>

          </div>

          <div class="right">
            <p [ngStyle]="{'background-color' :interviewDetails?.status?.color}">{{interviewDetails?.status?.label === 'Shortlist' ? 'Shortlisted' : interviewDetails?.status?.label}}</p>
          </div>

        </div>

        <div class="bnx-modal-side-title">

          <div class="left">
            <h4> <span class="icon"><svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.1677 1H2.5072C1.6748 1 1 1.6748 1 2.5072V11.5504C1 12.3828 1.6748 13.0576 2.5072 13.0576H15.1677C16.0001 13.0576 16.6749 12.3828 16.6749 11.5504V2.5072C16.6749 1.6748 16.0001 1 15.1677 1Z" stroke="#0480F2" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M3.41211 3.41138L8.83804 7.63154L14.264 3.41138" stroke="#0480F2" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              </span> <span>{{interviewDetails?.email}}</span></h4>
            <h4> <span class="icon">
              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.0417 8.85417C11.1562 8.85417 10.3062 8.7125 9.51292 8.45042C9.44208 8.42917 9.36417 8.415 9.29333 8.415C9.10917 8.415 8.93208 8.48583 8.79042 8.62042L7.23208 10.1788C5.22318 9.15676 3.58921 7.52527 2.56417 5.51792L4.1225 3.9525C4.32083 3.76125 4.3775 3.485 4.29958 3.23708C4.03088 2.42112 3.89457 1.5674 3.89583 0.708333C3.89583 0.31875 3.57708 0 3.1875 0H0.708333C0.31875 0 0 0.31875 0 0.708333C0 7.35958 5.39042 12.75 12.0417 12.75C12.4312 12.75 12.75 12.4312 12.75 12.0417V9.5625C12.75 9.17292 12.4312 8.85417 12.0417 8.85417ZM1.43792 1.41667H2.50042C2.55 2.04 2.65625 2.65625 2.82625 3.25125L1.97625 4.10833C1.68583 3.25125 1.50167 2.35167 1.43792 1.41667ZM11.3333 11.3121C10.3983 11.2483 9.49167 11.0642 8.64167 10.7737L9.49167 9.92375C10.0937 10.0937 10.71 10.2 11.3333 10.2425V11.3121Z" fill="#0480F2"/>
                </svg>
            </span> <span>{{interviewDetails?.phone}}</span></h4>
          </div>

          <div class="right">
            <p>Source - {{interviewDetails?.source}}</p>
          </div>

        </div>


  </div>


  <div class="bnx-view-modal__body">
    <div class="bnx-modal__wrapper">
      <div class="bnx-table-view">
        <table  class="bnx-table-wrapper__bnx-table bnx-scroll-box">
          <thead class="bnx-thead">
            <tr>
              <th *ngFor="let title of tableTitle">{{title}}</th>
            </tr>
          </thead>
          <tbody class="bnx-body">

            <tr *ngFor="let item of panel_list ; let i=index">
              <td>{{item?.level}}</td>
              <td>
                <tr *ngFor="let xx of item.interviewer">{{xx?.name}}</tr>
            </td>

            <td>
              <tr *ngFor="let xx of item.optInterviewer">{{xx?.name}}</tr>
          </td>
            <td>{{item?.type}}</td>
              <td>{{item?.date}}</td>
              <td>{{item?.start_time}}</td>

            </tr>

          </tbody>
        </table>

        </div>
        <!-- <app-pagination *ngIf="data.length != 0" [_totalPage]="totalPages" (change)="getPage($event)"></app-pagination> -->




      <!-- <app-table-view [tableEditDelele]="tableEditDelele" [data]="interviewDetails"  [tableKeys]="tableKey" [tableOption]="tableOption" [tableTitle]="tableTitle" ></app-table-view> -->
    </div>
  </div>
</div>
