<div class="bnx-filter">
  <app-filter  [showMultiExcelDownload]="true" [department]="true" [projects]="true" [componentName]="'dashboardJobs'"
    (download)="downloadExcel($event)" (filterChange)="getFilteredChange($event)" [actionList]="adminActionList"
    [checkedEmit]="checkedData"
    ></app-filter>
</div>

<div class="bnx-table-wrapper__card">
  <div class="bnx-table-wrapper__card-body">
    <div class="bnx-table-wrapper__table-card">
      <app-table-view [setDefault]="setDefaultPage" [data]="jobOpeningsList" [tableKeys]="tableKey" [checkboxrequired]="true"
        [tableTitle]="tableTitle" [tableEditDelele]="tableEditDelele" (event)="getChange($event)"
        [tableOption]="tableOption" [totalPages]="totalPages" [isLoading]="isLoading" (checkChange)="customEvent($event)"></app-table-view>
    </div>
  </div>
</div>