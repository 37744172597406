<div class="bnx-modal">
  <div class="bnx-modal__close-btn" [mat-dialog-close]="false">
    <svg
      width="15"
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="times"
      class="svg-inline--fa fa-times fa-w-10"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
    >
      <path
        fill="currentColor"
        d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
      ></path>
    </svg>
  </div>

  <div class="bnx-modal__title">
    <h2 class="bnx-modal__heading">More Details</h2>
  </div>

  <div class="bnx-profile__wrapper">
    <div class="bnx-profile__details-tile">
      <div class="bnx-profile__details-label">Fixed Pay</div>
      <div class="bnx-profile__details-text">{{data.fixed_pay ?? '--'}}</div>
    </div>

    <div class="bnx-profile__details-tile">
      <div class="bnx-profile__details-label">Variable Pay</div>
      <div class="bnx-profile__details-text">{{data.variable_pay ?? ''}}</div>
    </div>

    <div class="bnx-profile__details-tile">
      <div class="bnx-profile__details-label">Currently Serving</div>
      <div class="bnx-profile__details-text">{{data.servingNoticePeriod ?? '-'}}</div>
    </div>
    <div class="bnx-profile__details-tile">
      <div class="bnx-profile__details-label">Last Working Day</div>
      <div class="bnx-profile__details-text">{{data.lastWorking ?? '-'}}</div>
    </div>
    <div class="bnx-profile__details-tile">
      <div class="bnx-profile__details-label">Current Company</div>
      <div class="bnx-profile__details-text">{{data.cuurentCompany ?? '-'}}</div>
    </div>
    <div class="bnx-profile__details-tile">
      <div class="bnx-profile__details-label">Onsite</div>
      <div class="bnx-profile__details-text">{{data?.onSite ?? '-'}}</div>
    </div>
    <div class="bnx-profile__details-tile">
      <div class="bnx-profile__details-label">Passport Status</div>
      <div class="bnx-profile__details-text">{{data.passport ?? ''}}</div>
    </div>
    <div class="bnx-profile__details-tile">
      <div class="bnx-profile__details-label">Validity</div>
      <div class="bnx-profile__details-text">{{data.passportValidity ?? ''}}</div>
    </div>

    <div class="bnx-profile__details-tile" *ngIf="loggedUserType === 'SLT Directors' || loggedUserType === 'HR'">
      <div class="bnx-profile__details-label negotiable">Negotiable CTC</div>
      <div class="bnx-profile__details-text">{{data.negotiable_ctc ?? '-'}}</div>
    </div>

    <div class="bnx-profile__details-tile full-width" *ngIf="data.certificates?.length !== 0">
      <div class="bnx-profile__details-label">Certificates</div>
      <div class="bnx-profile__details-table bnx-scroll-box">
        <div class="bnx-profile__details-table-outer">
          <div class="bnx-profile__details-table-head-sec">
            <div class="bnx-profile__th">
              Certificate Code
            </div>
            <div class="bnx-profile__th">
              Certificate Name
            </div>
            <div class="bnx-profile__th">
              Validity
            </div>
          </div>
          <div class="bnx-profile__table-contents bnx-scroll-box">
            <div class="bnx-profile__tr" *ngFor="let cer of data.certificates">
              <div class="bnx-profile__details-td">
                {{cer.certificate_code}}
              </div>
              <div class="bnx-profile__details-td">
                {{cer.certificate_name}}
              </div>
              <div class="bnx-profile__details-td">
                {{cer.validity | date: 'dd MMMM yyyy'}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="bnx-profile__submit-button">
    <button class="bnx-btn bnx-btn-border" [mat-dialog-close]="false">
      Close
    </button>
  </div>
</div>
