

export const environment = {
  production: false,
  version: '2023.1',
  //  baseUrl: 'http://local.beinex.com:18015/',
  //  baseUrl: 'http://local.beinex.com:18015/',
  // baseUrl: 'https://recruit.beinex.com/',
  baseUrl: 'https://recruit-staging.beinex.com/',
  cookieDomain:'https://tss.beinex.com/',
  apiVersion: 'api/v1/',
};
