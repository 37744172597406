
<div class="gnl-settings">
  <div class="gnl-settings__tabs-sec">
    <img src="../../../../../../assets/images/general-settings-img.png" alt="" class="gnl-settings__img" />
    <div class="gnl-settings__tabs-inner">
      <a class="gnl-settings__tab" [ngClass]="{'active':settingDetail.id=='addSkill'}" [routerLink]="'/main/settings/list/skills'" (click)="changeTab('addSkill')">
        <em class=" bnx-icon--skills"></em>
        Skills
      </a>
      <a  class="gnl-settings__tab" [routerLink]="'/main/settings/list/jobtitle'" [ngClass]="{'active':settingDetail.id=='addJobtitle'}"(click)="changeTab('addJobtitle')">
        <em class="bnx-icon bnx-icon--employment"></em>
        Job Title
      </a>
      <a class="gnl-settings__tab" [routerLink]="'/main/settings/list/location'" [ngClass]="{'active':settingDetail.id=='addLocation'}"(click)="changeTab('addLocation')">
        <em class="bnx-icon bnx-icon--location"></em>
        Location
      </a>
      <a class="gnl-settings__tab" [routerLink]="'/main/settings/list/department'" [ngClass]="{'active':settingDetail.id=='addDepartment'}"(click)="changeTab('addDepartment')">
        <em class="bnx-icon bnx-icon--location"></em>
        Department
      </a>
      <a class="gnl-settings__tab" [routerLink]="'/main/settings/list/template'" [ngClass]="{'active':settingDetail.id=='addTemplate'}"(click)="changeTab('addTemplate')">
        <em class="bnx-icon bnx-icon--employment-1"></em>
        Email Template
      </a>
    </div>
  </div>
  <hr class="gnl-settings__line">
  <div class="gnl-settings__tables-sec">
    <div class="bnx-main-header">
      <div class="bnx-main-header__title">
        <!-- <h3>{{settingDetail?.listTitle ?? 'List'}}</h3> -->
      </div>
      <div class="bnx-main-header__right-sec" >
        <div class="bnx-form__group">
          <div class="bnx-currency-item">

          <button (click)="openCommonModal()" type="submit" class="bnx-btn ">
            <em class="bnx-icon bnx-icon--plus"></em>
            {{settingDetail?.title}}
        </button>
          </div>
        </div>
      </div>
    </div>
    <!-- BNX TABLE TABE -->
<div class="bnx-table-tab">
  <div class="common-mat-tab">
    <mat-tab-group
      class="common-mat-tab"
      animationDuration="0ms"
      class="bnx-mat-tab"
      mat-stretch-tabs="false"
      mat-align-tabs="start"
    >
      <mat-tab label="{{settingDetail?.listTitle ?? 'List'}}">
        <div>
          <div class="bnx-filter">
            <app-filter [componentName]="'settings'" [searchClear]="searchClear" [showExcelDownload]="false" [demandJobStatus]="false" [showLocation]="false"  [department]="false" [roll]="false" (filterChange)="getFilteredChange($event)"></app-filter>
          </div>
          <ng-container>
          <app-job-title-list  [data]="tableList" [tabKey]="settingDetail.id" [isLoading]="isLoading"  (event)="getChange($event)" [totalPages]="totalPages" [tableOption]="actionsList" [settingDetail]="settingDetail"></app-job-title-list>
        </ng-container>
      </div>
    </mat-tab>
    </mat-tab-group>
  </div>
</div>
<!-- BNX TABLE TABE -->

  </div>
</div>

