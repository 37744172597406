import { Component, Inject, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import { ValidationService } from 'src/app/core/services/common/validation.service';
import { InterviewScheduleService } from 'src/app/modules/user/services/interview-schedule-api.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../confirm-dialog/confirm-dialog.component';
import { MicrosoftAuthService } from 'src/app/shared/service/microsoft-auth.service';
import { Router } from '@angular/router';
import { SuccessAlertComponent } from '../../success-alert/success-alert.component';

@Component({
  selector: 'app-interview-add',
  templateUrl: './interview-add.component.html',
  styleUrls: ['./interview-add.component.scss'],
})
export class InterviewAddComponent implements OnInit {
  interviewForm!: FormGroup;
  isLoading = false;
  isEdit = false;
  isSchedule: boolean = false;
  interviewCandidateList: any[] = [];
  deletedItem: any[] = [];
  deletedRecord: any[] = [];
  interviewStatusList = ['In Progress', 'Pending', 'Shortlist', 'On hold', 'Completed', 'Rejected'];
  modeList = ['Teams'];
  interviewLevels: any = [];
  interviewType: any = [];
  recruiterList: any = [];
  timeList = ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30',
    '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'];
  selectedUser: any;
  interviewerDetails: any[] = [];
  optinterviewerDetails: any[] = [];
  selectedInterviewer: any[] = [];
  optionalInterviewer: any[] = [];
  scheduledID: any = '';
  meetingID: any = '';
  meetingDetails: any;
  basicDetails: boolean = true;
  interviewPanel: boolean = false;
  modalChange: boolean = false;
  minDate: string = '';
  showOptional: boolean = false;
  public editPayload: any;
  public formSubmitted: boolean = false;
  constructor(private fb: FormBuilder,
    private interviewApiService: InterviewScheduleService,
    private commonService: CommonService,
    private microSoftAuthService: MicrosoftAuthService,
    private toast: ToastrService, private router: Router,
    public dialogRef: MatDialogRef<InterviewAddComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public interviewId: any
  ) { }
  ngOnInit(): void {
    this.initForm();
    this.getInterviewCandidateList();
    this.getStatus();
    this.setInterviewDate();
    this.getRecruiterList();
    if (this.interviewId) {
      if (this.router.url.includes('interviewer-candidate-panel')) {
        this.isSchedule = true;
        this.modalChange = true;
        this.getCandidateDetail(this.interviewId);
      } else {
        this.isEdit = true;
        this.getInterviewDetails();
      }
    }
  }


  setInterviewDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1; // Months are 0-based
    const day = today.getDate();
    this.minDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  }


  getControl(i: number, controlName: string) {
    return (this.ctrl['controls'][i] as FormGroup)['controls'][controlName];

  }
  initForm() {
    this.interviewForm = this.fb.group({
      candidate: new FormControl('', Validators.required),
      job_opening_id: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      interview_status: new FormControl(null, Validators.required),
      title: new FormControl(null, Validators.required),
      mode: new FormControl('Teams'),
      panel_list: this.fb.array([this.interviewAddbody()]),
      candidate_name: ('')
    });
  }

  nextBtn() {
    const data = this.interviewForm.value
    if (this.basicDetails === true) {
      this.interviewForm.get('title')?.markAsTouched();
      this.interviewForm.get('interview_status')?.markAsTouched();

      if (data.title !== null && data.interview_status !== null) {
        this.interviewPanel = true;
        this.basicDetails = false;
      }
    } else {
      this.interviewPanel = true
      this.basicDetails = false
    }
  }


  nextBtnSchedule() {
    const data = this.interviewForm.value
    if (this.basicDetails === true) {
      this.interviewForm.get('candidate')?.markAsTouched();
      this.interviewForm.get('job_opening_id')?.markAsTouched();
      this.interviewForm.get('phone')?.markAsTouched();
      this.interviewForm.get('title')?.markAsTouched();
      this.interviewForm.get('email')?.markAsTouched();
      this.interviewForm.get('interview_status')?.markAsTouched();

      if (data.candidate !== '' && data.title !== null) {
        this.interviewPanel = true;
        this.basicDetails = false;
      }
    } else {
      this.interviewPanel = true
      this.basicDetails = false
    }

  }

  clearBtn() {
    this.interviewForm.reset()
  }

  back() {
    this.interviewPanel = false;
    this.basicDetails = true;
  }


  getSelected(env: any) {
    this.interviewForm.controls['candidate'].setValue(env.id);
    this.selectedUser = env;
  }

  getSelectedItem(env: any) {
    this.interviewForm.controls['job_opening_id'].setValue(env.job_openings.job_title);
    this.interviewForm.controls['email'].setValue(env.email);
    this.interviewForm.controls['phone'].setValue(env.phone);
    this.interviewForm.controls['candidate_name'].setValue(env.name);
    this.getInterviewType(env.job_openings?.id);
  }

  getStatus() {
    this.interviewForm.controls['interview_status'].setValue(this.interviewStatusList[1]);
  }


  interviewAddbody() {
    return this.fb.group({
      id: new FormControl(''),
      level: new FormControl(null, Validators.required),
      interviewer: new FormControl(null, Validators.required),
      type: new FormControl(null),
      optional_interviewer: new FormControl(null),
      date: new FormControl('', [Validators.required, Validators.min(1)]),
      start_time: new FormControl('', [Validators.required, Validators.min(1)]),
      end_time: new FormControl('', [Validators.required, Validators.min(1)])
    });
  }

  getInterviewCandidateList(): void {
    this.interviewApiService.getInterviewCandidateList().subscribe((res: any) => {
      this.interviewCandidateList = res;
    })
  }


  getInterviewType(jobId: any): void {
    this.interviewApiService.getInterviewType(jobId).subscribe((res: any) => {
      const type = res[0];
      this.interviewLevels = type.levels;
    })
  }

  getSelectedLevel(env: any) {
    this.ctrl.patchValue([
      { 'level': env.level, 'type': env.interview_round?.title }
    ])
  }

  getRecruiterList(): void {
    let filterArray = {
      'license_type': [],
      'is_active': [],
      'q': []
    }
    this.commonService.getJRecruiterList(filterArray).subscribe((result) => {
      this.recruiterList = result;
    })
  }

  get ctrl() {
    return this.interviewForm.get('panel_list') as FormArray;
  }

  addCtrl(i: any) {
    this.ctrl['controls'][i].markAllAsTouched();
    if (this.ctrl['controls'][i].valid) {
      this.ctrl.push(this.interviewAddbody());
    }
  }

  confirmDialog(data: any): void {
    const message = `Do you really wish to proceed with the deletion of this record?`;
    const dialogData = new ConfirmDialogModel("Confirm Action", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "500px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.removeCtrl(data);
      }
    });
  }


  removeCtrl(i: any) {
    if (this.isEdit) {
      this.deletedItem.push(this.getControl(i, 'id').value);
      this.deletedRecord = [
        ...this.deletedRecord
      ];
    }
    this.ctrl.removeAt(i);
  }

  remove(data: any, type: any): void {
    const item: any = this.ctrl['controls'][0].value
    if (type == "skills") {
      this.selectedInterviewer?.forEach((element: any, index: any) => {
        if (data.id == element.id) {
          var index = index;
          if (index !== -1) {
            this.selectedInterviewer?.splice(index, 1);
            if (data.id == item.interviewer) {
              this.getControl(0, 'interviewer').reset();
              if (this.selectedInterviewer.length === 0) {
                this.ctrl['controls'][0].get('interviewer')?.reset();
                this.getControl(0, 'interviewer').reset();
              }
            }
          }
        }
      })
    }

    if (type == "opt") {
      this.optionalInterviewer?.forEach((element: any, index: any) => {
        if (data.id == element.id) {
          var index = index;
          if (index !== -1) {
            this.optionalInterviewer?.splice(index, 1);
            if (data.id == item.optional_interviewer) {
              this.getControl(0, 'optional_interviewer').reset();
              if (this.optionalInterviewer.length === 0) {
                this.ctrl['controls'][0].get('optional_interviewer')?.reset();
                this.getControl(0, 'optional_interviewer').reset();
              }

            }
          }
        }
      })
    }
  }

  scheduleInterview() {
    this.interviewForm.markAllAsTouched();
    if (this.interviewForm.valid) {
      // if (this.interviewForm.value.mode === 'Telephonic') {
      //   this.saveScheduleInterview();
      // } else {
      this.microSoftAuthService.signIn();
      this.isLoading = true;
      setTimeout(() => {
        this.saveScheduleInterview();
      }, 5000);
      // }
    }
  }

  saveScheduleInterview() { 
    if (!this.isEdit) {
      this.interviewerDetails.splice(0);
      this.selectedInterviewer?.forEach(ele => {
        this.interviewerDetails.push(ele.id);
      });
      this.interviewForm.controls['panel_list'].value[0].interviewer = this.interviewerDetails;

      this.optinterviewerDetails.splice(0);
      this.optionalInterviewer?.forEach(ele => {
        this.optinterviewerDetails.push(ele.id);
      });
      this.interviewForm.controls['panel_list'].value[0].optional_interviewer = this.optinterviewerDetails;
    }
    else {
      this.interviewerDetails.splice(0);
      this.selectedInterviewer?.forEach(ele => {
        this.interviewerDetails.push(ele.id);
      });
      this.interviewForm.controls['panel_list'].value[0].interviewer = this.interviewerDetails;

      this.optinterviewerDetails.splice(0);
      this.optionalInterviewer?.forEach(ele => {
        this.optinterviewerDetails.push(ele.id);
      });
      this.interviewForm.controls['panel_list'].value[0].optional_interviewer = this.optinterviewerDetails;
    }
    this.formSubmitted = true;  
    const payload = {
      "candidate": this.interviewForm.controls['candidate'].value,
      "email": this.interviewForm.controls['email'].value,
      "phone": this.interviewForm.controls['phone'].value,
      "job_opening_id": this.interviewForm.controls['job_opening_id'].value,
      "interview_status": this.interviewForm.controls['interview_status'].value,
      "title": this.interviewForm.controls['title'].value,
      "mode": 'Teams',
      "panel_list": this.interviewForm.controls['panel_list'].value
    }

    if (this.isEdit) {
      // this.editInterviewSchedule(payload);
      // if (mode === 'Teams'){
      this.editPayload = payload;
      this.sendNotification();
      // }
      return;
    }
    this.interviewApiService.addInterviewScheduleDetail(payload)
      .pipe(finalize(() => { this.isLoading = false; })).subscribe(res => {
        if (res.status == 'success') {
          this.formSubmitted = false;  
          const dialogRef = this.dialog.open(SuccessAlertComponent, {
            maxWidth: "500px",
            data: res.message
          });
          this.scheduledID = res.interview;
          // if (mode === 'Teams'){
          this.sendNotification();
          // }
          this.dialogRef.close(true);
        } else {
          // this.toast.error(res.message);
          this.dialogRef.close(true);
        }
      }, (err: any) => {
        this.formSubmitted = false;  
        this.toast.error(err.error?.message);
      })

  }

  editInterviewSchedule(payload: any) {
    payload.deleted_item = this.deletedItem.length ? this.deletedItem : [];
    this.interviewApiService.editInterviewSchedule(payload, this.selectedUser?.id).pipe(finalize(() => { this.isLoading = false; })).subscribe(res => {
      if (res.status = 'success') {
        this.toast.success(res.message);
        this.dialogRef.close(true);
        this.formSubmitted = false;
      }
    }, (err: any) => {
      this.toast.error(err.error?.message);
      this.formSubmitted = false;  
    })
  }

  getInterviewDetails(): void {
    this.interviewApiService.getInterviewDetailsById(this?.interviewId).subscribe((res: any) => {
      this.selectedUser = res;
      this.meetingID = res?.notification_id;
      this.scheduledID = res?.id;
      this.interviewForm.patchValue({
        candidate: res.candidate.id,
        job_opening_id: res.candidate.job_openings.job_title,
        email: res.candidate.email,
        phone: res.candidate.phone,
        interview_status: res.interview_status,
        candidate_name: res.candidate.name,
        title: res.title,
        mode: res.mode === null ? 'Teams' : res.mode
      })
      for (let i = 0; i < this.selectedUser.panel_list.length - 1; i++) {
        this.ctrl.push(this.interviewAddbody());
      }

      const jobId = this.selectedUser?.candidate?.job_openings?.id
      this.getInterviewType(jobId);
      this.interviewForm.controls['panel_list'].patchValue(this.selectedUser?.panel_list);

      const convertedDate = this.selectedUser?.panel_list[0]?.date !== null ? this.convertDate(this.selectedUser?.panel_list[0]?.date) : '';
      this.ctrl['controls'][0].patchValue({
        date: convertedDate
      });

      if (this.selectedUser?.panel_list[0]?.optional_interviewer?.length !== 0) {
        this.showOptional = true;
      }

      let panelInterviewer: any[] = [];
      let opt_panelInterviewer: any[] = [];

      this.selectedUser?.panel_list[0]?.interviewer.forEach((xx: any) => {
        panelInterviewer.push(xx.id);
        this.ctrl['controls'][0].patchValue({
          interviewer: panelInterviewer,
        });
      });

      this.selectedUser?.panel_list[0]?.optional_interviewer?.forEach((xx: any) => {
        opt_panelInterviewer.push(xx.id);
        this.ctrl['controls'][0].patchValue({
          optional_interviewer: opt_panelInterviewer,
        })

      });


      this.selectedInterviewer = this.selectedUser?.panel_list[0]?.interviewer;
      this.optionalInterviewer = this.selectedUser?.panel_list[0]?.optional_interviewer || [];
    })
  }

  errorMessage(i: number, controlName: string): boolean {
    let control = (this.ctrl['controls'][i] as FormGroup)['controls'][controlName]
    let labelName = "";
    for (const propertyName in control.errors) {
      if (control.errors.hasOwnProperty(propertyName) && control.touched) {
        return ValidationService.getValidationErrorMessage(propertyName, control.errors[propertyName], labelName);
      }
    }
    return false;
  }

  selectedType(event: any) {
    event.forEach((xx: any) => {
      let addInterviewer: boolean = false;
      this.optionalInterviewer?.forEach((element: any) => {
        if (element?.id === xx.id) {
          addInterviewer = true;
        }
      });
      this.selectedInterviewer = [];
      this.selectedInterviewer = event;
      var index = this.selectedInterviewer?.findIndex(x => x?.id == xx.id);
      if (index == -1) {
        var selectedArray = this.recruiterList.find((element: any) => element.id == Number(xx.id));

        if (!addInterviewer) {
          this.selectedInterviewer?.push({
            'id': selectedArray.id,
            'name': selectedArray.full_name?.name,
            'email': selectedArray.email
          });
          this.interviewerDetails.push(selectedArray.id);
          // this.selectedInterviewer.forEach((ele:any) => {
          //   console.log(ele);
          //   // this.ctrl['controls'][0].get('interviewer')?.patchValue(ele.name);
          // });

        } else {
          this.getControl(0, 'interviewer').reset()
          this.toast.warning('Interviewers can not be same');
        }
      }
    });
  }

  selectedOptional(event: any) {
    this.optionalInterviewer = [];
    this.optionalInterviewer = event;
    event.forEach((xx: any) => {
      let addOptional: boolean = false;
      this.selectedInterviewer?.forEach((element: any) => {
        if (element?.id === xx.id) {
          addOptional = true;
        }
      });

      var index = this.optionalInterviewer?.findIndex(x => x?.id == xx.id);
      if (index == -1) {
        var selectedArray = this.recruiterList.find((element: any) => element.id == Number(xx.id));
        if (!addOptional) {
          this.optionalInterviewer?.push({
            'id': selectedArray.id,
            'name': selectedArray.full_name?.name,
            'email': selectedArray.email
          });
          this.optinterviewerDetails.push(selectedArray.id);
          // this.ctrl['controls'][0].get('optional_interviewer')?.patchValue(this.optionalInterviewer);

        } else {
          this.getControl(0, 'optional_interviewer').reset()
          this.toast.warning('Interviewers can not be same');
        }

      }
    })
  }

  getCandidateDetail(data: any) {
    this.interviewForm.patchValue({
      candidate: data.id,
      job_opening_id: data.job_position,
      email: data.email,
      phone: data.phone,
      candidate_name: data.name
    })
    this.getInterviewType(data.job_position_Id);

  }

  sendNotification() {
    let notification: any = {};
    const data = this.interviewForm.value.panel_list;
    const head = this.interviewForm.value;
    let dateTimeStr_strt = '';
    let dateTimeStr_end = '';
    let attendees: any[] = [];
    let meeting: any = {};
    if (this.microSoftAuthService.token) {
      data.forEach((element: any) => {
        if (element.start_time.length !== 8) {
          dateTimeStr_strt = `${element.date}T${element.start_time}`;
        }
        if (element.end_time.length !== 8) {
          dateTimeStr_end = `${element.date}T${element.end_time}`;
        }


        if (this.isEdit) {
          this.selectedInterviewer?.forEach(ele => {
            if (!ele.email) {
              const ls = this.recruiterList.find((res: any) => res.id === ele.id);
              ele.email = ls.email;
            }
          });
        }

        this.selectedInterviewer?.forEach(element => {
          attendees.push({
            emailAddress: {
              address: element.email,
              name: element.name
            },
            type: "required"
          });
        });

        this.optionalInterviewer?.forEach(element => {
          attendees.push({
            emailAddress: {
              address: element.email,
              name: element.name
            },
            type: "optional"
          });
        });

        notification = {
          "subject": head.title,
          "start": {
            "dateTime": dateTimeStr_strt,
            "timeZone": 'Asia/Kolkata'
          },
          "end": {
            "dateTime": dateTimeStr_end,
            "timeZone": 'Asia/Kolkata'
          },
          "isOnlineMeeting": true,
          "attendees": attendees
        };


      });
      if (!this.isEdit) {
        this.interviewApiService.getInterviewScheduleNotify(notification).subscribe((response: any) => {
          this.toast.success('Notification Sent')
          this.microSoftAuthService.meeting_id = response.id;
          this.getMeetingDetails(response);
        });
      }
      else {
        if(this.selectedUser?.panel_list[0]?.machine_test_output_url === '1' && this.meetingID === null){
          this.interviewApiService.getInterviewScheduleNotify(notification).subscribe((response: any) => {
            this.toast.success('Notification Sent')
            this.microSoftAuthService.meeting_id = response.id;
            this.getMeetingDetails(response);
          });
        } else {
          const id = this.meetingID;
          if (id !== null) {
            this.interviewApiService.editInterviewScheduleNotify(notification, id).subscribe((response: any) => {
              this.toast.success('Updated Notification Sent');
              this.microSoftAuthService.meeting_id = response.id;
              this.editInterviewSchedule(this.editPayload);
              this.getMeetingDetails(response);
            }, (err: any) => {
              this.dialogRef.close(true);
              this.toast.warning('Cannot update this interview, Since you are not the organizer');
            });
          } else {
            this.dialogRef.close(true);
            this.toast.warning('Cannot update this interview, Since meeting is not found');
          }
        }
      }
    }

  }


  getMeetingDetails(result: any) {
    // this.interviewApiService.getInterviewScheduleMeeting(details).subscribe((response: any) => {
    //   this.microSoftAuthService.meeting_url = response?.joinUrl;
    //   console.log(response);
    if (!this.isEdit) {
      this.getMeetingId(result?.id, result?.onlineMeeting?.joinUrl, 'create');
    } else {
      setTimeout(() => {
        this.getMeetingId(result?.id, result?.onlineMeeting?.joinUrl, 'update')
      }, 200);
    }
    // });
  }

  getMeetingId(id: any, url: any, type: any) {
    const data = {
      notification_id: id,
      meeting_url: url,
      action: type
    }
    this.interviewApiService.storeMeetingID(data, this.scheduledID).subscribe((response: any) => {
    })
  }


  setendTime() {
    const val1 = this.ctrl.value[0].start_time;
    const val2 = this.ctrl.value[0].end_time;
    const start = val1.slice(0, 2);
    const end = val2.slice(0, 2);

    if (this.ctrl.value[0].start_time === this.ctrl.value[0].end_time) {
      this.toast.warning('Start date and end date should not be same');
      this.ctrl['controls'][0].get('end_time')?.reset();
    }

    if (this.ctrl.value[0].end_time !== '') {
      if (start > end) {
        this.toast.warning('Please select end time correctly');
        this.ctrl['controls'][0].get('end_time')?.reset();
      }
    }

    if (this.ctrl.value[0].start_time === '') {
      if (start > end) {
        this.toast.warning('Please select start time correctly');
        this.ctrl['controls'][0].get('start_time')?.reset();
      }
    }
  }

  showOptionalInput() {
    this.showOptional = true
  }

  convertDate(originalDate: string) {
    const [year, month, day] = originalDate?.split('-');
    const formattedDate = new Date(`${day}-${month}-${year}`);
    const result = formattedDate.toISOString().split('T')[0];
    return result;
  }

}

