import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { finalize, take } from 'rxjs';
import { DemandApiService } from 'src/app/modules/user/services/demand-api.service';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-demand-add',
  templateUrl: './demand-add.component.html',
  styleUrls: ['./demand-add.component.scss']
})
export class DemandAddComponent {

  demandForm!: FormGroup;
  isLoading = false;
  isEdit: boolean = false;
  jobTitleList: any[] = [];
  departmentList: any[] = [];
  countryList : any[] = [];
  skillList: any[] = [];
  selectedSkills: any[] = [];
  errorShow : boolean =false;
  selectedJob:any;
  selectedDept:any;
  selectedCountry:any;
  onSiteArray = [{id:true, name:'Yes'}, {id:false, name:'No'}]
  demandDetails: boolean = true;
  public formSubmitted: boolean = false;
  constructor(
    private fb: FormBuilder,
    private commonService : CommonService,
    private demandApiService : DemandApiService,
    private toast: ToastrService,
    public dialogRef: MatDialogRef<DemandAddComponent>, @Inject(MAT_DIALOG_DATA) public demandId: any
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.getJobPosition();
    this.getDepartmentList();
    this.getCountryList();
    this.getSkillList();
    if (this.demandId) {
      this.isEdit = true;
      this.getDemandDetails();
    }
  }

  initForm(): void {
    this.demandForm = this.fb.group({
      id: new FormControl(''),
      job_position: new FormControl(null,Validators.required),
      start_date: new FormControl('', Validators.required),
      location: new FormControl(null, Validators.required),
      department: new FormControl(null, Validators.required),
      project_name: new FormControl('', Validators.required),
      years_of_exp: new FormControl('', Validators.required),
      sub_skills: new FormControl(''),
      skills: new FormControl(null, Validators.required),
      job_description: new FormControl(null),
      client_name: new FormControl('', Validators.required),
      no_of_resources: new FormControl('', Validators.required),
      comment: new FormControl(''),
      onSite: new FormControl(null,Validators.required)
    })
  }

  getJobPosition(): void {
    this.commonService.getJobPosition().subscribe((result) => {
      this.jobTitleList = result;
    })
  }

  getDepartmentList(): void {
    this.commonService.getDepartmentList().subscribe((result) => {
      this.departmentList = result;
    })
  }

  getCountryList(): void {
    this.commonService.getCountryList().subscribe((result) => {
      this.countryList = result;
    })
  }
  getSkillList(): void {
    this.commonService.getSkillsList().subscribe((result) => {
      this.skillList = result;
    })
  }

  selectedSkillType(event: any) {
    var index = this.selectedSkills.findIndex(x => x?.id == event.id);
    if (index == -1) {
      var selectedArray = this.skillList.find(x => x.id == Number(event.id));
      this.selectedSkills.push(selectedArray);
      this.demandForm.controls['skills'].patchValue(this.selectedSkills)
    }
  }

  remove(data: any, type: any): void {
    const item = this.demandForm.value;
    if (type == "skills") {
      this.selectedSkills.forEach((element: any, index: any) => {
        if (data.id == element.id) {
          var index = index;
          if (index !== -1) {
            this.selectedSkills.splice(index, 1);
            if (data.id == item.skills) {
              this.demandForm.controls['skills'].reset();
            }
          }
        }
      })
    }
  }


  getJobSelected(env: any) {
    this.demandForm.controls['job_position'].setValue(env.id);
    this.selectedJob = env;
  }

  getDepartmentSelected(env: any) {
    this.demandForm.controls['department'].setValue(env.id);
    this.selectedDept = env;
  }

  getCountrySelected(env: any) {
    this.demandForm.controls['location'].setValue(env.id);
    this.selectedCountry = env;
  }


  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'e' || event.key === '.' || event.key === '-'|| event.key === '+') {
      event.preventDefault();
    }
  }

  nextBtn(){
    const data = this.demandForm.value;
    this.demandForm.markAllAsTouched();
    if(this.demandForm.valid) {
      this.demandDetails = false;
    }
  }

  back(){
    this.demandDetails= !this.demandDetails;
    this.demandForm.controls["job_description"].clearValidators();

  }

  getDemandDetails() {
    this.demandApiService.getDemandViewDetails(this.demandId).subscribe((res: any) => {
      this.selectedJob = res[0];
      this.selectedCountry = res[0];
      this.selectedDept = res[0];
      this.demandForm.patchValue({
        id: res[0]?.id,
        start_date: res[0]?.start_date,
        job_description: res[0]?.job_description,
        years_of_exp: res[0]?.years_of_exp,
        project_name: res[0]?.project_name,
        sub_skills: res[0]?.sub_skills,
        client_name: res[0]?.client_name,
        no_of_resources: res[0]?.no_of_resources,
        comment: res[0]?.comment,
        onSite: res[0].onSite
      })
      this.demandForm.controls['job_position'].setValue(res[0]?.job_position?.id);
      this.demandForm.controls['department'].setValue(res[0]?.department?.id);
      this.demandForm.controls['location'].setValue(res[0]?.location?.id);

      this.selectedSkills = res[0]?.skills ? res[0]?.skills : [];
      const skill_length = this.selectedSkills?.length;
      this.demandForm.controls['skills'].setValue(this.selectedSkills[skill_length-1].id);
    })
  }

  setValidation(){
    this.demandForm.controls["job_description"].setValidators(Validators.required);
  }

  addDemand(): void{
    if (!this.isEdit) {
      this.setValidation();
      this.errorShow = true;
      this.demandForm.markAllAsTouched();
      this.demandForm.controls['job_description'].updateValueAndValidity();
      if (this.demandForm.valid && this.selectedSkills.length >0) {
        this.formSubmitted = true;  
        this.isLoading = true;
        let formData = new FormData();
        var skillsArray = [];
        skillsArray = this.selectedSkills.map(x => x.id);
        const data = {
          ...this.demandForm.value,
          skills: skillsArray
        }
        formData.append('data', JSON.stringify(data));
        this.demandApiService.addDemandDetails(formData)
          .pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
          .subscribe(res => {
            if (res.status = 'success') {
              this.formSubmitted = false;  
              this.toast.success(res.message);
              this.dialogRef.close(true);
            }
          }, (err:any)=>{
            this.toast.error(err?.error?.message);
            this.formSubmitted = false;
          });
      }
    } else {
      this.editDemand();
    }
  }


  editDemand() {
    this.demandForm.markAllAsTouched()
    if (this.demandForm.valid) {
      this.formSubmitted = true;
      this.isLoading = true;
      let formData = new FormData();
      var skillsArray = [];
      skillsArray = this.selectedSkills.map(x => x.id);
      const data = {
        ...this.demandForm.value,
        skills: skillsArray
      }
      formData.append('data', JSON.stringify(data));
      this.demandApiService.editDemandDetails(formData,this.demandForm?.value?.id).pipe(finalize(() => {this.isLoading = false;})).subscribe(res => {
          if (res.status = 'success') {
            this.toast.success(res.message);
            this.dialogRef.close(true);
            this.formSubmitted = false;
          }
        }, (err:any)=>{
          this.toast.error(err?.error?.message);
          this.formSubmitted = false;
        })

    }
  }

  preventTab(event: KeyboardEvent): void {
    if (event.key === 'Tab'|| event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      event.preventDefault();
    }
  }

}
