<div class="bnx-modal">
  <div class="bnx-modal__close-btn" [mat-dialog-close]="false">
    <svg width="15" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times"
      class="svg-inline--fa fa-times fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
      <path fill="currentColor"
        d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z">
      </path>
    </svg>
  </div>

  <div class="bnx-modal__title">

    <div class="back-button" *ngIf="!demandDetails" (click)="back()">
      <svg _ngcontent-nlj-c136="" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 28 28" fill="none"
       matTooltip="Back" class="mat-mdc-tooltip-trigger" ng-reflect-message="Back" aria-describedby="cdk-describedby-message-nlj-1-4" cdk-describedby-host="nlj-1">
        <path _ngcontent-nlj-c136="" d="M11.667 9.83334L16.3337 14.5L11.667 19.1667" stroke="#0094FF" stroke-width="1.5" stroke-linecap="round"></path>
      </svg>
    </div>
    <h2 class="bnx-modal__heading">{{isEdit ? 'Edit Demand':'Add Demand'}}</h2>


  </div>


  <div>
    <div class="bnx-view-modal__body">

      <div class="bnx-modal__wrapper">

        <form class="bnx-form" [formGroup]="demandForm">

          <div class="bnx-form__row bnx-scroll-box" *ngIf="demandDetails">
            <div class="bnx-form__col required">
              <div class="bnx-form__label">Job Position <span class="bnx-form__aster-risk">*</span></div>

              <ng-select class="recNgclass" placeholder="Select Job Position" (change)="getJobSelected($event)"
                [items]="jobTitleList" bindLabel="name" bindValue="id" formControlName="job_position"></ng-select>
              <error-msg [control]="demandForm.controls['job_position']"></error-msg>
            </div>


            <div class="bnx-form__col">
              <div class="bnx-currency-item">
                <input class="bnx-form__input" formControlName="start_date" placeholder="name" type="date"(keydown)="preventTab($event)" />
                <div class="bnx-form__label">Start Date <span class="bnx-form__aster-risk">*</span></div>
                <error-msg [control]="demandForm.controls['start_date']"></error-msg>
              </div>

            </div>

            <div class="bnx-form__col required">
              <div class="bnx-form__label">Department <span class="bnx-form__aster-risk">*</span></div>
              <ng-select class="recNgclass" placeholder="Select Department" (change)="getDepartmentSelected($event)"
                [items]="departmentList" bindLabel="name" bindValue="id" formControlName="department"></ng-select>
              <error-msg [control]="demandForm.controls['department']"></error-msg>
            </div>


            <div class="bnx-form__col required">
              <div class="bnx-form__label">Location <span class="bnx-form__aster-risk">*</span></div>
              <ng-select class="recNgclass" placeholder="Select Location" (change)="getCountrySelected($event)"
                [items]="countryList" bindLabel="name" bindValue="id" formControlName="location"></ng-select>
              <error-msg [control]="demandForm.controls['location']"></error-msg>
            </div>



            <div class="bnx-form__col">
              <div class="bnx-currency-item">
                <input type="text" class="bnx-form__input" placeholder="name" formControlName="client_name">
                <div class="bnx-form__label">Client Name <span class="bnx-form__aster-risk">*</span></div>
                <error-msg [control]="demandForm.controls['client_name']"></error-msg>
              </div>

            </div>


            <div class="bnx-form__col">
              <div class="bnx-currency-item">
                <input type="text" class="bnx-form__input" placeholder="name" formControlName="project_name">
                <div class="bnx-form__label">Project Name <span class="bnx-form__aster-risk">*</span></div>
                <error-msg [control]="demandForm.controls['project_name']"></error-msg>
              </div>

            </div>

            <div class="bnx-form__col">
              <div class="bnx-currency-item">
                <input type="text" class="bnx-form__input" placeholder="name" formControlName="years_of_exp">
                <div class="bnx-form__label">Years of Exp <span class="bnx-form__aster-risk">*</span></div>
                <error-msg [control]="demandForm.controls['years_of_exp']"></error-msg>
              </div>
            </div>

            <div class="bnx-form__col">
              <div class="bnx-currency-item">
                <input type="number" class="bnx-form__input" placeholder="name" formControlName="no_of_resources"
                  (keydown)="onKeyDown($event)">
                <div class="bnx-form__label">No of Resources <span class="bnx-form__aster-risk">*</span></div>
                <error-msg [control]="demandForm.controls['no_of_resources']"></error-msg>
              </div>

            </div>

            <div class="bnx-form__col required">
              <div class="bnx-form__label">On Site <span class="bnx-form__aster-risk">*</span></div>
              <ng-select class="recNgclass" placeholder="Onsite" [items]="onSiteArray" bindLabel="name" bindValue="id"
                formControlName="onSite"></ng-select>
              <error-msg [control]="demandForm.controls['onSite']"></error-msg>
            </div>


            <div class="bnx-form__col">
              <div class="bnx-currency-item">
                <input type="text" class="bnx-form__input" placeholder="name" formControlName="sub_skills">
                <div class="bnx-form__label">Sub Skills</div>
              </div>
            </div>


            <div class="bnx-form__col required">
              <div class="bnx-form__label">Skills <span class="bnx-form__aster-risk">*</span></div>
              <ng-select class="recNgclass" placeholder="Select Skills" (change)="selectedSkillType($event)"
                [items]="skillList" bindLabel="name" bindValue="id" formControlName="skills"></ng-select>


                <error-msg [control]="demandForm.controls['skills']"></error-msg>

            </div>


            <div class="select-filters">
              <span class="filter-margin" *ngIf="selectedSkills.length >0">
                <mat-chip class="assigned-chip" *ngFor="let skills of selectedSkills; let i = index" [removable]="true"
                  (removed)="remove(skills,'skills')">
                  {{skills?.name}}
                  <mat-icon matChipRemove>clear</mat-icon>
                </mat-chip>
              </span>

            </div>


          </div>

          <div class="submit" *ngIf="demandDetails">
            <button class="bnx-btn" (click)="nextBtn()">Next</button>
          </div>




          <div class="bnx-form__row bnx-scroll-box" *ngIf="!demandDetails">

            <div class="bnx-form__col">
              <div class="bnx-currency-item">
                <textarea class="bnx-form__input comment" placeholder="name" formControlName="job_description" style="resize: none;" name="" id="" cols="20"
                rows="8"></textarea>
                <div class="bnx-form__label">Job Description</div>
                </div>
                <error-msg [control]="demandForm.controls['job_description']"></error-msg>
            </div>

            <div class="bnx-form__col">
              <div class="bnx-currency-item">
                <textarea class="bnx-form__input comment" placeholder="name" formControlName="comment"  style="resize: none;" name="" id="" cols="20"
                rows="8"></textarea>
                <div class="bnx-form__label">Comment</div>
              </div>

            </div>

            <div class="submit">
              <button class="bnx-btn" (click)="addDemand()" [disabled]="formSubmitted ? true : false">{{isLoading ? isEdit ? 'Updating ...':'Adding...':isEdit ?
                'Update Demand':'Submit'}}</button>
              <button class="bnx-btn bnx-btn-border" [mat-dialog-close]="false">Cancel</button>
            </div>


          </div>

        </form>
      </div>

    </div>
  </div>













  <!-- <form [formGroup]="demandForm">
    <div class="bnx-view-modal__body modal_scroll bnx-scroll-box">
      <div class="bnx-modal__wrapper">
         <div class="bnx-form__row">
          <div class="bnx-form__col">
            <div class="bnx-form__group">
              <div class="bnx-form__label">Job Position</div>

              <ng-select class="recNgclass" placeholder="Select Job Position" (change)="getJobSelected($event)"
              [items]="jobTitleList" bindLabel="name" bindValue="id" formControlName="job_position" ></ng-select>

              <error-msg [control]="demandForm.controls['job_position']"></error-msg>
            </div>
          </div>
          <div class="bnx-form__col">
            <div class="bnx-form__group">
              <input class="bnx-form__input" formControlName="start_date" placeholder="name" type="date" />
              <div class="bnx-form__label">Start Date</div>
            </div>
            <error-msg [control]="demandForm.controls['start_date']"></error-msg>
          </div>

          <div class="bnx-form__col">

            <div class="bnx-form__label">Department </div>

            <ng-select class="recNgclass" placeholder="Select Department" (change)="getDepartmentSelected($event)"
              [items]="departmentList" bindLabel="name" bindValue="id" formControlName="department" ></ng-select>
            <error-msg [control]="demandForm.controls['department']"></error-msg>
          </div>

          <div class="bnx-form__col">
            <div class="bnx-form__label">Location</div>
            <ng-select class="recNgclass" placeholder="Select Location" (change)="getCountrySelected($event)"
            [items]="countryList" bindLabel="name" bindValue="id" formControlName="location" ></ng-select>
            <error-msg [control]="demandForm.controls['location']"></error-msg>
          </div>

          <div class="bnx-form__col">
            <div class="bnx-currency-item">
              <input type="text" class="bnx-form__input" placeholder="name" formControlName="client_name">
              <div class="bnx-form__label">Client Name</div>
            </div>
            <error-msg [control]="demandForm.controls['client_name']"></error-msg>
          </div>

          <div class="bnx-form__col">
            <div class="bnx-currency-item">
              <input type="text" class="bnx-form__input" placeholder="name" formControlName="project_name">
              <div class="bnx-form__label">Project Name</div>
            </div>
            <error-msg [control]="demandForm.controls['project_name']"></error-msg>
          </div>

          <div class="bnx-form__col">
            <div class="bnx-currency-item">
              <input type="text" class="bnx-form__input" placeholder="name" formControlName="years_of_exp"
              >
              <div class="bnx-form__label">Years of Exp</div>
            </div>
            <error-msg [control]="demandForm.controls['years_of_exp']"></error-msg>
          </div>

          <div class="bnx-form__col">
            <div class="bnx-currency-item">
              <input type="number" class="bnx-form__input" placeholder="name" formControlName="no_of_resources"
              (keydown)="onKeyDown($event)">
              <div class="bnx-form__label">No of Resources</div>
            </div>
            <error-msg [control]="demandForm.controls['no_of_resources']"></error-msg>
          </div>

          <div class="bnx-form__col">
            <div class="bnx-currency-item">
              <ng-select class="recNgclass" placeholder="Onsite" [items]="onSiteArray" bindLabel="name" bindValue="id" formControlName="onSite"></ng-select>
            </div>
            <error-msg [control]="demandForm.controls['onSite']"></error-msg>
          </div>

          <div class="bnx-form__col">
            <div class="bnx-currency-item">
              <input type="text" class="bnx-form__input" placeholder="name" formControlName="sub_skills">
              <div class="bnx-form__label">Sub Skills</div>
            </div>
          </div>


          <div class="bnx-form__col">
            <div class="bnx-form__label">Skills</div>
            <ng-select class="recNgclass" placeholder="Select Skills" (change)="selectedSkillType($event)"
            [items]="skillList" bindLabel="name" bindValue="id" formControlName="skills" ></ng-select>

            <small *ngIf="selectedSkills.length  == 0 && errorShow" class="error_msg">
              Data is required
            </small>

          </div>

        </div>

        <div class="select-filters">
          <span class="filter-margin" *ngIf="selectedSkills.length >0">
            <mat-chip class="assigned-chip" *ngFor="let skills of selectedSkills; let i = index" [removable]="true"
              (removed)="remove(skills,'skills')">
              {{skills?.name}}
              <mat-icon matChipRemove>clear</mat-icon>
            </mat-chip>
          </span>

        </div>

        <div class="bnx-form__col__comment">
          <div class="bnx-currency-item">
            <textarea class="bnx-form__input comment"  placeholder="name" formControlName="job_description" style="resize: none;" name="" id="" cols="20" rows="6"
            ></textarea>
            <div class="bnx-form__label">Job Description</div>
          </div>
          <error-msg [control]="demandForm.controls['job_description']"></error-msg>
        </div>

        <div class="bnx-form__col__comment">
          <div class="bnx-currency-item">
            <textarea class="bnx-form__input"  placeholder="name" formControlName="comment" style="resize: none;" cols="20" rows="6"></textarea>
            <div class="bnx-form__label">Comment</div>
          </div>
        </div>



        <div class="submit">
          <button class="bnx-btn" (click)="addDemand()">{{isLoading ? isEdit ? 'Updating ...':'Adding...':isEdit ? 'Update Demand':'Submit'}}</button>
          <button class="bnx-btn bnx-btn-border" [mat-dialog-close]="false">Cancel</button>
        </div>

      </div>
    </div>
  </form> -->

</div>
