import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { catchError, finalize, of } from 'rxjs';
import { DemandApiService } from '../../services/demand-api.service';

@Component({
  selector: 'app-approved-reject',
  templateUrl: './approved-reject.component.html',
  styleUrls: ['./approved-reject.component.scss']
})
export class ApprovedRejectComponent {
  isLoading: boolean = false;
  showContent: boolean = false;
  paramData: any;
  isUpdatedAlready : boolean | undefined;
  isDeletedAlready : boolean | undefined;

  constructor(private activatedRoute: ActivatedRoute, private demandService: DemandApiService, private toast: ToastrService) { }


  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      let data: string = params['data'];
      this.paramData = this.strToObj(data);
      this.updateStatus();
    });
  }

  strToObj(str: string) {
    var obj = {};
    if (str && typeof str === 'string') {
      var objStr = str.match(/\{(.)+\}/g);
      eval("obj =" + objStr);
    }
    return obj
  }

  updateStatus(): void {
    const data = {
      "status": this.paramData?.status
    }
    this.demandService.updateEmailStatus(data, this.paramData?.id).pipe(
      catchError((err)=>{
        if(err.error?.detail == 'Not found.') {
          this.isDeletedAlready = true;
          this.showContent = true;
        }
        if(err.error?.status == "failed"){
          this.isUpdatedAlready = true;
          this.showContent = true;
          this.isDeletedAlready = false;
        }
        return of(false)})
    ).subscribe(res => {
      if (res.status = 'success') {
        this.toast.success(res.message);
        this.showContent = true;
        this.isUpdatedAlready = false;
        this.isDeletedAlready = false;
      }
    })
  }

}
