<div class="bnx-filter">
  <app-filter [showExcelDownload]="true"  [status]="false" [componentName]="'dashboardJoinees'" (download)="downloadExcel($event)"  [department]="false" [projects]="true" (filterChange)="getFilteredChange($event)"></app-filter>
</div>

<div class="bnx-table-tab">
  <div class="bnx-table-wrapper__card">
    <div class="bnx-table-wrapper__card-body">
      <div class="bnx-table-wrapper__table-card">
        <app-table-view [setDefault]="setDefaultPage" [tableEditDelele]="tableEditDelele" [data]="upcommingJobList" [totalPages]="totalPages"  [tableKeys]="tableKey" [isLoading]="isLoading" [tableOption]="tableOption" [tableTitle]="tableTitle" (event)="getChange($event)"></app-table-view>
      </div>
    </div>
  </div>
</div>
