<div class="bnx-filter">
    <app-filter  [showExcelDownload]="true"  [status]="true"  (download)="downloadExcel($event)" [department]="true" [componentName]="'upcomming-interviews-panel'"   (filterChange)="getFilteredChange($event)"></app-filter>
  </div>

  <div class="bnx-table-wrapper__card">
    <div class="bnx-table-wrapper__card-body">
      <div class="bnx-table-wrapper__table-card">
        <app-table-view [data]="candidateList" [tableKeys]="tableKey" (event)="getChange($event)" [setDefault]="setDefaultPage"
          [tableTitle]="tableTitle" [tableEditDelele]="tableEditDelele" [tableOption]="tableOption" [totalPages]="totalPages"></app-table-view>
      </div>
    </div>
  </div>

