import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { LoginRoutingModule } from './login.routing.module';
import { SubLoginComponent } from './pages/sub-login/sub-login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CandidateLoginPageComponent } from './pages/candidate-login-page/candidate-login-page.component';
import { SharedModule } from "../../shared/shared.module";

@NgModule({
    declarations: [
        LoginPageComponent,
        SubLoginComponent,
        CandidateLoginPageComponent
    ],
    imports: [
        CommonModule,
        LoginRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule
    ]
})
export class LoginModule { }
