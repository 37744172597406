<div class="bnx-header" [ngClass]="{'active':loggedUserType === 'CANDIDATE'}">
  <div class="bnx-header__container" [ngClass]="{'no-border':loggedUserType === 'CANDIDATE'}">
    <div class="bnx-header__navbar-header">
      <div class="bnx-header__right-section">
        <div class="bnx-common__head" *ngIf="loggedUserType !== 'CANDIDATE'">
          <span>Welcome to</span> Beinex Recruitment Portal!
        </div>
        <img src="/assets/images/logo-colored.svg" class="bnx-header__logo" alt="" *ngIf="loggedUserType === 'CANDIDATE'">
      </div>

      <div class="bnx-header__left-section">
        <div class="bnx-header__head-menus">
          <!-- <div class="border-right">
                <button class="bnx-btn"> <span><svg width="12" class="icon-svg" height="12" viewBox="0 0 12 12"  xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 6H11M6 11V1"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  </span> Add Entry</button>
              </div>

              <div class="bnx-header__left-btn">
                <button>
                  <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.5 7.5135V11.3985M14.5242 2.3335C10.0775 2.3335 6.47667 5.81016 6.47667 10.1035V12.5535C6.47667 13.3468 6.13833 14.5368 5.71542 15.2135L4.18083 17.6868C3.23833 19.2152 3.89083 20.9185 5.63083 21.4785C11.4082 23.3335 17.6522 23.3335 23.4296 21.4785C23.8108 21.3557 24.1586 21.1518 24.4472 20.8818C24.7359 20.6118 24.958 20.2827 25.0972 19.9187C25.2364 19.5547 25.2891 19.1652 25.2514 18.7789C25.2138 18.3925 25.0867 18.0194 24.8796 17.6868L23.345 15.2135C22.9221 14.5368 22.5838 13.3352 22.5838 12.5535V10.1035C22.5717 5.8335 18.9467 2.3335 14.5242 2.3335Z" stroke="#8196AC" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                    <path d="M18.5236 21.9565C18.5236 24.0915 16.7111 25.8415 14.4998 25.8415C13.4002 25.8415 12.3852 25.3982 11.6602 24.6982C10.9352 23.9982 10.4761 23.0182 10.4761 21.9565" stroke="#8196AC" stroke-width="1.5" stroke-miterlimit="10"/>
                  </svg>
                </button>
              </div>-->
          <div class="bnx-header__left-btn">
            <!-- <div class="bnx-header__date"  [matMenuTriggerFor]="dateMenu"> <span>{{currentMonth}} {{currentYear}} </span>
               <span class="bnx-header__notify-icon" ><em class="bnx-icon bnx-icon--calendar"></em></span>
            </div>
            <mat-menu #dateMenu="matMenu">
              <button mat-menu-item >{{currentYear}}</button>
              <button mat-menu-item >{{previousYear}}</button>
            </mat-menu> -->


            <button
              [matMenuTriggerFor]="notification"
              (menuOpened)="notificationOpenEvent()"
              (menuClosed)="notificationCloseEvent()"
              class="bnx-header__notify-icon" title="Notification">

              <span class="dot" *ngIf="newNotification"></span>
              <em class="bnx-icon bnx-icon--bell"></em>
              <svg
                class="indicator"
                *ngIf="notifCount !== 0"
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <circle cx="6" cy="6" r="6" fill="#0094FF" />
              </svg>
            </button>

            <div class="bnx-header__notify-icon logout" *ngIf="loggedUserType === 'CANDIDATE'" title="Logout">
              <a class="link_name" (click)="logout()">
                <em class="bnx-icon bnx-icon--exit"></em>
              </a>
            </div>

          </div>
          <!-- <div class="bnx-header__left-btn">
            <button mat-button [matMenuTriggerFor]="menu">
              <div class="action-logout">
                <svg
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.075 14.6875C13.9754 14.675 13.8746 14.675 13.775 14.6875C12.7149 14.6518 11.7102 14.2055 10.9732 13.4428C10.2361 12.68 9.8244 11.6607 9.825 10.6C9.825 8.33751 11.65 6.50001 13.925 6.50001C14.9993 6.49814 16.0313 6.91852 16.7985 7.67051C17.5658 8.4225 18.0067 9.44587 18.0264 10.52C18.0461 11.5941 17.6429 12.6329 16.9037 13.4125C16.1646 14.1921 15.1486 14.65 14.075 14.6875ZM22.35 22.9375C20.0528 25.0488 17.045 26.2181 13.925 26.2125C10.675 26.2125 7.725 24.975 5.5 22.9375C5.625 21.7625 6.375 20.6125 7.7125 19.7125C11.1375 17.4375 16.7375 17.4375 20.1375 19.7125C21.475 20.6125 22.225 21.7625 22.35 22.9375Z"
                    stroke="#8196AC"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13.5 26C20.4037 26 26 20.4037 26 13.5C26 6.59625 20.4037 1 13.5 1C6.59625 1 1 6.59625 1 13.5C1 20.4037 6.59625 26 13.5 26Z"
                    stroke="#8196AC"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span class="logged-name">{{ loggedUserName }}</span>

                <svg
                  width="14"
                  height="9"
                  viewBox="0 0 14 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L7 7L13 1"
                    stroke="#8196AC"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
            </button>
          </div> -->
        </div>
      </div>
    </div>
  </div>

  <mat-menu #menu="matMenu">
    <button backdropClass="custom-menu" mat-menu-item (click)="logout()">
      Log Out
    </button>
  </mat-menu>

  <mat-menu
    #notification="matMenu"
    class="bnx-notifications-popup bnx-notifications"
    xPosition="after"
  >
    <app-notification></app-notification>
  </mat-menu>
</div>
