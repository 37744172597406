import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiEndPoints } from 'src/app/core/constants/constants';
import { HttpService } from 'src/app/core/services/common/HttpService';
@Injectable({
  providedIn: 'root'
})

export class OfferStatusApiService {
    addclient(data:any): Observable<any> {
        return this.httpService.post(ApiEndPoints.offerstatusAdd,data);
    }

    constructor(private httpService : HttpService) { }

    getofferList(filterArray: any,offset:any,limit:any): Observable<any> {
        return this.httpService.get(`${ApiEndPoints.OfferstatusList}?filter=${JSON.stringify(filterArray)}&offset=${offset}&limit=${limit}`);
    }

    deleteofferDetails(id:string){
        return this.httpService.delete(`${ApiEndPoints.offerstatusDelete}/${id}`);
    }

    editofferDetails(data:any,id:string): Observable<any> {
        return this.httpService.put(`${ApiEndPoints.OfferstatusEdit}/${id}`,data);
    }

    getofferDetailsById(id:string): Observable<any>{
        return this.httpService.get(`${ApiEndPoints.offerstatusDetail}/${id}`);
    }


}