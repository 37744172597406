import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-user-manage-modal',
  templateUrl: './user-manage-modal.component.html',
  styleUrls: ['./user-manage-modal.component.scss']
})
export class UserManageModalComponent {
  userManageform!: FormGroup;
  isLoading = false;
  isEdit = false;
  currencyList = [
    'SAR', 'AED',  'USD', 'EU','QAR','INR'
  ];
  addClient(){

  }
}
