import { Component, OnInit } from '@angular/core';
import { AdminApiService } from '../../services/admin-api.service';
import { FunctionHelperService } from 'src/app/core/helper/function.helper.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  constructor(private adminApiService: AdminApiService,
    private helper: FunctionHelperService) { }
  interviews!: number;
  upcomingInterviews!: number;
  yearList = ['2023', '2022'];
  currentYear = new Date().getFullYear();
  filterArray: any = {
    "year": [this.currentYear],
  }

  ngOnInit(): void {
    this.getInterviewCount(this.filterArray);
  }

  getInterviewCount(filterArray: any) {
    const token = this.helper.userData.key;
    let year = [];
    this.filterArray = filterArray;
    if (filterArray == '') {
      filterArray = {
        "year": []
      }
    }
    console.log(filterArray);

    this.adminApiService.getInterviewCount(filterArray,token).subscribe((res: any) => {
       this.interviews=res.interview_count;
       this.upcomingInterviews=res.upcoming_interview_count;
    })
  }

  changeYear(env:any){
    if (env === undefined){
      const year = new Date().getFullYear();
      this.filterArray = {
        "year": [year]
      }
    } else {
      this.filterArray = {
        "year": [env]
      }
    }
    this.getInterviewCount(this.filterArray);
  }
}
