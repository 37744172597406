import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.dev';


@Injectable({
  providedIn: 'root',
})
export class HttpService {

  apiUrl = environment.baseUrl + environment.apiVersion;

  constructor(private http: HttpClient) {
    console.log(this.apiUrl);
  }
  ngOnInit(): void {
    console.log(this.apiUrl);

  }

  post(url: string, data = {}): Observable<any | any[]> {
    return this.http.post(this.apiUrl + url, data);
  }
  get(url: string): Observable<any | any[]> {
    return this.http.get (this.apiUrl + url, );
  }
  patch(url: string, data = {}): Observable<any | any[]> {
    return this.http.patch(this.apiUrl + url, data);
  }
  put(url: string, data = {}): Observable<any | any[]> {
    return this.http.put(this.apiUrl + url, data);
  }

  delete(url: string): Observable<any | any[]> {
    return this.http.delete (this.apiUrl + url, );
  }

  deleteInterview(url: string,data = {}): Observable<any | any[]> {
    return this.http.delete (this.apiUrl + url, data);
  }

  download(url: string): Observable<any | any[]> {
    return this.http.get (url,{
      responseType: 'blob',
      reportProgress: true
    });
  }
}
