import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import { ValidationService } from 'src/app/core/services/common/validation.service';
import { JobApiService } from 'src/app/modules/user/services/job-api.service';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-jobadd-details',
  templateUrl: './job-add.component.html',
  styleUrls: ['./job-add.component.scss']
})
export class JobAddComponent {
  jobForm!: FormGroup;
  isLoading = false;
  isEdit: boolean = false;
  currencyList: any[] = [];
  departmentList: any[] = [];
  jobTitleList: any[] = [];
  jobStatusList: any[] = [];
  recruiterList: any[] = [];
  countryList: any[] = [];
  skillList: any[] = [];
  selectedSkills: any[] = [];
  selectedRecruiter:any[] = [];
  jobDetaildata!: any;
  selectedJobStatus: any;
  statusProgress: any;
  public formSubmitted: boolean = false;
  constructor(
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private jobApiService: JobApiService,
    private toast: ToastrService,private router: Router,
    public dialogRef: MatDialogRef<JobAddComponent>, @Inject(MAT_DIALOG_DATA) public jobDetails: any,
  ) { }
  ngOnInit(): void {
    this.initForm();
    this.getJobOpeningStatusList();
    this.getRecruiterList('');
    this.getCountryList();
    this.getSkillList();
    if (this.jobDetails.demandId) {
      this.getJobDetails();
      this.isEdit = true;
    }

    if (this.jobDetails?.id) {
      if(this.router.url.includes('admin/index')) {
        this.getJobDetails();
      this.isEdit = true;
      }
    }

  }

  initForm(): void {
    this.jobForm = this.formBuilder.group({
      id: new FormControl(''),
      assigned_recruiter:new FormControl(null, Validators.required),
      target_date: new FormControl('', Validators.required),
      job_opening_status: new FormControl('', Validators.required),
      careerPage: new FormControl('',[
          Validators.required,
          ValidationService.urlValidator
        ]),
      demandId: new FormControl('')
    })
  }


  getJobOpeningStatusList(): void {
    this.commonService.getJobStatus().subscribe((result) => {
      this.jobStatusList = result;
      this.getStatusToProgress();
    })
  }
  getRecruiterList(filterArray: any): void {
    filterArray = {
      'license_type': [1],
      'is_active': [],
      'user_status': [1],
      'q': []
    }
    this.commonService.getJRecruiterList(filterArray).subscribe((result) => {
      this.recruiterList = result;
    })
  }
  getCountryList(): void {
    this.commonService.getCountryList().subscribe((result) => {
      this.countryList = result;
    })
  }
  getSkillList(): void {
    this.commonService.getSkillsList().subscribe((result) => {
      this.skillList = result;
    })
  }

  getStatusToProgress(){
    this.jobStatusList.forEach(el => {
      if (el.name === 'In progress'){
        this.statusProgress = el.name
        this.jobForm.controls['job_opening_status'].setValue(el.id);
      }
    });
  }

  getRecruiterSelected(env: any) {
    var index = this.selectedRecruiter.findIndex(x => x?.id == env.id);
    if (index == -1) {
      var selectedArray = this.recruiterList.find(x => x.id == Number(env.id));
      this.selectedRecruiter.push(selectedArray);
      this.jobForm.controls['assigned_recruiter'].patchValue(this.selectedRecruiter);
    }
  }

  remove(data: any, type: any): void {
    const item = this.jobForm.value;
    if (type == "recruit") {
      this.selectedRecruiter.forEach((element: any, index: any) => {
        if (data.id == element.id) {
          var index = index;
          if (index !== -1) {
            this.selectedRecruiter.splice(index, 1);
            if (this.selectedRecruiter.length === 0){
               this.jobForm.controls['assigned_recruiter'].reset();
            }
            if (data.id == item.assigned_recruiter.id) {
              this.jobForm.controls['assigned_recruiter'].reset();
            }
          }
        }
      })
    }
  }

  getJobStatusSelected(env: any) {
    this.jobForm.controls['job_opening_status'].setValue(env.id);
    this.selectedJobStatus = env;
  }

  getJobDetails() {
    this.jobApiService.getJobViewDetails(this.jobDetails.id).subscribe((res: any) => {
      this.selectedJobStatus = res[0];
      console.log(res[0]);
      const convertedDate = this.convertDate(res[0]?.target_date);

      this.jobForm.patchValue({
        id: res[0]?.id,
        assigned_recruiter: res[0].assigned_recruiter,
        target_date: convertedDate,
        careerPage: res[0]?.career_page_link,
        demandId: res[0]?.job_demand,
      })
      this.jobForm.controls['job_opening_status'].setValue(res[0]?.job_opening_status?.id);
      this.selectedRecruiter = this.selectedJobStatus?.assigned_recruiter;
    })
  }


  addJob() {
    if (!this.isEdit) {
      this.jobForm.markAllAsTouched();
      if (this.jobForm.valid && this.selectedRecruiter.length > 0) {
        this.formSubmitted = true;  
        this.isLoading = true;
        let formData = new FormData();
        const data = this.jobForm.value;
        const demandId = this.jobDetails.id;
        var recruiterArray = [];
        recruiterArray = this.selectedRecruiter.map(x => x.id);
        const body = {
          "job_demand": demandId,
          "assigned_recruiter": recruiterArray,
          "target_date": data.target_date,
          "job_opening_status": data.job_opening_status,
          "career_page_link": data.careerPage
        }
        formData.append('data', JSON.stringify(body));
        this.jobApiService.addJobDetails(formData)
          .pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
          .subscribe(res => {
            if (res.status = 'success') {
              this.toast.success(res.message);
              this.dialogRef.close(true);
              this.formSubmitted = false;  
            }
          }, (err:any)=>{
            this.toast.error(err?.error?.message);
            this.formSubmitted = false;
          });
      }
    } else {
      this.editJob();
    }
  }


  editJob() {
    this.jobForm.markAllAsTouched()
    if (this.jobForm.valid && this.selectedRecruiter.length > 0) {
      let formData = new FormData();
      this.isLoading = true;
      this.formSubmitted = true;
      const data = this.jobForm.value;
      var recruiterArray = [];
      recruiterArray = this.selectedRecruiter.map(x => x.id);
        const body = {
          "job_demand": data.demandId,
          "assigned_recruiter": recruiterArray,
          "target_date": data.target_date,
          "job_opening_status": data.job_opening_status,
          "career_page_link": data.careerPage
        }
      formData.append('data', JSON.stringify(body));
      this.jobApiService.editJobDetails(formData, this.jobForm?.value?.id).pipe(finalize(() => { this.isLoading = false; })).subscribe(res => {
        if (res.status = 'success') {
          this.toast.success(res.message);
          this.dialogRef.close(true);
          this.formSubmitted = false;
        }
      },(err:any)=>{
        this.toast.error(err?.error?.message);
        this.formSubmitted = false;
      });
    }
  }

  convertDate(originalDate: string) {
    const [year, month, day] = originalDate.split('-');
    const formattedDate = new Date(`${day}-${month}-${year}`);
    const result = formattedDate.toISOString().split('T')[0];
    return result;
  }

  preventTab(event: KeyboardEvent): void {
    if (event.key === 'Tab'|| event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      event.preventDefault();
    }
  }

}
