import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JobApiService } from 'src/app/modules/user/services/job-api.service';

@Component({
  selector: 'app-jobview-details',
  templateUrl: './jobview-details.component.html',
  styleUrls: ['./jobview-details.component.scss']
})
export class JobviewDetailsComponent {
  userManageform!: FormGroup;
  isLoading = false;
  isEdit = false;

  currencyList = [
    'SAR', 'AED',  'USD', 'EU','QAR','INR'
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public datas: any, private jobApiService : JobApiService) {}
  data!: any;

  ngOnInit(): void {
    this.getJobViewDetails();
  }


  getJobViewDetails(){
    this.jobApiService.getJobViewDetails(this.datas).subscribe((res) => {
      this.data = {
        department: res[0]?.department?.name,
        recruiter: res[0]?.assigned_recruiter,
        location: res[0]?.location?.name,
        target_date: res[0]?.target_date,
        project_name: res[0]?.project_name,
        status: [res[0].job_opening_status?.color, res[0].job_opening_status?.label],
        skills: res[0].skills,
        position: res[0]?.job_position?.name,
        career_link: res[0]?.career_page_link ?? '-',
        client: res[0]?.client_name
      }

    })
  }



}
