<div class="login-wrappper-main">
  <div class="login-wrappper-main__login-left">
    <div class="login-wrappper-main__content">
      <h2>Join the Tribe. Share the Vibe!</h2>
      <h3>Beinex owns a great environment to work, learn, and grow professionally. We nurture a positive culture of passion and foster a self-sustaining cycle of success. That makes working at Beinex meaningful and pleasurable
      </h3>
    </div>
  </div>
  <div class="login-wrappper-main__login-right">
    <div class="login-wrappper-main__login-content">

      <div class="login-wrappper-main__head">
        <img class="login-wrappper-main__logo" src="assets/images/logo.svg" alt="Beinex Logo" />
      </div>

      <form [formGroup]="CandidateloginForm">
        <div class="sub-form" *ngIf="!forgot_password">

          <h2 class="login-wrappper-main__sign-in">Sign In</h2>
          <div class="bnx-form">
            <div class="bnx-form__group">
              <input class="bnx-form__input" formControlName="email" placeholder="email id" type="text" />
              <div class="bnx-form__label">Email Id</div>
              <error-msg [control]="CandidateloginForm.controls['email']"></error-msg>
            </div>
          </div>

          <div class="bnx-form">
            <div class="bnx-form__group">
              <div class="show-password">
                <input class="bnx-form__input" formControlName="password" placeholder="Password" [type]="show_button ? 'text' : 'password'">
                <div class="bnx-form__label">Password</div>
                <i [class]="show_eye ? 'fa fa-eye' : 'fa fa-eye-slash'" (click)="showPassword()"></i>
                <error-msg [control]="CandidateloginForm.controls['password']"></error-msg>
              </div>


            </div>
          </div>

          <div class="remeber-passs">
            <div>
              <input type="checkbox" name="remember">
              <label for="remember">Remember Me</label>
            </div>
            <div>
              <u class="forgot_link" (click)="addUsername()">Forgot Password</u>
            </div>
          </div>

          <button class="login-button" (click)="onLogin()">Submit</button>
        </div>


        <div class="sub-form" *ngIf="add_username">

          <h2 class="login-wrappper-main__sign-in">Forgot Password</h2>

          <div class="bnx-form">
            <div class="bnx-form__group">
              <input class="bnx-form__input" formControlName="username" placeholder="emailid" type="text" />
              <div class="bnx-form__label">Email Id</div>
              <error-msg [control]="CandidateloginForm.controls['username']"></error-msg>
            </div>
          </div>

          <div class="username__back">
              <button class="login-button-submit" (click)="sendOtp()">
                {{isLoading ? "Submitting.." : "Submit"}}
              </button>
              <u class="forgot_link__username" (click)="back()">Back to Login</u>
          </div>


        </div>

        <div class="sub-form" *ngIf="forgot_password && !add_username">
          <h2 class="login-wrappper-main__sign-in">Change Password</h2>

          <div class="bnx-form">
            <div class="bnx-form__group">
              <input class="bnx-form__input" formControlName="email_otp" type="text" />
              <div class="bnx-form__label">Enter OTP</div>
            </div>
          </div>


          <div class="bnx-form">
            <div class="bnx-form__group">
              <div class="show-password">
              <input class="bnx-form__input" formControlName="new_password" placeholder="newpassword" type="password" />
              <div class="bnx-form__label">New Password</div>
              <i [class]="show_eye ? 'fa fa-eye' : 'fa fa-eye-slash'" (click)="showPassword()"></i>
              <error-msg [control]="CandidateloginForm.controls['new_password']"></error-msg>
            </div>
            </div>
          </div>

          <div class="bnx-form">
            <div class="bnx-form__group">
              <div class="show-password">
                <input class="bnx-form__input" formControlName="confirm_password" placeholder="confirmpassword" type="password">
                <div class="bnx-form__label">Confirm Password</div>
                <i [class]="show_eye ? 'fa fa-eye' : 'fa fa-eye-slash'" (click)="showPassword()"></i>
                <error-msg [control]="CandidateloginForm.controls['confirm_password']"></error-msg>
              </div>
            </div>
          </div>
          <button class="login-button-submit" (click)="onSubmit()">Submit</button>
        </div>

      </form>

    </div>
  </div>
</div>
