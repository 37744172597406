import { Component, OnInit } from '@angular/core';
import { TableOption, editDelete } from 'src/app/core/interface/table';
import { AdminApiService } from '../../services/admin-api.service';
import { finalize } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { FunctionHelperService } from 'src/app/core/helper/function.helper.service';
import { environment } from 'src/environments/environment';
import { DownloadService } from 'src/app/core/services/common/download.service';

@Component({
  selector: 'app-interviews-panel',
  templateUrl: './interviews-panel.component.html',
  styleUrls: ['./interviews-panel.component.scss']
})
export class InterviewsPanelComponent implements OnInit {

constructor(private adminApiService:AdminApiService,private datePipe :DatePipe, private router: Router,private helper: FunctionHelperService,private downloadService: DownloadService){}

tableEditDelele: editDelete = {
  edit: false,
  delete: false,
};
tableTitle = [
  'Candidate Name',
  'Department',
  'Job Title',
  'Scheduled',
  'Status',
  'Level',
  ''
];

isLoading: boolean = false;
tableKey = [
  'link_name',
  'department',
  'jobTitle',
   'scheduled',
   'status',
   'level'
  ];

filterArray: any = {
  "job_position": [],
   "job_opening_status": [],
}
totalPages!: number;
  tableLimit = 10;
  page = 1;

  tableOption: TableOption = {
    viewMode: true,
    infoMode: false,
    optionMode: false,
  }
candidateList: any[] = [];
setDefaultPage: boolean =false;

interviewStatusList = [
  { status: 'In Progress', color: '#17a2b8' },
  { status: 'Pending', color: '#ffc107' },
  { status: 'Shortlist', color: '#007bff' },
  { status: 'Completed', color: '#28a745' },
  { status: 'On hold', color: '#6c757d' },
  { status: 'Rejected', color: '#dc3545' }
];

  ngOnInit(): void {
  this.getInterviewList('')
}


getChange(env: any) {
  console.log(env);
  switch (env.action) {
    case 'pageLimit':
        this.tableLimit = env.pageLimit;
        this.getInterviewList(this.filterArray);
        break;
        case 'page':
        this.page = env.page;
        this.getInterviewList(this.filterArray);
        break;

      case 'cell_click' :
        this.getDetails(env?.row?.candidate_id);
        break;
      case 'viewDetail':
        this.getDetails(env?.row?.candidate_id);
        break;
    default:
      break;
  }
}

getDetails(id:any){
  this.router.navigate(['/main/user/interviewer-candidate-panel'], { queryParams: { id: id } });
  // this.router.navigate(['/main/user/interview-panel'],{ queryParams: { id: id }});
}

getInterviewList(filterArray: any,): void {
  const token = this.helper.userData.key;
    this.filterArray = filterArray;
    if (filterArray == '') {
      filterArray = {
        "job_position": [],
        "job_opening_status": [],
      }
    }
    this.isLoading = true;
    this.adminApiService.getInterviewList(filterArray, (this.page - 1) * this.tableLimit, this.tableLimit,token)
      .pipe(finalize(() => { this.isLoading = false; }))
       .subscribe((res: any) => {
         this.isLoading = false;
         this.setDefaultPage = false;
        this.totalPages = res?.count % this.tableLimit == 0 ? Math.trunc(res?.count / this.tableLimit) : Math.trunc(res.count / this.tableLimit) + 1;
        // this.totalPages = this.totalPages > 3 ? 3 : this.totalPages;
         this.candidateList = res.results.map((item: any) => {
          let tableColor = this.interviewStatusList.filter((res: any) => {
            if (res.status == item.status) {
              return res.color;
            }
          })
          return {
            id: item?.id,
            jobTitle: item.candidate.job_openings.job_title,
            link_name: item.candidate.name,
            candidate_id: item.candidate.id,
            type: item.type,
            department: item.candidate.department,
            scheduled:item.date !== null ? item.date : '-',
            status: {
              label: item.status,
              color: tableColor[0]?.color
            },
            level: item.level
          }
        })
      })
  }


  getFilteredChange(env:any){
    this.tableLimit = 10;
        this.page = 1;
        this.setDefaultPage = true
    switch (env.type) {
      case 'search':
        this.getInterviewList(env.filterData);
        break;
      case 'filter':
        this.getInterviewList(env.filterData);
        break;
      case 'clear':
        this.getInterviewList(env.filterData);
        break;
      default:
        break;
    }
    
  }

  downloadExcel(filterArray: any): void{
    const token = this.helper.userData.key;
    if(filterArray == ''){
      filterArray = {
        "location" : [],
        "job_position" : [],
        "job_opening_status" : [],
       }
    }

    const apiUrl = environment.baseUrl + environment.apiVersion;
    this.downloadService
        .download(
          `${apiUrl}interviewer-interview-export/xls_download?filter=${JSON.stringify(filterArray)+'&token='+token}`
        )
        .subscribe((data) => {
          this.downloadService.createATagAndClickDownload(data, {
            name: 'Interviewer- Interview Data List.xls',
          });
        });
    // window.open(apiUrl + 'interviewer-interview-export/xls_download?filter='+ JSON.stringify(filterArray)+'&token='+token);
    }
}
