import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ListSettingsComponent } from './list-settings/list-settings.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { SkillsListComponent } from './skills-list/skills-list.component';
import { JobTitleListComponent } from './job-title-list/job-title-list.component';
import { TemplateListComponent } from './template-list/template-list.component';
import { AddNewSettingDetailsComponent } from './modals/add-new-setting-details/add-new-setting-details.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NgSelectModule } from '@ng-select/ng-select';


const routes: Routes = [
  {
      path: '',
      component: ListSettingsComponent
  },
  {
      path: 'list/:id',
      component: ListSettingsComponent
  },

];


@NgModule({
  declarations: [
    ListSettingsComponent,
    SkillsListComponent,
    JobTitleListComponent,
    TemplateListComponent,
    AddNewSettingDetailsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatTabsModule,
    ReactiveFormsModule,
    MatDialogModule,
    NgSelectModule,
    FormsModule,
    RouterModule.forChild(routes),
  ]
})
export class GeneralSettingModule { }
