import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiEndPoints } from 'src/app/core/constants/constants';
import { HttpService } from 'src/app/core/services/common/HttpService';

@Injectable({
  providedIn: 'root'
})
export class GeneralSettingsService {

  constructor(
    private httpService : HttpService
  ) { }

  addContract(data:any): Observable<any> {
    return this.httpService.post(ApiEndPoints.addContract,data);
  }

  getList(endPoint:any,filterArray: any,offset:any,limit:any): Observable<any> {
   return this.httpService.get(`${endPoint}?offset=${offset}&limit=${limit}&filter=${JSON.stringify(filterArray)}`);
  }
  getLevelList(endPoint:any): Observable<any> {
    return this.httpService.get(`${endPoint}`);
   }
}
