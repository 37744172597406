<div class="bnx-filter">
  <app-filter  [showExcelDownload]="true"  [status]="true"   [department]="true" [componentName]="'interviews-panel'" (download)="downloadExcel($event)" (filterChange)="getFilteredChange($event)"></app-filter>
</div>

<div class="bnx-table-wrapper__card">
  <div class="bnx-table-wrapper__card-body">
    <div class="bnx-table-wrapper__table-card">
      <app-table-view [data]="candidateList" [tableKeys]="tableKey" [setDefault]="setDefaultPage"  [isLoading]="isLoading"
        [tableTitle]="tableTitle" [tableEditDelele]="tableEditDelele" [tableOption]="tableOption" [totalPages]="totalPages" (event)="getChange($event)"></app-table-view>
    </div>
  </div>
</div>
