import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ValidateComponent } from './authentification/validate/validate.component';
import { AuthGuard } from './core/guards/auth.guard';
import { ApprovedRejectComponent } from './modules/user/pages/approved-reject/approved-reject.component';
import { FullComponent } from './shared/layouts/full/full.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'status/:data',
    component : ApprovedRejectComponent
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.routing.module').then(m => m.LoginRoutingModule),
    data: { core: { title: 'Login' } }
  },
  {
    path: 'main',
    component:FullComponent,
    canActivate:[AuthGuard],
    children:[
      {
        path:'admin',
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
      },
      {
        path:'user',
        loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule),
      },
      {
        path:'settings',
        loadChildren: () => import('./modules/admin/pages/general-settings/general-setting.module').then(m => m.GeneralSettingModule),
      }

    ]
  },
  {
    path: 'validate', component: ValidateComponent, // canActivate: [PublicAuthGuard]
  },
  {
    path: '**',
    redirectTo:'/login'
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
