import { Component } from '@angular/core';

@Component({
  selector: 'app-guideline-modal',
  templateUrl: './guideline-modal.component.html',
  styleUrls: ['./guideline-modal.component.scss']
})
export class GuidelineModalComponent {

}
