import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CandidateSideBar, HRSideBar, InterviewerSideBar, SLTCEOSideBar, sideBarMenus } from 'src/app/core/constants/constants';
import { FunctionHelperService } from 'src/app/core/helper/function.helper.service';
import { CommonService } from '../../service/common.service';
import { LoginService } from 'src/app/modules/login/service/login.service';
import { LogoutConfirmComponent } from '../../widget/logout-confirm/logout-confirm.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {

  sidebar_data = sideBarMenus;
  settingShow:boolean = false;

  // navigate(i:number){
  //   this.sidebar_data.forEach((el:any)=>{
  //     el.isActive = false;
  //   });
  //   this.sidebar_data[i].isActive = true;
  // }

  constructor(
    public route:Router,
    public common: CommonService,
    public helper:FunctionHelperService,
    private loginService: LoginService,
    private router: Router,
    public dialog: MatDialog,
  ){}


  ngOnInit(): void {  
    this.settingShow = false;
    switch (this.helper.userData?.user_type) {
      case 'HR':
        this.sidebar_data = HRSideBar;
        break;

      case 'Interviewer':
        this.sidebar_data = InterviewerSideBar;
        break;

        case 'SLT CEO':
        this.sidebar_data = SLTCEOSideBar;
        break;

        case 'SLT Directors':
          this.sidebar_data = SLTCEOSideBar;
          this.common.slt_directors = true;
          break;
        case 'CANDIDATE':
          this.sidebar_data = CandidateSideBar;
          break;
      default:
       this.sidebar_data = sideBarMenus;
      break;
    }

  }
  showSubmenu(itemEl: HTMLElement) {
    itemEl.classList.toggle("showMenu");
  }

  toSettings() {
    this.router.navigateByUrl(`/main/settings/list/skills`);
    this.settingShow = true;
  }


  selectNavbar(){
    if (this.router.url.includes('/main/admin/index')) {
      this.common.headerCalender = true
    } else {
      this.common.headerCalender = false;
    }

  }



  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmComponent, {
      maxWidth: "500px"
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        const type = this.helper.userData;
        this.loginService.logout().subscribe((res:any) => {
          this.helper.deleteCookie().then(() => {
            if (type.user_type === 'CANDIDATE'){
              this.router.navigateByUrl('candidate-login');
            } else{
            this.router.navigateByUrl('login');
            }
          })
        })
      }
    });
  }
}
