<div class="bnx-modal">
  <div class="bnx-modal__close-btn" [mat-dialog-close]="false">
    <svg width="15" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times"
      class="svg-inline--fa fa-times fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
      <path fill="currentColor"
        d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z">
      </path>
    </svg>
  </div>

  <div class="bnx-modal__title">
    <h2 class="bnx-modal__heading">{{isEdit ? 'Edit Job':'Add Candidate'}}</h2>
  </div>

  <form [formGroup]="candidateForm">
    <div class="bnx-view-modal__body modal_scroll pt-3">
      <div class="bnx-modal__wrapper">
        <div class="bnx-form__row bnx-scroll-box">

          <div class="bnx-form__col">
            <div class="bnx-form__group">
              <input class="bnx-form__input" placeholder="First Name" formControlName="first_name" type="text" />
              <div class="bnx-form__label">First Name <span class="bnx-form__aster-risk">*</span></div>
              <error-msg [control]="candidateForm.controls['first_name']"></error-msg>
            </div>
          </div>

          <div class="bnx-form__col">
            <div class="bnx-form__group">
              <input class="bnx-form__input" placeholder="name" formControlName="last_name" type="text" />
              <div class="bnx-form__label">Last Name <span class="bnx-form__aster-risk">*</span></div>
              <error-msg [control]="candidateForm.controls['last_name']"></error-msg>
            </div>
          </div>

          <div class="bnx-form__col">
            <div class="bnx-form__group">
              <input class="bnx-form__input" placeholder="name" formControlName="email" type="text" />
              <div class="bnx-form__label">Email <span class="bnx-form__aster-risk">*</span></div>
              <error-msg [control]="candidateForm.controls['email']"></error-msg>
            </div>
          </div>


          <div class="bnx-form__col">
            <div class="bnx-form__group required">
              <input class="bnx-form__input" placeholder="name" formControlName="phone" type="text" />
              <div class="bnx-form__label">Phone <span class="bnx-form__aster-risk">*</span></div>
              <error-msg [control]="candidateForm.controls['phone']"></error-msg>
            </div>
          </div>

          <div class="bnx-form__col">
            <div class="bnx-form__group required">
              <div class="bnx-currency-item">
                <ng-select class="recNgclass" [items]="genderList" bindLabel="name" placeholder="Select Gender" title=""
                  formControlName="gender"></ng-select>
              </div>
              <error-msg [control]="candidateForm.controls['gender']"></error-msg>
            </div>
          </div>

          <div class="bnx-form__col">
            <div class="bnx-form__group">
              <input class="bnx-form__input" placeholder="Experience (in years)" formControlName="years_of_exp"
                type="number" (keydown)="onKeyDown($event)" />
              <div class="bnx-form__label">Experience (in years)<span class="bnx-form__aster-risk">*</span></div>
              <error-msg [control]="candidateForm.controls['years_of_exp']"></error-msg>
              <small class="error_msg" *ngIf="candidateForm.controls['years_of_exp'].hasError('pattern')">
                Invalid experience
              </small>
            </div>
          </div>


          <div class="bnx-form__col">
            <div class="bnx-form__group required">
              <div class="bnx-currency-item">
                <ng-select class="recNgclass" bindLabel="name" bindValue="id" [items]="jobTitleList"
                  formControlName="job_position" placeholder="Select Job" (change)="getJobDetails($event)" (clear)="removeJobId()">
                  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div matTooltip="{{item.name}}" class="ng-select-name"> {{item.name}}</div>
                  </ng-template>
                </ng-select>

              </div>
              <error-msg [control]="candidateForm.controls['job_position']"></error-msg>
            </div>
          </div>

          <div class="bnx-form__col">
            <div class="bnx-currency-item arrow-none ">
              <div class="bnx-form__label">Job ID</div>
              <ng-select class="recNgclass" placeholder="Job ID" formControlName="jobId" [readonly]="true"></ng-select>
            </div>
          </div>


          <div class="bnx-form__col">
            <div class="bnx-currency-item arrow-none ">
              <div class="bnx-form__label">Department</div>
              <ng-select class="recNgclass" placeholder="Department"formControlName="jobDept" [readonly]="true"></ng-select>
            </div>
          </div>

          <div class="bnx-form__col">
            <div class="bnx-currency-item arrow-none ">
              <div class="bnx-form__label">Status</div>
              <ng-select class="recNgclass" [items]="statusList" bindLabel="name" placeholder="Pending"
                formControlName="status" [readonly]="true"></ng-select>
            </div>
          </div>


          <div class="bnx-form__col">
            <div class="bnx-currency-item required">
              <ng-select class="recNgclass" placeholder="Select Source" formControlName="source"
                (change)="selectOption($event)">
                <ng-option *ngFor="let item of sourceList" [value]="item"> {{item.name}}</ng-option>
              </ng-select>
            </div>
            <error-msg [control]="candidateForm.controls['source']"></error-msg>
          </div>



          <div class="bnx-form__col" *ngIf="showEmployee">
            <div class="bnx-currency-item required">
              <ng-select class="recNgclass" placeholder="Select Employee" formControlName="empName">
                <ng-option *ngFor="let item of beinexEmployee" [value]="item.name"> {{item.name}}</ng-option>
              </ng-select>
            </div><error-msg [control]="candidateForm.controls['empName']"></error-msg>
          </div>


          <div class="bnx-form__col" *ngIf="showReferral">
            <div class="bnx-form__group">
              <input class="bnx-form__input" placeholder="Referral From" formControlName="referralName" type="text" />
              <div class="bnx-form__label">Referral From <span class="bnx-form__aster-risk">*</span></div>
            </div>
            <error-msg [control]="candidateForm.controls['referralName']"></error-msg>
          </div>

           <div class="bnx-form__border-wraper">
            <div class="bnx-form__heading">Resume</div>
            <div class="bnx-form__border-outer">
            <div class="bnx-form__col">
              <div class="bnx-form__group mb-0">
                <div class="bnx-dropzone bnx-dropzone--table" [ngClass]="{'disabled': disableURL === false && resumeDoc === true}" id="drop-zone" appFiledrop (filesChangeEmiter)="onFileChange($event,'drop')">
                  <div class="bnx-dropzone__text-wrapper">
                    <div class="bnx-dropzone__centered bnx-dropzone__centered--flex-row">
                      <em  class="bnx-icon bnx-icon--upload "></em>
                      <input type="file" (change)="onFileChange($event.target,'click')"
                        accept=".pdf"
                        name="file" id="file">
                      <label class="bnx-dropzone__label" for="file">
                        <span class="bnx-dropzone__textLink">Drag and drop your resume here Or 
                         
                        </span>
                        <div class="bnx-dropzone__a"><a> Browse</a></div>
                      </label>
  
                    </div>
                  </div>
                </div>
  
                <div class="bnx-file-data">
                  <div *ngFor="let file of fileArray;let i=index" class="bnx-file-data__card">
                    <svg class="bnx-file-data__card__icon" xmlns="http://www.w3.org/2000/svg" width="15" height="19"
                      viewBox="0 0 15 19" fill="none">
                      <path
                        d="M14.0769 8.07993V16.0385C14.0769 16.5587 13.8703 17.0576 13.5024 17.4255C13.1345 17.7933 12.6356 18 12.1154 18H2.96154C2.44131 18 1.94238 17.7933 1.57452 17.4255C1.20666 17.0576 1 16.5587 1 16.0385V2.96154C1 2.44131 1.20666 1.94238 1.57452 1.57452C1.94238 1.20666 2.44131 1 2.96154 1H6.997C7.3437 1.00005 7.67619 1.13778 7.92137 1.38291L13.694 7.15555C13.9391 7.40074 14.0769 7.73323 14.0769 8.07993Z"
                        stroke="#005695" stroke-width="1.5" stroke-linejoin="round" />
                      <path
                        d="M7.53876 1.3269V6.23075C7.53876 6.57757 7.67654 6.91019 7.92178 7.15543C8.16702 7.40067 8.49963 7.53844 8.84645 7.53844H13.7503M4.26953 10.8077H10.808M4.26953 14.0769H10.808"
                        stroke="#005695" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
  
                    <label class="bnx-file-data__card-text" title="{{file?.name ?? file?.document_name}}">{{file?.name ??
                      file?.document_name}}</label>
                    <svg class="bnx-file-data__card__close" (click)="removeFile(i)" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24" width="24" height="24">
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                    </svg>
                  </div>
                </div>
  
             
              </div>
            </div>
            <span class="bnx-dropzone__span" >or</span>
            <div class="bnx-form__col" *ngIf="disableURL">

              <div class="bnx-currency-item arrow-none">
                <div class="bnx-form__label">Insert resume link</div>
                <ng-select class="recNgclass" placeholder="Insert resume link" formControlName="resumeURL" [readonly]="true"></ng-select>
              </div>
            </div>

            <div class="bnx-form__col" *ngIf="!disableURL">
              <input class="bnx-form__input" placeholder="URL" formControlName="resumeURL" type="text" (change)="addedURL($event)"/>
              <div class="bnx-form__label">Insert resume link <span class="bnx-form__aster-risk">*</span></div>
              <error-msg [control]="candidateForm.controls['resumeURL']"></error-msg>
            </div>
            </div>
           </div>



          <div class="bnx-form__col bnx-form--col--w-100" >
            <div class="bnx-form__group">
              <textarea class="bnx-form__input comment" placeholder="HR Feedback" formControlName="hr_screening" style="resize: none;" name="" id="" cols="10" rows="8"></textarea>
            <div class="bnx-form__label">HR Feedback <span class="bnx-form__aster-risk">*</span></div>
            <error-msg [control]="candidateForm.controls['hr_screening']"></error-msg>
            </div>
          </div>

        </div>
        <div class="submit">
          <button class="bnx-btn" (click)="addCandidate()" [disabled]="isLoading ? true : false">{{isLoading ? isEdit ? 'Updating ...':'Adding ...':isEdit ?
            'Update Candidate':'Add Candidate'}}</button>
          <button class="bnx-btn bnx-btn-border" [mat-dialog-close]="false">Cancel</button>
        </div>
      </div>
    </div>
  </form>

</div>
