import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-interview-cancel',
  templateUrl: './interview-cancel.component.html',
  styleUrls: ['./interview-cancel.component.scss']
})
export class InterviewCancelComponent {
  reason!: string;

  constructor(private commonService: CommonService,
    private toast: ToastrService,
    public dialogRef: MatDialogRef<InterviewCancelComponent>) { }


  addReason(): void {
    if(this.reason !== undefined){
    this.dialogRef.close(this.reason);
    }
    else {
      this.toast.warning('Reason is required');
    }
  }
}
