import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilterPipe'
})
export class SearchFilterPipePipe implements PipeTransform {

  transform(value: any, args?: any,label?:any): any {
    if(!value){return null;}
    if(!args){return value;}

    args = args.toLowerCase();

    return value.filter(function(data: any){
        return JSON.stringify(data[label]).toLowerCase().includes(args);
    });
}
}
