<div class="bnx-modal">
  <div class="bnx-modal__close-btn" [mat-dialog-close]="false">
    <svg width="15" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times"
       class="svg-inline--fa fa-times fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
       <path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z">
       </path>
    </svg>
  </div>

  <div class="bnx-modal__title">
    <h2 class="bnx-modal__heading">Add Offer Status</h2>
  </div>

  <div class="bnx-view-modal__body">
    <div class="bnx-modal__wrapper">
      <form class="bnx-form" [formGroup]="offerStatusForm">
        <div class="bnx-form__row">
          <div class="bnx-form__col">
            <div class="bnx-form__label">Name</div>
            <div class="bnx-form__group" *ngIf="isEdit !== true">
              <!-- <div class="bnx-currency-item required"> -->
                <app-searchable-select [asterRisk]="true" [data]="OfferCandidateList" [placeholder]="selectedUser?.candidate?.name ?? 'Select Name'"
                nameField="name" (selecteditem)="getSelectedItem($event)" idField="id" [isloading]="isLoadingEmp">
              </app-searchable-select>
              <error-msg [control]="offerStatusForm.controls['candidate']"></error-msg>
              <!-- </div> -->
            </div>

            <div class="bnx-form__group" *ngIf="isEdit === true">
              <input type="text" class="bnx-form__input" formControlName="candidate_name" readonly="readonly">
            </div>
          </div>
          <div class="bnx-form__col">
            <div class="bnx-currency-item">
              <input type="text" class="bnx-form__input" placeholder="email" formControlName="email" readonly="readonly">
              <div class="bnx-form__label">Email</div>
            </div>
          </div>
          <div class="bnx-form__col">
            <div class="bnx-currency-item">
              <input type="text" class="bnx-form__input" placeholder="Phone" formControlName="phone" readonly="readonly">
              <div class="bnx-form__label">Phone</div>
            </div>
          </div>
          <div class="bnx-form__col">
            <div class="bnx-form__label">Offer Status</div>
            <div class="bnx-form__group required">
              <ng-select class="recNgclass"  placeholder="Offer Status" [items]="statusList" bindLabel="name"  formControlName="offer_status"
              (change)="getStatusCheck($event)"></ng-select>
              <error-msg [control]="offerStatusForm.controls['offer_status']"></error-msg>
            </div>
        </div>
          <div class="bnx-form__col" *ngIf="!isDeclined">
            <div class="bnx-currency-item">
              <input type="date" class="bnx-form__input" placeholder="Offer Date" formControlName="offer_date"  (change)="checkingOfferedDate($event?.target)">
              <!-- [attr.min]="minDate" -->
              <div class="bnx-form__label">Offer Date <span class="bnx-form__aster-risk">*</span></div>
             <error-msg [control]="offerStatusForm.controls['offer_date']"></error-msg>
            </div>
          </div>

          <div class="bnx-form__col">
            <div class="bnx-form__group">
              <input type="text" class="bnx-form__input" placeholder="Job Title" formControlName="job_title" readonly="readonly">
              <div class="bnx-form__label">Job Title</div>
              </div>
          </div>



          <div class="bnx-form__col" *ngIf="!isDeclined">
            <div class="bnx-currency-item">
              <input type="date"  class="bnx-form__input" placeholder="DOJ" formControlName="date_of_joining"  > 
              <!-- [attr.min]="tomorowDate" -->
              <div class="bnx-form__label">DOJ <span class="bnx-form__aster-risk">*</span></div>
             <error-msg [control]="offerStatusForm.controls['date_of_joining']"></error-msg>
            </div>
          </div>
        </div>
      </form>

      <div class="submit">
        <button class="bnx-btn" (click)="addClient()" [disabled]="formSubmitted ? true : false">{{isLoading ? isEdit ? 'Updating ...':'Adding ...':isEdit ? 'Update':'Submit'}}</button>
        <button class="bnx-btn bnx-btn-border" [mat-dialog-close]="false">Cancel</button>
      </div>
    </div>
  </div>

</div>
