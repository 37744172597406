<div class="bnx-modal bnx-modal--modal2">
  <div class="bnx-modal__close-btn" [mat-dialog-close]="false">
    <svg width="15" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times"
      class="svg-inline--fa fa-times fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
      <path fill="currentColor"
        d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z">
      </path>
    </svg>
  </div>


  <div class="bnx-modal__padd-header">
    <div class="bnx-modal__head-icon">
      <svg width="65" height="65" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="37" cy="37" r="35.5" fill="#EDF7FF" stroke="#8FBADD" stroke-width="3" />
        <rect x="17.3423" y="26.865" width="40.0941" height="25.635" rx="2.5" fill="#96BEDF" stroke="#96BEDF" />
        <path d="M16 34.4736L28.6316 38.6842H46.3158L58.9474 34.4736" stroke="#EDF7FF" stroke-width="2"
          stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M30.7896 22H44.158C45.2626 22 46.158 22.8954 46.158 24V27.579H28.7896V24C28.7896 22.8954 29.685 22 30.7896 22Z"
          stroke="#96BEDF" stroke-width="2" />
      </svg>
    </div>
    <div class="bnx-modal__head-title">
      <h3>{{data?.position}}</h3>
      <p>Experience : <span>{{data?.experience}}</span></p>
    </div>
    <div class="status__title">
      <p></p>
      <span class="bnx-data-status" [ngStyle]="{'background-color': data?.status?.color}"></span>
      <span>{{data?.status?.label}}</span>
    </div>

  </div>

  <div class="bnx-view-modal__body">
    <div class="bnx-modal__wrapper ">
      <div class="bnx-form" *ngIf="firstSlide">
        <div class="bnx-form__row" >
            <div class="bnx-form__row pt-5 bnx-scroll-box modal_scroll`" >
              <div class="bnx-form__row bnx-form__row--border">
                <div class="bnx-form__col">
                  <div class="bnx-form__group">
                    <div class="">
                      <div class="bnx-form__label-2">Department</div>
                      <h3 class="bnx-form__sub-head">{{data?.department|| '-'}}</h3>
                    </div>
                  </div>
                </div>
                <div class="bnx-form__col">
                  <div class="bnx-form__label-2">Location</div>
                  <div class="">
                    <h3 class="bnx-form__sub-head">{{data?.location|| '-'}}</h3>
                  </div>
                </div>
                </div>

                <div class="bnx-form__row bnx-form__row--border">
                  <div class="bnx-form__col">
                    <div class="bnx-form__label-2">Client Name</div>
                    <div class="">
                      <h3 class="bnx-form__sub-head">
                        {{data?.client_name|| '-'}}
                      </h3>
                    </div>
                  </div>
                  <div class="bnx-form__col">
                    <div class="bnx-form__label-2">Target Date</div>
                    <div class="bnx-form__group">
                      <h3 class="bnx-form__sub-head">{{data?.target_date || '-'}}</h3>
                    </div>
                  </div>
                </div>

                <div class="bnx-form__row bnx-form__row--border">
                  <div class="bnx-form__col">
                    <div class="bnx-form__label-2">No.of Resources</div>
                    <div class="">
                      <h3 class="bnx-form__sub-head">
                        {{data?.resources|| '-'}}
                      </h3>
                    </div>
                  </div>
                  <div class="bnx-form__col">
                    <div class="bnx-form__label-2">Project Name</div>
                    <div class="">
                      <h3 class="bnx-form__sub-head">
                        {{data?.project_name || '-'}}
                      </h3>
                    </div>
                  </div>
                </div>

                <div class="bnx-form__row bnx-form__row--border">

                  <div class="bnx-form__col">
                    <div class="bnx-form__label-2">Job Status</div>
                    <div class="">
                      <h3 class="bnx-form__sub-head">
                        {{data?.is_active || '-'}}
                      </h3>
                    </div>
                  </div>

                  <div class="bnx-form__col">
                    <div class="bnx-form__label-2">Sub Skills</div>
                      <h3>{{data?.sub_skills || '-'}}</h3>
                  </div>
                </div>

                <div class="bnx-form__row bnx-form__row--border">
                  <div class="bnx-form__col w-100">
                    <div class="bnx-form__label-2">Skills</div>
                    <div class="skills">
                      <div *ngFor="let item of data?.skills">
                        <p>{{item?.name || '-'}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bnx-form__row bnx-form__row--border">
                  <div class="bnx-form__col w-100">
                    <div class="bnx-form__label-2">Job Description</div>
                    <h3 class="bnx-form__sub-head">
                      {{data?.job_description || '-'}}
                    </h3>
                  </div>
                </div>

            </div>

        </div>
        <div class="back-btn">
          <div class="bnx-btn" (click)="clicKBack()">Next</div>
        </div>
      </div>

      <div class="bnx-form " *ngIf="secondSlide">
        <div class="bnx-form__row " >
          <div class="bnx-form__row pt-5">

            <div class="bnx-form__row--border">
              <div class="bnx-form__col__comment">
                <div class="bnx-form__label-2">Job Description</div>
                <h3 class="bnx-scroll-box modal_scrol2">{{data?.job_description || '-'}}</h3>
              </div>
            </div>

            <div class="bnx-form__row--border">
              <div class="bnx-form__col__comment">
                <div class="bnx-form__label-2">Comment</div>
                  <h3 class="bnx-scroll-box modal_scrol2">{{data?.comment || '-'}}</h3>
              </div>
            </div>

          </div>
        </div>

        <div class="back-btn2">
          <div class="bnx-btn" (click)="clicKBack2()">Back</div>
        </div>

      </div>


  </div>
