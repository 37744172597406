import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FunctionHelperService } from 'src/app/core/helper/function.helper.service';
import { CommonService } from '../../service/common.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent{
   NotifList :any[]=[];

   constructor(
     public commonService:CommonService,
     public dialog: MatDialog,
     private toast :ToastrService,
     private router:Router,
     private helper:FunctionHelperService
   ){}

   clearAllNotification(){
    this.commonService.claerAll()
    .subscribe(res=>{
      this.commonService.notifList =[];
    })
  }

  openDialogBox(id:any): void{ 
    if (id !== null) {
    }else{
      this.toast.warning("Contract dosn't exist")
    }
  }

  expand(i:number)
  {
    this.commonService.notifList[i].is_open = !this.commonService.notifList[i].is_open;
  }


}
