import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './HttpService';


@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  private render: Renderer2;
constructor(rendererFactory: RendererFactory2,private httpService:HttpService) {
  this.render = rendererFactory.createRenderer(null, null);
}

  createATagAndClickDownload(blob: Blob, option: { name: string }) {

    const url = window.URL.createObjectURL(blob);
    const a =  this.render.createElement('a');
    this.render.appendChild(document.body, a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = option.name;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  download(url:string):Observable<any>{
    return this.httpService.download(url)
  }
}
