<!-- <div class="select-box" [id]="formName" id="select-box">
  <div (click)="openOptions()" class="selected bnx-form__input">
    <label>{{selectedOption ===''? selected : selectedOption}}</label>
 </div>
  <div class="choose hide" [class]="formName+'-choose'">
      <div class="search-bar">
        <div class="search">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="612.01px" height="612.01px" viewBox="0 0 612.01 612.01" style="enable-background:new 0 0 612.01 612.01;" xml:space="preserve">
              <g>
                  <g id="_x34__4_">
                      <g>
                          <path d="M606.209,578.714L448.198,423.228C489.576,378.272,515,318.817,515,253.393C514.98,113.439,399.704,0,257.493,0     C115.282,0,0.006,113.439,0.006,253.393s115.276,253.393,257.487,253.393c61.445,0,117.801-21.253,162.068-56.586     l158.624,156.099c7.729,7.614,20.277,7.614,28.006,0C613.938,598.686,613.938,586.328,606.209,578.714z M257.493,467.8     c-120.326,0-217.869-95.993-217.869-214.407S137.167,38.986,257.493,38.986c120.327,0,217.869,95.993,217.869,214.407     S377.82,467.8,257.493,467.8z"/>
                      </g>
                  </g>
              </g>
            </svg>
           <input placeholder="Search" type="search" [(ngModel)]="searchFilter">
        </div>
      </div>
      <div *ngFor="let item of data| searchFilterPipe:searchFilter:nameField"(click)="selectedItem(item)" class="option">
         <span>{{item[nameField]}}</span>
      </div>
      <p class="no-data" *ngIf="(data| searchFilterPipe:searchFilter:nameField).length ==0">Data not found</p>
  </div>
</div> -->

<!-- <form [formGroup]="formGroup">
 <mat-form-field >
    <mat-select [formControlName]="formName" [value]="selected" #singleSelect (selectionChange)="selectedItem($event)" [placeholder]="placeholder">
      <mat-option>
      <div class="search-bar">
        <ngx-mat-select-search  [formControl]="dataFilterCtrl" placeholderLabel="Search" noEntriesFoundLabel="no matching data found"></ngx-mat-select-search>
        <button (click)="addModals()" >+</button>
      </div>
    </mat-option>
      <mat-option *ngFor="let item of data| searchFilterPipe:searchFilter:nameField" [value]="item[idField]">
        {{item[nameField]}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form> -->

<form [formGroup]="formGroup">
  <div class="search-dropdown" [ngClass]="{'open':shown }">
    <div class="search-dropdown__btn less-height" (click)="show()" >
      <span [ngClass]="{'search-dropdown__btn__active':_select && select_ }">{{title}} <em class="bnx-form__aster-risk" *ngIf="asterRisk">*</em> </span>
    </div>
    <div class="search-dropdown__dropdown ">
      <div class="search-dropdown__dropdown__header">
      <div class="search-dropdown__dropdown__search">
        <input class="search-dropdown__dropdown__search__input" type="search" placeholder="Search"  [formControl]="dataFilterCtrl"  #input />
        <svg class="search-dropdown__dropdown__search__icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="612.01px" height="612.01px" viewBox="0 0 612.01 612.01" style="enable-background:new 0 0 612.01 612.01;" xml:space="preserve">
          <g>
              <g id="_x34__4_">
                  <g>
                      <path d="M606.209,578.714L448.198,423.228C489.576,378.272,515,318.817,515,253.393C514.98,113.439,399.704,0,257.493,0     C115.282,0,0.006,113.439,0.006,253.393s115.276,253.393,257.487,253.393c61.445,0,117.801-21.253,162.068-56.586     l158.624,156.099c7.729,7.614,20.277,7.614,28.006,0C613.938,598.686,613.938,586.328,606.209,578.714z M257.493,467.8     c-120.326,0-217.869-95.993-217.869-214.407S137.167,38.986,257.493,38.986c120.327,0,217.869,95.993,217.869,214.407     S377.82,467.8,257.493,467.8z"/>
                  </g>
              </g>
          </g>
        </svg>
      </div>
      <button *ngIf="isAdd" (click)="addItem()" >+</button>
     </div>
      <div class="search-dropdown__dropdown__scroller bnx-scroll-box">
        <div class="search-dropdown__dropdown__item" *ngFor="let item of data| searchFilterPipe:searchFilter:nameField" (click)="select(item,nameField)">
          {{item[nameField]}}
        </div>
        <div class="search-dropdown__dropdown__msg" *ngIf="(data| searchFilterPipe:searchFilter:nameField).length == 0 && !isloading">No Result Found</div>
        <div class="bnx-table-loader" *ngIf="isloading">
          <app-table-loader></app-table-loader>
        </div>
      </div>
    </div>
  </div>
  </form>
  <small class="error_msg" *ngIf="id==='' && errorShow">{{validationMsg}}</small>
