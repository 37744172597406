import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiEndPoints } from 'src/app/core/constants/constants';
import { HttpService } from 'src/app/core/services/common/HttpService';
import { MicrosoftAuthService } from 'src/app/shared/service/microsoft-auth.service';
@Injectable({
  providedIn: 'root'
})
export class InterviewScheduleService {

  constructor(
    private httpService : HttpService, private http: HttpClient, public microAuthService: MicrosoftAuthService
  ) { }

  getInterviewCandidateList(): Observable<any> {
    return this.httpService.get(ApiEndPoints.interviewCandidate);
  }

  getInterviewType(id:string): Observable<any>{
    return this.httpService.get(`${ApiEndPoints.interviewTypeList}/${id}`);
  }

  getInterviewScheduleList(filterArray: any,offset:any,limit:any): Observable<any>{
    return this.httpService.get(`${ApiEndPoints.interviewScheduleList}?filter=${JSON.stringify(filterArray)}&offset=${offset}&limit=${limit}`);
  }

  addInterviewScheduleDetail(data:any): Observable<any> {
    return this.httpService.post(ApiEndPoints.addInterviewScheduleDetail,data);
  }

  getInterviewDetailsById(id:string): Observable<any>{
    return this.httpService.get(`${ApiEndPoints.interviewScheduleDetail}/${id}`);
  }

  editInterviewSchedule(data:any,id:string): Observable<any> {
    return this.httpService.patch(`${ApiEndPoints.editInterviewSchedule}/${id}`,data);
  }

  deleteInterviewDetails(data:any,id:string): Observable<any>{
    return this.httpService.deleteInterview(`${ApiEndPoints.deleteInterviewDetails}/${id}?reason=${data}`);
  }
  upcommingInterviewList(filterArray: any,offset:any,limit:any): Observable<any> {
    return this.httpService.get(`${ApiEndPoints.upcommingInterviewList}?filter=${JSON.stringify(filterArray)}&offset=${offset}&limit=${limit}`);
  }

  upcommingJoineeList(filterArray: any,offset:any,limit:any): Observable<any> {
    return this.httpService.get(`${ApiEndPoints.upcommingJoineeList}?filter=${JSON.stringify(filterArray)}&offset=${offset}&limit=${limit}`);
  }


  getInterviewScheduleNotify(data:any){
    const options = {
      headers: new HttpHeaders({Authorization: `Bearer ${this.microAuthService.token}`}),
    };
    return this.http.post('https://graph.microsoft.com/v1.0/me/events', data, options);
  }


  getInterviewScheduleMeeting(data:any){
    const options = {
      headers: new HttpHeaders({Authorization: `Bearer ${this.microAuthService.token}`}),
    };
    return this.http.post('https://graph.microsoft.com/v1.0/me/onlineMeetings', data, options);
  }

  storeMeetingID(data:any, id:any){
    return this.httpService.put(`${ApiEndPoints.storeMeetingID}/${id}`,data);
  }

  editInterviewScheduleNotify(data:any, id:any){
    const options = {
      headers: new HttpHeaders({Authorization: `Bearer ${this.microAuthService.token}`}),
    };
    return this.http.patch(`https://graph.microsoft.com/v1.0/me/events/${id}`, data, options);
  }

  deleteInterviewSchedule(id:any){
    const options = {
      headers: new HttpHeaders({Authorization: `Bearer ${this.microAuthService.token}`}),
    };
    return this.http.delete(`https://graph.microsoft.com/v1.0/me/calendar/events/${id}`, options);
  }

  editInterviewStatus(data:any,id:string): Observable<any> {
    return this.httpService.put(`${ApiEndPoints.editInterviewStatus}/${id}`,data);
  }

  rejectionMail(id:string): Observable<any> {
    return this.httpService.post(`${ApiEndPoints.rejectionMail}/${id}`);
  }

}
