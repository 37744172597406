import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiEndPoints } from 'src/app/core/constants/constants';
import { HttpService } from 'src/app/core/services/common/HttpService';
@Injectable({
  providedIn: 'root'
})
export class JobApiService {

  constructor(
    private httpService : HttpService
  ) { }

  addJobDetails(data:any): Observable<any> {
    return this.httpService.post(ApiEndPoints.addJobDetails,data);
  }

  editJobDetails(data:any,id:string): Observable<any> {
    return this.httpService.patch(`${ApiEndPoints.editJobDetails}/${id}`,data);
  }

  deleteJobDetails(id:string){
    return this.httpService.delete(`${ApiEndPoints.deleteJobDetails}/${id}`);
  }

  getJobList(filterArray: any,offset:any,limit:any): Observable<any> {
    return this.httpService.get(`${ApiEndPoints.jobList}?filter=${JSON.stringify(filterArray)}&offset=${offset}&limit=${limit}`);
  }

  getJobViewDetails(id:string){
    return this.httpService.get(`${ApiEndPoints.jobViewDetails}/${id}`);
  }
}
