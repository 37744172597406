import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DemandApiService } from 'src/app/modules/user/services/demand-api.service';

@Component({
  selector: 'app-demand-details',
  templateUrl: './demand-details.component.html',
  styleUrls: ['./demand-details.component.scss']
})
export class DemandDetailsComponent {
  firstSlide:boolean = true
  secondSlide:boolean = false
  demandStatusList = [
    { status: 'Pending', color: '#ffc107' },
    { status: 'Approved', color: '#28a745' },
    { status: 'Rejected', color: '#dc3545' }
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public datas: any, private demandApiService : DemandApiService) {}
  data!: any;


  ngOnInit(): void {
    this.getDemandViewDetails();
  }

  clicKBack(){
    this.firstSlide = false
    this.secondSlide = true
  }
  clicKBack2(){
    this.firstSlide = true
    this.secondSlide = false
  }

  getDemandViewDetails(){
    let active = '';
    this.demandApiService.getDemandViewDetails(this.datas).subscribe((res) => {

      if (res[0]?.is_active === true){
        active = 'Active'
      } else {
        active = 'Inactive'
      }

      let tableColor = this.demandStatusList.filter((result: any) => {

        if (result.status === res[0]?.status) {
          return result.color;
        }
      })

      this.data = {
        department: res[0]?.department?.name,
        job_description: res[0]?.job_description,
        location: res[0]?.location?.name,
        target_date: res[0]?.start_date,
        project_name: res[0]?.project_name,
        status: {
          label: res[0].status,
          color: tableColor[0].color
        },
        experience: res[0]?.years_of_exp,
        position: res[0]?.job_position?.name,
        client_name: res[0]?.client_name,
        resources: res[0]?.no_of_resources,
        comment: res[0]?.comment,
        is_active: active,
        skills: res[0].skills,
        sub_skills: res[0]?.sub_skills
      }
    })
  }

}
