import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss']
})
export class DocumentViewerComponent {
  viewer:any;
  safeUrl:any; 
  isLoading: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DocumentViewerComponent>) {
      if (data.name?.includes('.pdf')){
        this.viewer = 'google';
      } else {
        this.viewer = 'office';
      }
    this.safeUrl = data.download || data?.download_link;
  }


  onDocumentLoad(): void {
    this.isLoading = false;
  }
  
  onDocumentError(error: any): void {
    this.isLoading = false;
    console.error('Document loading error:', error);
  }

 
}
