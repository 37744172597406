import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Directive({
  selector: '[appFiledrop]'
})
export class FiledropDirective {

  //@Input() private allowed_extensions : Array<string> = ['png', 'jpg', 'bmp'];
  @Output() private filesChangeEmiter: EventEmitter<File[]> = new EventEmitter();
  //@Output() private filesInvalidEmiter : EventEmitter<File[]> = new EventEmitter();
  @HostBinding('style.background') private background = '#F8F8F8';
  @HostBinding('style.border') private borderStyle = '1px dashed';
  @HostBinding('style.border-color') private borderColor = '#D4D8DB';
  @HostBinding('style.border-radius') private borderRadius = '5px';

  constructor(private toast:ToastrService) { }

  @HostListener('dragover', ['$event']) public onDragOver(evt: { preventDefault: () => void; stopPropagation: () => void; }) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = 'lightgray';
    this.borderColor = 'var(--buttonColor)';
    this.borderStyle = '1px solid';
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: { preventDefault: () => void; stopPropagation: () => void; }) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#FFF';
    this.borderColor = '#D4D8DB';
    this.borderStyle = '1px solid';
  }

  @HostListener('drop', ['$event']) public onDrop(evt: { preventDefault: () => void; stopPropagation: () => void; dataTransfer: { files: any; }; }) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#FFF';
    this.borderColor = '#D4D8DB';
    this.borderStyle = '1px solid';
    let files = evt.dataTransfer.files;
    let fileTypes = ['.xls','.jpg','.jpeg','.xlsx','.pdf','application/pdf','text/csv','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/vnd.ms-excel','.csv','.doc','.docx','.xml','application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document']
    let valid_files: Array<File> = files;
    let invC =true
    for (let i = 0; i < valid_files.length; i++) {
      const element = valid_files[i];
      if(!fileTypes.includes(element.type))
      {
        invC=false;
      }
    }
    if(invC)
    {
      this.filesChangeEmiter.emit(valid_files);
    }
    else{
      this.toast.warning('Invalid doc');
    }

  }
}
