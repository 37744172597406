import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { InterviewScheduleService } from 'src/app/modules/user/services/interview-schedule-api.service';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-interview-status',
  templateUrl: './interview-status.component.html',
  styleUrls: ['./interview-status.component.scss']
})
export class InterviewStatusComponent {

  interviewStatusList = [{name:'In Progress'},{name:'Pending'},{name:'Shortlist'},{name:'On hold'}, {name:'Rejected'},{name:'Completed'} ];
  status: any = null;
  panel_id: any;

  constructor(private interviewApiService: InterviewScheduleService,
    private toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<InterviewStatusComponent>) { }


  ngOnInit(): void {
    this.getInterviewStatus();
  }


  getInterviewStatus(){
    this.interviewApiService.getInterviewDetailsById(this.data?.id).subscribe((res: any) => {
      this.panel_id = res.panel_list[0]?.id;
    })

    this.interviewStatusList.find((item:any) => {
      if (item.name === this.data.status?.label) {
        this.status = item.name;
      }
    })
  }


  updateStatus(){
    if (this.status !== null){
    let payload = new FormData();
    payload.append('status', this.status);
    this.interviewApiService.editInterviewStatus(payload, this.panel_id).subscribe((result) => {
      if (result.status = 'success') {
        this.toast.success(result.message);
        this.dialogRef.close(true);
      }
    })
  } else {
    this.toast.warning('Please select a status');
  }
  }




}
