import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class MicrosoftAuthService {

  token:any = null;
  meeting_id: any;
  meeting_url:any = '';

  constructor(private msalService: MsalService, private toast: ToastrService) {}

   async signIn(){
    this.msalService.loginPopup({
      scopes: [
        'User.read',
        'calendars.readwrite',
        'Calendars.ReadWrite',
        'Directory.Read.All',
        'User.ReadBasic.All',
        'Directory.AccessAsUser.All',
      ],
    }).subscribe((response) => {
      this.token = response.accessToken;
  })

   }
}
