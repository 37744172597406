import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() { }

  public static getValidationErrorMessage(validatorName: string, validatorValue?: any, labelName?: string): any {
    var data = {
      required: labelName == "" ? 'This field is required' : labelName,
      invalidEmailAddress: `Invalid email address`,
      invalidWebsite: `Invalid URL`,
      invalidPhoneNumber: `Invalid phone number.`,
      invalidName: `Special characters not allowed`,
      minlength: `Minimum length is ${validatorValue.requiredLength}`,
      maxlength: `Maximum length is ${validatorValue.requiredLength}`,
      max: `Maximum is ${validatorValue.max}`,
      min: labelName ? labelName : `Minimum value must be ${validatorValue.min}`,
      hasWhitespace: labelName != '' ? 'This field is required' : labelName,
      invalidExperisence: `Invalid experience`,
    };
    let vName = validatorName as keyof typeof data;
    return data[vName];
  }

  // EMAIL
  static emailValidator(control: AbstractControl): any {
    let email = String(control.value);
    if (control.value == null || control.value == '') {
      return true;
    }
    if (email.match(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/)) {
      return false
    }
    return { invalidEmailAddress: true };
  }


  // WEBSITE
  static websiteValidator(control: AbstractControl): any {
    let website = String(control.value);
    if (control.value == null || control.value == '') {
      return true;
    }

    if (website.match(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi)) {
      return false
    }
    return { invalidWebsite: true };
  }


  // URL
  static urlValidator(control: AbstractControl): any {
    let url = String(control.value);
    if (control.value == null || control.value == '') {
      return true;
    }

    if (url.match('https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}')) {
      return false
    }
    return { invalidWebsite: true };
  }


  static resumeURL(control: AbstractControl): any {
    let url = String(control.value);
    if (!url || url.trim() === '') {
      return null;  // Return null if the control value is empty (optional)
    }

    if (typeof control?.value === 'string' && control?.value !== ''){
      const validUrl = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g?.test(control?.value);
      return validUrl ? null: {invalidWebsite: true};
    }
    return null;

    // const pattern: RegExp = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
    // if (pattern.test(url)) {
    //   return null;  // Valid URL, return null
    // }
    // return { invalidWebsite: true };  // Invalid URL, return error object
  }




  // PHONE NUMBER
  static phoneNumberValidator(control: AbstractControl): any {
    let phone = String(control.value);
    if (control.value == null || control.value == '') {
      return true;
    }
    //  /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/
    //  /(^\d{5,15}$)|(^\d{5}-\d{4}$)/
    if (phone.match(/(^\d{5,15}$)|(^\d{5}-\d{4}$)/)) {
      return false
    }
    return { invalidPhoneNumber: true };
  }
  // NAME
  static nameValidator(control: AbstractControl): any {
    const regex = new RegExp(/(?=.*[*.!#@$%^&(){}[:;<>,.?\'\"`\\/~_+\-=|\]])/);
    let name = String(control.value);
    if (control.value == null || control.value == '') {
      return true;
    }
    if (!name.match(regex) && !name.match(/(?=.*[0-9])/)) {
      return false;
    }

    return { invalidName: true };
  }



  // Experisence
  static experisenceValidator(control: AbstractControl): any {
    let exp = String(control.value);
    if (control.value == null || control.value == '') {
      return true;
    }
    if (exp.match(/(^\d{1,4}$)/) || exp.includes('.')) {
      return false;
    }
    return { invalidExperisence: true };
  }
}
