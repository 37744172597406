<div class="bnx-modal">
  <div class="bnx-modal__close-btn" [mat-dialog-close]="false">
    <svg width="15" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times"
      class="svg-inline--fa fa-times fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
      <path fill="currentColor"
        d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z">
      </path>
    </svg>
  </div>


  <div class="bnx-modal__title">
    <h2 class="bnx-modal__heading">{{'Change Profile Image'}}</h2>
  </div>
  <div class="bnx-view-modal__body">
    <div class="bnx-modal__wrapper">
      <div class="bnx-form__row">

        <div class="bnx-subtitle">
          <label class="bnx-form__pic--label">
            <div>{{'Please upload an image that is square and has a file size of 500 KB or less'}} </div>
          </label>
          <div class="resume__update">

            <form [formGroup]="profileFileUpload">
              <div class="bnx-dropzone" id="drop-zone" appFiledrop (files)="onFileChange($event)">
                <div class="bnx-dropzone__text-wrapper" *ngIf="!imageUrl">
                  <div class="bnx-dropzone__centered" (click)="fileInput.click()">
                    <img src="../../../../../assets/images/icons-svg/drophere.svg" alt="" >
                    <input type="file" formControlName="filUpload" accept=".png, .jpg, .jpeg" #fileInput
                      (change)="uploadFile($event)">
                    <label for="file"><span class="bnx-dropzone__textLink" >Drag and drop
                        your Image here</span></label>
                    <label for="file"><span class="bnx-dropzone__textLink">Or</span></label>
                    <label for="file"><span class="bnx-dropzone__textLink browse"> <a>Browse</a> </span></label>
                  </div>
                </div>

                  <div class="bnx-profile-upload__upload-image" *ngIf="imageUrl">
                    <img src="{{imageUrl}}" width="100%" height="300px" style="margin-bottom: -4px;">
                  </div>
              </div>
              <div class="bnx-profile-upload__error-label">
                <p class="bnx-file-data__remove" (click)="removeUploadedFile()">Reset Image</p>
                <p *ngIf="errorMsg" class="bnx-profile-upload__error">The image size should be below 500 kb</p>
              </div>

            </form>
          </div>

          <div class="submit">
            <button class="bnx-btn bnx-btn-border" [mat-dialog-close]="false">Cancel</button>
            <button class="bnx-btn" [ngClass]="{'disable-class': imageUrl === ''}"  (click)="uploadImage()">Upload</button>
          </div>
        </div>
      </div>




















    </div>
