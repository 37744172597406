<ng-container *ngIf="showContent">
    <div class="wrapper" [ngClass]="{'approved': paramData?.status == 'Approved' && !isUpdatedAlready && !isDeletedAlready ,'rejected': paramData?.status == 'Rejected' && !isUpdatedAlready && !isDeletedAlready, 'warning': isUpdatedAlready || !isDeletedAlready }">
        <div class="wrapper__beinex-icon">
            <svg width="150" height="28" viewBox="0 0 150 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M70.4741 27.281V0.00193597H75.5157C76.208 0.00189072 76.8878 0.187792 77.4838 0.540223C78.0798 0.892652 78.5702 1.39867 78.9038 2.00541L88.7762 19.9517H88.9174V3.86772C88.9172 3.35975 89.0172 2.85676 89.2115 2.38743C89.4059 1.91812 89.6907 1.49167 90.0498 1.13249C90.409 0.77331 90.8356 0.488423 91.3047 0.294104C91.7741 0.0997871 92.2771 -0.000152214 92.7851 1.7401e-07H94.6667V27.2791H87.3393L76.3647 7.29259H76.2254V27.2791H70.4741V27.281Z" fill="#04BBF2"/>
                <path d="M54.3627 16.0782H40.553C40.8798 18.4626 41.5528 19.9865 42.5681 21.0385C43.5833 22.0905 45.0879 22.6165 47.074 22.6165H56.3314V27.281H45.4959C41.8023 27.281 38.9968 26.1124 37.0798 23.7749C35.1627 21.4375 34.2041 18.0597 34.2041 13.6415C34.2041 9.22454 35.1627 5.84672 37.0798 3.50804C38.9968 1.16936 41.8023 0.000664884 45.4959 0.00195419H56.3314V0.796773C56.3316 1.30473 56.2316 1.80775 56.0372 2.27706C55.8429 2.74639 55.558 3.17282 55.1989 3.532C54.8398 3.89118 54.4132 4.17607 53.944 4.37039C53.4747 4.5647 52.9717 4.66465 52.4636 4.66449H47.074C46.4101 4.66704 45.7475 4.72593 45.0937 4.84047C44.3884 4.96497 43.722 5.2531 43.1482 5.6817C42.466 6.2199 41.9194 6.91079 41.5528 7.69872C41.0964 8.59797 40.7638 9.63839 40.553 11.2048H54.3627C54.7731 11.2048 55.1666 11.3678 55.4567 11.658C55.7468 11.9481 55.9098 12.3416 55.9098 12.7519V14.533C55.9092 14.943 55.746 15.336 55.4559 15.6257C55.1658 15.9154 54.7727 16.0782 54.3627 16.0782Z" fill="#10385B"/>
                <path d="M118.637 16.0782H104.825C105.152 18.4626 105.825 19.9865 106.842 21.0385C107.859 22.0905 109.36 22.6165 111.348 22.6165H120.605V27.281H109.77C106.076 27.281 103.272 26.1124 101.356 23.7749C99.4398 21.4375 98.4813 18.0597 98.48 13.6415C98.48 9.22454 99.4386 5.84672 101.356 3.50804C103.273 1.16936 106.078 0.000664884 109.77 0.00195419H120.605V0.796773C120.605 1.82256 120.198 2.80632 119.472 3.53167C118.747 4.25701 117.763 4.66449 116.738 4.66449H111.348C110.684 4.66704 110.021 4.72593 109.368 4.84047C108.662 4.96497 107.996 5.2531 107.422 5.6817C106.813 6.12649 106.281 6.79884 105.827 7.69872C105.37 8.59797 105.038 9.63839 104.827 11.2048H118.639C119.049 11.2048 119.442 11.3678 119.733 11.658C120.023 11.9481 120.186 12.3416 120.186 12.7519V14.533C120.184 14.9431 120.021 15.3358 119.73 15.6254C119.44 15.915 119.047 16.0778 118.637 16.0782Z" fill="#10385B"/>
                <path d="M60.6924 27.281V1.93581C60.6924 1.42292 60.8962 0.931038 61.2588 0.568367C61.6214 0.205698 62.1134 0.00195312 62.6262 0.00195312H64.5098C65.0227 0.00195312 65.5147 0.205698 65.8773 0.568367C66.2399 0.931038 66.4437 1.42292 66.4437 1.93581V27.281H60.6924Z" fill="#04BBF2"/>
                <path d="M132.516 1.81592L137.271 9.39859L142.025 1.81592C142.373 1.26087 142.856 0.803295 143.43 0.486033C144.003 0.168772 144.647 0.00221349 145.303 0.00195319H150L140.182 13.6415L150 27.281H143.164L137.273 17.8844L131.383 27.281H124.544L134.326 13.6415L124.544 0.00195319H129.242C129.897 0.00183484 130.541 0.168264 131.113 0.485592C131.686 0.802917 132.169 1.26071 132.516 1.81592Z" fill="#10385B"/>
                <path d="M29.6055 16.1149C28.7517 14.9695 27.5722 14.1081 26.2213 13.6434C27.6697 13.013 28.7391 12.1015 29.4295 10.909C30.1364 9.64674 30.4935 8.21876 30.4641 6.77242C30.4874 5.74869 30.252 4.73566 29.7796 3.82715C29.3348 2.99957 28.7119 2.28097 27.9559 1.72311C27.1714 1.14961 26.2932 0.716462 25.3607 0.442892C24.3999 0.151961 23.4017 0.0040482 22.398 0.00390625H2.33224C1.71371 0.0039575 1.12052 0.249691 0.683155 0.68706C0.245784 1.12443 5.12816e-05 1.71761 0 2.33614V4.4924H20.6111C21.9435 4.4924 22.9312 4.79665 23.5738 5.40518C24.2164 6.01371 24.5374 6.85494 24.5369 7.92887C24.5369 9.0041 24.2158 9.85177 23.5738 10.4719C22.9292 11.0907 21.9416 11.4002 20.6111 11.4002H11.6921V15.8887H20.6111C21.9435 15.8887 22.9312 16.1929 23.5738 16.8014C24.2164 17.41 24.5374 18.2512 24.5369 19.3251C24.5369 20.4236 24.2158 21.277 23.5738 21.8856C22.9292 22.4947 21.9416 22.7989 20.6111 22.7983H11.7018V27.2868H22.3999C23.4551 27.2894 24.5075 27.1778 25.5386 26.9542C26.5067 26.7531 27.4247 26.3599 28.2383 25.7978C29.0375 25.2292 29.6823 24.47 30.1141 23.5893C30.5821 22.6649 30.8161 21.5021 30.8161 20.1006C30.8161 18.5535 30.4125 17.225 29.6055 16.1149Z" fill="#10385B"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.21969 5.78906H0V10.2776H4.21969V5.78906ZM5.57339 10.2776V5.78906H10.2901V10.2776H5.57339Z" fill="#04BBF2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.21969 11.3984H0V15.8869H4.21969V11.3984ZM5.57339 15.8869V11.3984H10.2901V15.8869H5.57339Z" fill="#04BBF2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.21969 17.0938H0V21.5823H4.21969V17.0938ZM5.57339 21.5823V17.0938H10.2901V21.5823H5.57339Z" fill="#04BBF2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.21969 22.793H0V27.2815H4.21969V22.793ZM5.57339 27.2815V22.793H10.2901V27.2815H5.57339Z" fill="#10385B"/>
                </svg>
        </div>
        <div class="wrapper__icon-content">
            <div>
                <img *ngIf="paramData?.status == 'Approved' && !isUpdatedAlready && !isDeletedAlready" src="/assets/images/Approved-Animated.svg" alt="Approved-Image">
                <img *ngIf="paramData?.status == 'Rejected' && !isUpdatedAlready && !isDeletedAlready" src="/assets/images/Rejected-Animated.svg" alt="Rejected-Image">
                <img *ngIf="isUpdatedAlready" src="/assets/images/Warning-Animated.svg" alt="Warning-Image">
                <img *ngIf="isDeletedAlready" src="/assets/images/Warning-Animated.svg" alt="Warning-Image">
            </div>
            <div>
                <h2 *ngIf="paramData?.status == 'Approved' && !isUpdatedAlready && !isDeletedAlready">Approved Successfully</h2>
                <h2 *ngIf="paramData?.status == 'Rejected' && !isUpdatedAlready && !isDeletedAlready">Rejected Successfully</h2>
                <h2 *ngIf="isUpdatedAlready">Job Demand Status already Updated</h2>
                <h2 *ngIf="isDeletedAlready">Job Demand is not listed or Deleted</h2>
            </div>
        </div>
    </div>
</ng-container>
