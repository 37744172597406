import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { loginUrls } from '../constants/constants';
import { FunctionHelperService } from '../helper/function.helper.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private _router: Router,
    private _helper: FunctionHelperService) { }

    canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this._helper.isLogged && (this._helper.userData?.user_type!=="ADMIN" || this._helper.userData?.user_type!=="APPROVER") && !state.url.includes('user-manage') && !state.url.includes('user-log') && !state.url.includes('roles-privileges')) {
          return true;
        }else if(this._helper.isLogged && (this._helper.userData?.user_type =="ADMIN" || this._helper.userData?.user_type!=="APPROVER" ) && state.url.includes('user-manage')){
          return true;
        } else {
          this._router.navigate([loginUrls.login]);
          return false;
        }
    }

}
