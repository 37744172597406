import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiStatus, modalData } from 'src/app/core/constants/constants';
import { CommonService } from 'src/app/shared/service/common.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared/widget/confirm-dialog/confirm-dialog.component';
import { CommonModalComponent } from 'src/app/shared/widget/modals/common-modal/common-modal.component';
import { GeneralSettingsService } from '../../../services/general-settings.service';
import { catchError, finalize, of } from 'rxjs';
import { TableOption, editDelete } from 'src/app/core/interface/table';
import { AddNewSettingDetailsComponent } from '../modals/add-new-setting-details/add-new-setting-details.component';


@Component({
  selector: 'app-list-settings',
  templateUrl: './list-settings.component.html',
  styleUrls: ['./list-settings.component.scss'],
})
export class ListSettingsComponent implements OnInit {
  searchClear!: boolean;
  listSettingsData: any = modalData;
  settingDetail: any;
  page = 1;
  tableLimit = 10;
  totalPages!: number;
  isLoading = false;
  tableList: any[] = [];
  tableKey: any;
  tableTitle: any = ['id', 'Name', ''];
  tableEditDelele: editDelete = {
    edit: false,
    delete: false,
  };

  actionsList: TableOption = {
    viewMode: false,
    optionMode: true,
    infoMode: false,
    optionList: [
      {
        id: 'id_edit_user',
        title: 'Edit',
        svg: 'assets/images/icons-svg/edit-action.svg',
      },
      {
        id: 'id_delete_user',
        title: 'Delete',
        svg: 'assets/images/icons-svg/delete.svg',
      },
    ],
  };

  filterArray: any = {
    "q": '',
  }


  constructor(
    private activeRoute: ActivatedRoute,
    private settingService: GeneralSettingsService,
    public dialog: MatDialog,
    public commonService: CommonService,
    private toast: ToastrService
  ) {}
  ngOnInit() {
    this.getSettingDetails();
  }

  getSettingDetails() {
    let route;
    this.activeRoute.params.subscribe((res) => {
      route = res['id'];
      this.settingDetail = this.listSettingsData[route];

      if (this.settingDetail) {
        window.scroll(0, 0);
        this.commonService.setId('count_reset');
        this.page = 1;
        this.totalPages = 1;
        this.getAllList('');
      }

    });
  }

  getAllList(filterArray:any) {
    this.isLoading = true;
    if (filterArray) {
      this.filterArray = filterArray;
    } else {
      filterArray = {
        "q": ''
      }
    }
    this.settingService
      .getList(
        this.settingDetail.listEndPoint, filterArray,
        (this.page - 1) * this.tableLimit,
        this.tableLimit
      ).subscribe((res: any) => {
        this.isLoading = false;
        if (ApiStatus.ok) {
          this.totalPages =
            res.count % this.tableLimit == 0
              ? Math.trunc(res.count / this.tableLimit)
              : Math.trunc(res.count / this.tableLimit) + 1;

          this.tableList = res.results;
          this.tableKey = Object.keys(this.tableList[0]);
          this.searchClear = false;
          this.tableTitle = [
            'Id',
            ...this.settingDetail?.formfields?.map((e: any) => {
              return e.title;
            }),
            '',
          ];
        }
      });
  }

  getChange(env: any) {
    switch (env.action) {
      case 'edit':
        this.updateList(env);
        break;
      case 'delete':
        this.confirmDialog(env);
        break;
      case 'pageLimit':
        this.tableLimit = env.pageLimit;
        this.getAllList(this.filterArray);
        break;
      case 'page':
        this.page = env.page;
        this.getAllList(this.filterArray);
        break;

    }
  }

  openCommonModal(updateId?: any) {
    let dialogRef = this.dialog.open(AddNewSettingDetailsComponent, {
      width: this.settingDetail.modalWidth,
      panelClass: 'helooooooooooooo',
      data: {
        modal: this.settingDetail,
        list: this.tableList,
        populateField: undefined,
        id: updateId,
        position: { right: '100px', top: '10px' }
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((res) => {
      this.getSettingDetails();

    });

    const parentClass = document.getElementsByClassName('cdk-global-overlay-wrapper')
    console.log('parentClass', parentClass);
    parentClass?.[0]?.classList.add('add-new-settings')
  }

  updateList(env: any): void {
    if (this.settingDetail.id !== 'addTemplate') {
      this.settingDetail.formfields.forEach((item: any) => {
        this.settingDetail.formControls[item.field]?.patchValue(env.row[item.field])
      })

      this.settingDetail.submit = "Update";
      this.settingDetail.title = this.settingDetail.title.replace("Add", "Edit");
      let updateId = env.row.id;
      this.openCommonModal(updateId);
    }
    else if (this.settingDetail.id === 'addTemplate') {
      this.openCommonModal(env.row.id);
    }
  }

  deleteList(env: any): void {
    this.commonService.genericDelete(this.settingDetail.deleteEndPoint, env.row.id).pipe(
      catchError((err) => {
        this.toast.warning(err.error.message);
        return of(false)
      })
    )
      .subscribe((res: any) => {
        if (res.status === 'success') {
          this.toast.success(res.message);
          this.getSettingDetails();
        }
      }, (error: any) => {
        this.toast.error(error.message);
      })
  }

  confirmDialog(data: any): void {
    const message = `Do you really wish to proceed with the deletion of this record?`;
    const dialogData = new ConfirmDialogModel("Confirm Action", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "500px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.deleteList(data);
      }
    });
  }

  changeTab(label: string) {
    if (label !== this.settingDetail.id) {
      this.searchClear = true;
      this.filterArray = {
        "q": ''
      }
    }
  }

  getFilteredChange(env: any) {
    this.tableLimit = 10;
    this.page = 1;
    switch (env.type) {
      case 'search':
        this.getAllList(env.filterData);
        break;
      default:
        break;
    }

  }
}
