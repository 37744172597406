<div class="gnl-modal">
  <a href="javascript:void(0)" class="gnl-modal__close" (click)="closeModal()">
      <em class="bnx-icon bnx-icon--plus"></em>
  </a>
      <ng-container
      *ngIf="data.modal.id == 'addDepartment' || data.modal.id == 'addLocation' || data.modal.id == 'addSkill'">
      <div class="gnl-modal__wrapper bnx-scroll-box-150">
          <div class="gnl-modal__head">{{data.modal.title}}</div>
          <img src="../../../../../../../assets/images/add-skill-img.png" alt="" class="gnl-modal__img" />
          <form [formGroup]="commonForm">
              <div class="bnx-form__multi-input" *ngFor="let form of data.modal.formfields">
                <div class="bnx-form__group">
                      <input class="bnx-form__input" [formControlName]="form.field" [placeholder]="form.title" [type]="form.type"  (keydown)="onKeyDown($event)"/>
                      <div class="bnx-form__label">{{form.title}} <span class="bnx-form__aster-risk">*</span></div>
                      <error-msg [control]="commonForm.controls[form.field]"></error-msg>
                  </div>
              </div>
              <div class="gnl-modal__btns-wrap">
                  <button class="bnx-btn" type="submit" [ngClass]="{'disable':loading}" class="bnx-btn"
                      (click)="submit()">{{loading?
                      'Adding...': data.modal.submit}}
                  </button>
                  <button (click)="closeModal()" class="bnx-btn bnx-btn-border" [mat-dialog-close]="false">
                      Cancel
                  </button>
              </div>
          </form>
          <div class="gnl-modal__skill-list-outer bnx-scroll-box" *ngIf="this.data.modal.submit != 'Update' || this.data.modal.modalUpdate">
              <div class="gnl-modal__skill-head">
                  <!-- Skills added -->
              </div>
              <div class="gnl-modal__skill-wrapper">
                  <ng-container *ngFor="let item of settingsListItem ;let i = index">
                      <div class="gnl-modal__tile">
                          <div class="gnl-modal__tile-inner">
                              <div class="gnl-modal__id-sec" *ngFor=" let key of data.modal.tableKey">
                                  <div class="gnl-modal__tile-head">
                                      {{key.label}}
                                  </div>
                                  <div class="gnl-modal__bold-txt">
                                      {{item[key.key]}}
                                  </div>
                              </div>
                          </div>
                          <div class="gnl-modal__tile-btns">
                              <a class="gnl-modal__btn" (click)="getUpdate(item)">
                                  <em class="bnx-icon bnx-icon--edit"></em>
                              </a>
                              <a class="gnl-modal__btn" (click)="confirmDialog(item)">
                                  <em class="bnx-icon bnx-icon--trash"></em>
                              </a>
                          </div>
                      </div>
                  </ng-container>
              </div>
          </div>
      </div>
  </ng-container>
  <!-- skill-end -->
  <!-- job-start -->
  <ng-container *ngIf="data.modal.id == 'addJobtitle'">
      <div class="gnl-modal__wrapper bnx-scroll-box-150">
          <div class="gnl-modal__head">{{data.modal.title}}</div>
              <form [formGroup]="commonForm">
          <div class="bnx-form__group">
              <input class="bnx-form__input" [formControlName]="'name'" placeholder="Job Title" [type]="'text'" #input  (keydown)="onKeyDown($event)"/>
              <div class="bnx-form__label">Job Title <span class="bnx-form__aster-risk">*</span></div>
              <error-msg [control]="commonForm.controls[data.modal.formfields[0].field]"></error-msg>
          </div>
          <div class="bnx-form__counter-outer">
              <div class="bnx-form__counter-name">Number of interviews rounds</div>
              <div class="bnx-form__counter-wrap">
                  <a class="bnx-form__counter-count" (click)="setLevelCount('decrement')"><em
                          class="bnx-icon bnx-icon--substract"></em></a>
                  <input type="number" class="bnx-form__input"  [formControlName]="'levelCount'" [type]="'number'" #input (change)="setLevelCount('input')" />
                  <a  class="bnx-form__counter-count" (click)="setLevelCount('increment')"><em
                          class="bnx-icon bnx-icon--plus"></em></a>
              </div>
          </div>
          <div class="gnl-modal__dropdown-list">
              <ng-container formArrayName="levels">
              <div class="gnl-modal__dropdown" *ngFor="let level of getLevels().controls; let i=index">
                  <ng-container [formGroupName]="i">
                  <div class="gnl-modal__dropdown-inner"  >
                      <div class="gnl-modal__dropdown-version">L{{i+1}}</div>
                      <div class="bnx-form__group required">
                          <ng-select [dropdownPosition]="'bottom'" class="recNgclass"  placeholder="Select Email Template" [items]="levelRoundList" bindLabel="title" (change)="setTemplateNote(i,level)"
                          bindValue="id"  formControlName="interview_round" ></ng-select>
                          <error-msg [control]="level.get('interview_round')"></error-msg>
                      </div>
                  </div>
                  <textarea  cols="30" rows="10" class="bnx-form__text-area bnx-form--text-area2"  formControlName="levelNaration" [readonly]="true" [ngClass]="{'gnl-modal__hide':step!=i}"></textarea>
                  <!-- <a hidden   class="gnl-modal__email-link" [ngClass]="{'gnl-modal__hide':step!=i}"><em class="bnx-icon bnx-icon--edit" hidden></em>
                      Edit template</a> -->
              </ng-container>
              </div>
          </ng-container>
             </div>
          </form>
          <div class="gnl-modal__btns-wrap  border-top">
              <a (click)=" submit()" class="bnx-btn">
                  {{loading?
                      'Adding...': data.modal.submit}}
              </a>
              <a  class="bnx-btn bnx-btn-border" (click)="closeModal()">
                  Cancel
              </a>
          </div>
      </div>
  </ng-container>

  <!-- Email template -->

  <ng-container *ngIf="data.modal.id == 'addTemplate'">
    <div class="gnl-modal__wrapper bnx-scroll-box-150">
      <div class="gnl-modal__head">{{data.modal?.title}}</div>
      <img src="../../../../../../../assets/images/Group 15948.svg" alt="" class="gnl-modal__img" />

      <form [formGroup]="templateForm">
        <div class="bnx-form__multi-input" >
            <div class="bnx-form__group">
              <input class="bnx-form__input" formControlName="title" type="text" (keydown)="onKeyDown($event)"/>
              <div class="bnx-form__label">{{'Template title'}} <span class="bnx-form__aster-risk">*</span></div>
              <error-msg [control]="templateForm.controls['title']"></error-msg>
            </div>

            <div class="bnx-form__group">
              <textarea class="bnx-form__text-area" placeholder="Type message here.." formControlName="content" name="" id="" cols="30"rows="10"></textarea>
              <error-msg [control]="templateForm.controls['content']"></error-msg>
            </div>
          </div>


          <div class="gnl-modal__btns-wrap">
            <button class="bnx-btn" type="submit" [ngClass]="{'disable':loading}" class="bnx-btn"
                (click)="submit()">{{loading?
                'Adding...': data.modal.submit}}
            </button>
            <button (click)="closeModal()" class="bnx-btn bnx-btn-border" [mat-dialog-close]="false">
                Cancel
            </button>
        </div>

      </form>

    </div>

  </ng-container>


</div>
