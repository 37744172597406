import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import { ValidationService } from 'src/app/core/services/common/validation.service';
import { CandidateMasterService } from 'src/app/modules/user/services/candidate-master.service';

@Component({
  selector: 'app-candidate-assignment',
  templateUrl: './candidate-assignment.component.html',
  styleUrls: ['./candidate-assignment.component.scss']
})
export class CandidateAssignmentComponent {

  candidateAssignmentForm!: FormGroup;
  candidateAssignmentSubmitForm!: FormGroup;
  fileArray: any[] = [];
  deletedFiles: any[] = [];
  assignArray: any[] = [];
  deletedAssign: any[] = [];
  isLoading = false;
  isSubmit:boolean = false;

  constructor(private formBuilder: FormBuilder, private toast: ToastrService,
    public dialogRef: MatDialogRef<CandidateAssignmentComponent>,
    @Inject(MAT_DIALOG_DATA) public panelData: any,
    private candidateApiService: CandidateMasterService) { }

  ngOnInit(): void {
    this.initForm();
    if(this.panelData.status === 'Pending'){
      this.isSubmit = true;
      this.submitForm();
    }
  }


  initForm(): void {
    this.candidateAssignmentForm = this.formBuilder.group({
      id: new FormControl(''),
      title: new FormControl(null, Validators.required),
      description: new FormControl(null, Validators.required),
      document: new FormControl(''),
      outputURL: new FormControl('')
    })
  }

  submitForm(): void {
    this.candidateAssignmentSubmitForm = this.formBuilder.group({
      outputURL: new FormControl(''),
      submit_description: new FormControl(null, Validators.required),
      assignments: new FormControl(''),
    })
  }


  onFileChange(env: any, type: string) {
    let files: any[] = [];
    switch (type) {
      case 'drop':
        files = env;
        break;

      case 'click':
        files = env.files
        break;

      default:
        break;
    }

    let fileTypes = ['.xls', '.xlsx', 'application/pdf', '.pdf', 'text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', '.csv', '.doc', '.docx', '.xml', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
    let invC = true
    for (let i = 0; i < files.length; i++) {
      const element = files[i];
      if (!fileTypes.includes(element.type)) {
        invC = false;
      }
    }
    if (!invC) {
      this.toast.warning('Invalid doc');
      return;
    }

    this.fileArray = [...this.fileArray, ...files];
    this.candidateAssignmentForm.controls['document'].setValue(this.fileArray.filter(e => { return e?.id != undefined }));
  }

  removeFile(i: number) {
    if (this.fileArray[i]?.id) {
      this.deletedFiles.push(this.fileArray[i]?.id);
    }
    this.fileArray.splice(i, 1);
  }


  onFileChangeAssign(env: any, type: string) {
    let files: any[] = [];
    switch (type) {
      case 'drop':
        files = env;
        break;

      case 'click':
        files = env.files
        break;

      default:
        break;
    }

    let fileTypes = ['.xls', '.xlsx', 'application/pdf', '.pdf', 'text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', '.csv', '.doc', '.docx', '.xml', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
    let invC = true
    for (let i = 0; i < files.length; i++) {
      const element = files[i];
      if (!fileTypes.includes(element.type)) {
        invC = false;
      }
    }
    if (!invC) {
      this.toast.warning('Invalid doc');
      return;
    }

    this.assignArray = [...this.assignArray, ...files];
    this.candidateAssignmentSubmitForm.controls['assignments'].setValue(this.assignArray.filter(e => { return e?.id != undefined }));
  }

  removeAssignment(i: number) {
    if (this.assignArray[i]?.id) {
      this.deletedAssign.push(this.assignArray[i]?.id);
    }
    this.assignArray.splice(i, 1);
  }


  submit() {
    if (!this.isSubmit){
    this.candidateAssignmentForm.markAllAsTouched();
      if (this.candidateAssignmentForm.valid) {
        if (this.candidateAssignmentForm.value.document === '') {
          this.toast.error('Document is required');
          return;
        }

        this.isLoading = true;
        let formData = new FormData();
        let c_data = this.candidateAssignmentForm.value;
        const candidateId = this.panelData.candidateId;
        const data = {
          "candidate": candidateId,
          "title": c_data.title,
          "description": c_data.description
        }

        formData.append('data', JSON.stringify(data));

        for (let i = 0; i < this.fileArray.filter(e => { return e?.id == undefined }).length; i++) {
          const element = this.fileArray.filter(e => { return e?.id == undefined })[i];
          formData.append(`document[${i}]`, element)
        }

        this.candidateApiService.candidateAssignment(formData).pipe(finalize(() => {
          this.isLoading = false;
        })).subscribe((res: any) => {
          if (res.status = 'success') {
            this.toast.success(res.message);
            this.dialogRef.close(true);
          }
        })

      }
    } else {
      this.submitAssignment();
    }
  }

  submitAssignment() {
    this.candidateAssignmentSubmitForm.markAllAsTouched();
    if (this.candidateAssignmentSubmitForm.valid) {
      if (this.candidateAssignmentSubmitForm.value.assignments === '') {
        this.toast.error('Document is required');
        return;
      }
      this.isLoading = true;
      let formData = new FormData();
      let c_data = this.candidateAssignmentSubmitForm.value;

      const assignmentId = this.panelData.id;

      const data = {
        "assignment": assignmentId,
        "output_url": c_data.outputURL,
        "description": c_data.submit_description
      }

      formData.append('data', JSON.stringify(data));

      for (let i = 0; i < this.assignArray.filter(e => { return e?.id == undefined }).length; i++) {
        const element = this.assignArray.filter(e => { return e?.id == undefined })[i];
        formData.append(`document[${i}]`, element)
      }

      this.candidateApiService.submitAssignment(formData).pipe(finalize(() => {
        this.isLoading = false;
      })).subscribe((res: any) => {
        if (res.status = 'success') {
          this.toast.success(res.message);
          this.dialogRef.close(true);
        }
      })

    }
  }

}
