<div class="bnx-main-header">
  <!-- <div class="bnx-main-header__title">
    <h3>Welcome to Beinex Recruitment Portal!</h3>
  </div> -->

  <div class="bnx-main-header__right-sec">
    <div class="bnx-form__group">
      <div class="bnx-currency-item">
        <ng-select
          [items]="yearList"
          [(ngModel)]="currentYear"
          placeholder="Select year"
          (change)="changeYear($event)"
          [searchable]="false"
          [clearable]="false"
        ></ng-select>
      </div>
    </div>
  </div>
</div>

<!-- BNX SHORT DECS -->
<div class="bnx-home">
  <div class="bnx-home__outer">
     <!-- RECRUITMENT BY MONTH -->
     <div class="bnx-recruitment" >
      <div class="bnx-common__heading-wrp">
        <div class="bnx-common__subhead">Month-wise Recruitment Picture</div>
        <div class="bnx-common__btns">
          <div class="bnx-form__group">
            <!-- <div class="bnx-currency-item__month">
              <ng-select
                [items]="monthList"
                [(ngModel)]="currentMonth"
                placeholder="All"
                bindLabel="value"
                [searchable]="false"
                (change)="changeMonth($event)"
              ></ng-select>
            </div> -->
          </div>
        </div>
      </div>
      <div class="bnx-recruitment__chart">
        <app-recruitment-chart [isLoading]="isRecChartLoading"[selectedYear]="currentYear" (setLoading)="setLoadingChange($event)"
        ></app-recruitment-chart>
      </div>
      <div class="bnx-table-loader" *ngIf="isRecChartLoading">
        <app-table-loader></app-table-loader>
      </div>
    </div>

    <!-- RECRUITMENT BY MONTH -->
    <div class="bnx-home__wrapper" style="background-image: url(../../../../../assets/images/recuitment-bg.png);">
      <div class="bnx-home__row">


        <div class="bnx-home__custom">
          <div class="bnx-home__content">
            <div class="bnx-home__content-head">
              <h4>Recruitment Overview</h4>
              <div class="bnx-currency-item__month">
                <ng-select
                  [items]="monthList"
                  [(ngModel)]="currentMonth"
                  placeholder="All"
                  bindLabel="value"
                  [searchable]="false"
                  (change)="changeMonth($event)"
                ></ng-select>
              </div>
            </div>

            <p>
              Know how many candidates have registered to join the portal, how
              many have been shortlisted as well as rejected
            </p>
          </div>

          <div class="bnx-home-wrapper__row">
            <!-- DETAILS -->
            <div class="bnx-home-wrapper__div">
              <div class="bnx-home-wrapper__card">
                <div class="bnx-home-wrapper__card-body">
                  <div class="bnx-home-wrapper__card-details">
                    <h2>{{ deapartmentCards?.job_openings ?? 0}}</h2>
                    <h4>Job Openings</h4>
                   </div>
                  <div class="bnx-home-wrapper__head">
                    <div>

                      <svg width="40" height="40" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_b_9481_4138)">
                        <circle cx="18" cy="18" r="18" fill="#04BBF2"/>
                        </g>
                        <path d="M18.0322 13.2476C19.5483 13.2334 20.9411 13.3235 22.1781 13.4656C23.7387 13.6449 24.9905 14.8389 25.1742 16.399C25.2734 17.2413 25.3333 18.1504 25.3333 19.1156C25.3333 20.0994 25.2761 21.0241 25.1809 21.8798C24.9996 23.5109 23.663 24.7393 22.028 24.8818C20.8357 24.9857 19.5325 25.0474 18.1356 25.0337C16.8892 25.027 15.7195 24.9626 14.6381 24.8658C13.0582 24.7243 11.737 23.5759 11.521 22.0045C11.4177 21.2533 11.35 20.4421 11.337 19.5776C11.3147 18.4259 11.3937 17.3599 11.5287 16.3955C11.7472 14.8347 13.0457 13.6807 14.6105 13.4927C15.7439 13.3565 16.9041 13.2595 18.0322 13.2476Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M15.0176 13.0013V12.0188C15.0176 11.7583 15.1211 11.5084 15.3053 11.3241C15.8595 10.77 17.6482 10.668 18.3334 10.668C19.1304 10.668 20.9485 10.6656 21.607 11.3241C21.7913 11.5084 21.8948 11.7583 21.8948 12.0188V13.0013" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M23.1226 18.0352H14.2805" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M19.9999 18.0195V19.0195C19.9999 19.1079 19.9824 19.1927 19.9511 19.2552C19.9198 19.3177 19.8775 19.3529 19.8333 19.3529H17.4999C17.4557 19.3529 17.4133 19.3177 17.3821 19.2552C17.3508 19.1927 17.3333 19.1079 17.3333 19.0195V18.0195" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <defs>
                        <filter id="filter0_b_9481_4138" x="-60" y="-60" width="156" height="156" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="30"/>
                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_9481_4138"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_9481_4138" result="shape"/>
                        </filter>
                        </defs>
                        </svg>

                    </div>
                  </div>


                </div>
              </div>
            </div>
            <!-- DETAILS -->

            <!-- DETAILS -->
            <div class="bnx-home-wrapper__div">
              <div class="bnx-home-wrapper__card">
                <div class="bnx-home-wrapper__card-body">
                  <div class="bnx-home-wrapper__card-details">
                    <h2>{{ deapartmentCards?.candidate ?? 0}}</h2>
                    <h4>Candidates</h4>

                  </div>
                  <div class="bnx-home-wrapper__head">
                    <div>
                      <svg width="40" height="40" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_b_9481_4148)">
                        <circle cx="18" cy="18" r="18" fill="#04BBF2"/>
                        </g>
                        <path d="M20.8249 18.4916C22.0986 18.4916 23.1312 17.459 23.1312 16.1852C23.1312 14.9115 22.0986 13.8789 20.8249 13.8789C19.5511 13.8789 18.5186 14.9115 18.5186 16.1852C18.5186 17.459 19.5511 18.4916 20.8249 18.4916Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M15.6497 25.2767C15.6497 22.4079 17.956 20.1016 20.8248 20.1016C23.6937 20.1016 26 22.4079 26 25.2767" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M13.9381 14.8197C14.9012 14.8197 15.682 14.0389 15.682 13.0758C15.682 12.1128 14.9012 11.332 13.9381 11.332C12.9751 11.332 12.1943 12.1128 12.1943 13.0758C12.1943 14.0389 12.9751 14.8197 13.9381 14.8197Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10 20.3673C10 18.1735 11.7438 16.4297 13.9376 16.4297C14.5002 16.4297 15.0064 16.5422 15.4564 16.7109" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <defs>
                        <filter id="filter0_b_9481_4148" x="-60" y="-60" width="156" height="156" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="30"/>
                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_9481_4148"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_9481_4148" result="shape"/>
                        </filter>
                        </defs>
                        </svg>

                    </div>
                  </div>

                </div>
              </div>
            </div>
            <!-- DETAILS -->

            <!-- DETAILS -->
            <div class="bnx-home-wrapper__div">
              <div class="bnx-home-wrapper__card">
                <div class="bnx-home-wrapper__card-body">
                  <div class="bnx-home-wrapper__card-details">
                    <h2>{{ deapartmentCards?.shortlisted ?? 0 }}</h2>
                    <h4>Shortlisted</h4>

                  </div>
                  <div class="bnx-home-wrapper__head">
                    <div class="round-progress-card">
                      <div class="round-progress-card__percent">
                        <svg>
                          <circle cx="29" cy="29" r="25"></circle>
                          <circle
                          cx="29" cy="29" r="25"
                            style="--percent: {{
                              deapartmentCards?.shortlisted ?? 0
                            }}"
                          ></circle>
                        </svg>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <!-- DETAILS -->
            <!-- DETAILS -->
            <div class="bnx-home-wrapper__div">
              <div class="bnx-home-wrapper__card">
                <div class="bnx-home-wrapper__card-body">
                  <div class="bnx-home-wrapper__card-details">
                    <h2>{{ deapartmentCards?.rejected ?? 0 }}</h2>
                    <h4>Rejected</h4>

                  </div>
                  <div class="bnx-home-wrapper__head">
                    <div class="round-progress-card">
                      <div class="round-progress-card__percent">
                        <svg>
                          <circle cx="29" cy="29" r="25"></circle>
                          <circle
                          cx="29" cy="29" r="25"
                            style="--percent: {{
                              deapartmentCards?.rejected ?? 0
                            }}"
                          ></circle>
                        </svg>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- DETAILS -->
          </div>
        </div>
        <!-- -->
      </div>
    </div>
    <!-- BNX SHORT DECS -->


  </div>


  <!-- SOURCE AND HIRING -->
  <div class="bnx-wrapper">
    <div class="bnx-wrapper__row">
      <div class="bnx-wrapper__source">
        <app-source-month [selectedYear]="currentYear" [isLoading]="isSouChartLoading" 
        (setLoading)="setSourceLoadingChange($event)"></app-source-month>
      </div>
      <div class="bnx-table-sou-loader" *ngIf="isSouChartLoading">
        <app-table-loader></app-table-loader>
      </div>
      <div class="bnx-wrapper__hiring">
        <app-department-hiring
          [selectedYear]="currentYear"
        ></app-department-hiring>
      </div>
    </div>
  </div>
<hr class="bnx-wrapper__line">
  <!-- SOURCE AND HIRING -->

  <!-- BNX TABLE TABE -->
  <div class="bnx-table-tab">
    <div class="common-mat-tab">
      <mat-tab-group
        class="common-mat-tab"
        animationDuration="0ms"
        class="bnx-mat-tab"
        mat-stretch-tabs="false"
        mat-align-tabs="start"
      >
        <mat-tab label="Open Jobs"
          ><div><app-opening-jobs></app-opening-jobs></div
        ></mat-tab>
        <mat-tab label="Upcoming Joinees">
          <div><app-upcomming-joinees></app-upcomming-joinees></div>
        </mat-tab>
        <mat-tab label="Upcoming Interviews">
          <div><app-upcomming-interviews></app-upcomming-interviews></div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <!-- BNX TABLE TABE -->
</div>
