<div class="bnx-modal">
  <div class="bnx-modal__close-btn" [mat-dialog-close]="false">
    <svg width="15" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times"
      class="svg-inline--fa fa-times fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
      <path fill="currentColor"
        d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z">
      </path>
    </svg>
  </div>

  <div class="bnx-modal__title">
    <h2 class="bnx-modal__heading">{{data.modal.title}}</h2>
  </div>

  <form  [formGroup]="commonForm">
    <div class="bnx-view-modal__body modal_scroll bnx-scroll-box">
      <div class="bnx-modal__wrapper">
          <div class="bnx-form__col__modal" *ngFor="let form of data.modal.formfields"
            [ngClass]="data.modal.formfields.length===1 ?'bnx-form__single':'bnx-form__col'">

            <div *ngIf="!form.mode" class="bnx-form__group">
              <input class="bnx-form__input" [formControlName]="form.field" [type]="form.type" />
              <div class="bnx-form__label">{{form.title}}</div>
              <error-msg [control]="commonForm.controls[form.field]"></error-msg>
            </div>

            <div *ngIf="form.mode" class="bnx-form__group">
              <mat-form-field class="form-field">
              </mat-form-field>
              <error-msg [control]="commonForm.controls[form.field]"></error-msg>
            </div>
        </div>
        <div class="submit">
          <button type="submit" [ngClass]="{'disable':loading}" class="bnx-btn" (click)="submit()">{{loading?
            'Adding...': data.modal.submit}}</button>
          <button class="bnx-btn bnx-btn-border" (click)="clearForm()">Cancel</button>
        </div>
      </div>
    </div>
  </form>
</div>
