import { Component } from '@angular/core';
import { FunctionHelperService } from 'src/app/core/helper/function.helper.service';

@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent {
constructor(private helper:FunctionHelperService,){}
  sideNavStatus:boolean = false;
  loggedUserType:any;
  ngOnInit() {
    this.loggedUserType = this.helper.userData?.user_type;
  }

}
