<div class="bnx-modal">
  <div class="bnx-modal__close-btn" [mat-dialog-close]="false">
    <svg width="15" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times"
      class="svg-inline--fa fa-times fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
      <path fill="currentColor"
        d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z">
      </path>
    </svg>
  </div>

  <div class="bnx-modal__title">
    <h2 class="bnx-modal__heading">{{isEdit ? 'Edit Job':'Add Job'}}</h2>
  </div>
  <form [formGroup]="jobForm">
    <div class="bnx-view-modal__body">
      <div class="bnx-modal__wrapper job__wrapper">
        <div class="bnx-form__row">
          <div class="bnx-form__col">
            <div class="bnx-form__group required">
              <div class="bnx-form__label">Recruiter Responsible</div>
              <ng-select class="recNgclass" placeholder="Recruiter Responsible" (change)="getRecruiterSelected($event)"
               [items]="recruiterList" bindLabel="name" bindValue="id" formControlName="assigned_recruiter" ></ng-select>
               <error-msg [control]="jobForm.controls['assigned_recruiter']"></error-msg>

            </div>
          </div>

          <div class="bnx-form__col">
            <div class="bnx-form__label">Status</div>
            <div class="bnx-form__group required" *ngIf="!isEdit">
              <ng-select class="recNgclass" placeholder="Status"  [items]="jobStatusList" bindLabel="name" bindValue="id" formControlName="job_opening_status" [readonly]="true"></ng-select>
            </div>

            <div class="bnx-form__group required" *ngIf="isEdit">
              <ng-select class="recNgclass" placeholder="Status"  [items]="jobStatusList" bindLabel="name" bindValue="id" formControlName="job_opening_status" ></ng-select>
              <error-msg [control]="jobForm.controls['job_opening_status']"></error-msg>
            </div>
          </div>


          <div class="bnx-form__col">
            <div class="bnx-form__group">
              <input class="bnx-form__input" formControlName="target_date" type="date"(keydown)="preventTab($event)"/>
              <div class="bnx-form__label">Target Date <span class="bnx-form__aster-risk">*</span></div>
            </div>
            <error-msg [control]="jobForm.controls['target_date']"></error-msg>
          </div>

          <div class="bnx-form__col">
            <div class="bnx-form__group ">
              <input class="bnx-form__input" placeholder="Career Page Link" formControlName="careerPage" type="text" (keyup)="jobForm.controls['careerPage'].markAsTouched()" />
              <div class="bnx-form__label">Career Page Link <span class="bnx-form__aster-risk">*</span></div>
              <error-msg [control]="jobForm.controls['careerPage']"></error-msg>
            </div>
          </div>

          <div class="select-filters">
            <span class="filter-margin" *ngIf="selectedRecruiter.length >0">
              <mat-chip class="assigned-chip" *ngFor="let recruit of selectedRecruiter; let i = index" [removable]="true"
                (removed)="remove(recruit,'recruit')">
                {{recruit?.name}}
                <mat-icon matChipRemove>clear</mat-icon>
              </mat-chip>
            </span>

          </div>
          <hr class="bnx-form__line">
        </div>
        <div class="submit__job">
          <button class="bnx-btn" (click)="addJob()" [disabled]="formSubmitted ? true : false">{{isLoading ? isEdit ? 'Updating ...':'Adding ...':isEdit ? 'Update':'Submit'}}</button>
          <button class="bnx-btn bnx-btn-border" [mat-dialog-close]="false">Cancel</button>
        </div>
      </div>
    </div>
  </form>


</div>
