<div class="bnx-filter">
  <app-filter [componentName]="'dashboardInterviews'" [showExcelDownload]="true" [department]="true" [projects]="true"(download)="downloadExcel($event)"  (filterChange)="getFilteredChange($event)"></app-filter>
</div>

<div class="bnx-table-tab">
  <div class="bnx-table-wrapper__card">
    <div class="bnx-table-wrapper__card-body">
      <div class="bnx-table-wrapper__table-card">
        <app-table-view [setDefault]="setDefaultPage" [tableEditDelele]="tableEditDelele" [data]="upcommingJobList" (event)="getChange($event)"  [totalPages]="totalPages"  [tableKeys]="tableKey" [isLoading]="isLoading" [tableOption]="tableOption" [tableTitle]="tableTitle" ></app-table-view>
      </div>
    </div>
  </div>
</div>
