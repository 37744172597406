<div class="bnx-modal">
  <div class="bnx-modal__close-btn" [mat-dialog-close]="false">
    <svg width="15" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times"
      class="svg-inline--fa fa-times fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
      <path fill="currentColor"
        d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z">
      </path>
    </svg>
  </div>

  <div >
    <div class="bnx-modal__title">
      <h2 class="bnx-modal__heading">Edit Status</h2>
    </div>
    <div class="bnx-view-modal__body">
      <div class="bnx-modal__wrapper">
        <div class="bnx-form__row">
          <div class="bnx-form">
            <div class="bnx-currency-item">
              <ng-select class="recNgclass" placeholder="Status" [(ngModel)]="status" [items]="interviewStatusList" bindLabel="name"
                bindValue="name"></ng-select>
            </div>
          </div>
        </div>
        <div class="submit pt-5">
          <button class="bnx-btn" (click)="updateStatus()">Update Status</button>
          <button class="bnx-btn bnx-btn-border" [mat-dialog-close]="false">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
