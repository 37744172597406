import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AdminHomeComponent } from './pages/admin-home/admin-home.component';
import { RouterModule, Routes } from '@angular/router';
import {MatTabsModule} from '@angular/material/tabs';
import { OpeningJobsComponent } from './pages/opening-jobs/opening-jobs.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SourceMonthComponent } from './pages/source-month/source-month.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { RecruitmentChartComponent } from './pages/recruitment-chart/recruitment-chart.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { InterviewsPanelComponent } from './pages/interviews-panel/interviews-panel.component';
import { UpcommingInterviewsComponent } from './pages/upcomming-interviews/upcomming-interviews.component';
import { UpcommingInterviewsPanelComponent } from './pages/upcomming-interviews-panel/upcomming-interviews-panel.component';
import { UserPermissionGuard } from 'src/app/core/guards/permission.guard';
import { NgSelectModule } from '@ng-select/ng-select';
import { DepartmentHiringComponent } from './pages/department-hiring/department-hiring.component';
import { FormsModule } from '@angular/forms';
import { UpcommingJoineesComponent } from './pages/upcomming-joinees/upcomming-joinees.component';
import { GeneralSettingModule } from './pages/general-settings/general-setting.module';

const routes: Routes = [
  {
      path: '',
      redirectTo:'index',
      pathMatch:'full'
  },
  {
      path: 'index',
      component: AdminHomeComponent,
      canActivate:[UserPermissionGuard],
  },
  {
      path: 'home',
      component: HomePageComponent,
      canActivate:[UserPermissionGuard],
  }

];

@NgModule({
  declarations: [
    AdminHomeComponent,
    OpeningJobsComponent,
    SourceMonthComponent,
    RecruitmentChartComponent,
    HomePageComponent,
    InterviewsPanelComponent,
    UpcommingInterviewsComponent,
    UpcommingInterviewsPanelComponent,
    DepartmentHiringComponent,
    UpcommingJoineesComponent,


  ],
  imports: [
    SharedModule,
    CommonModule,
    MatTabsModule,
    NgApexchartsModule,
    RouterModule.forChild(routes),
    NgSelectModule,
    FormsModule,
    GeneralSettingModule
  ],
  providers:[DatePipe]

})
export class AdminModule { }
