<!-- filter start -->
<div class="bnx-manage-wrapper__view">
  <div class="bnx-table-wrapper">
    <div class="bnx-table-wrapper__sec">
      <div class="bnx-table-wrapper__head">
        <div *ngIf="!settingComponment">
          <a class="bnx-filter-list__filter-btn" (click)="filterBtn('showFilter')" [ngClass]="{'active':showFilter}">
            filter
            <em class="bnx-icon bnx-icon--filter"></em>
          </a>
        </div>


        <div class="bnx-table-wrapper__search">
          <button class="action-button-filter" [matMenuTriggerFor]="levelOne" #levelOneTrigger="matMenuTrigger" *ngIf="componentName === 'dashboardJobs' && showMultiExcelDownload">Actions</button>
          <mat-menu class="dropdown-items" #levelOne="matMenu" direction="down" yPosition="below">
            <span>
              <ng-container *ngFor="let childL1 of actionList">
                <li class="p-0 dropdown-items__item" mat-menu-item *ngIf="!childL1.submenu">
                  <a class="nav-link">{{childL1.name}}
                  </a>
                </li>
                <ng-container *ngIf="childL1.submenu && childL1.submenu.length > 0">
                  <li class="dropdown-items__item" mat-menu-item [matMenuTriggerFor]="levelTwo">
                    <span>{{childL1.name}}</span>
                    <span class="sh-icon sh-icon-arrow-down"></span>
                  </li>
                  <mat-menu class="dropdown-items" #levelTwo="matMenu">
                    <ng-container *ngFor="let childL2 of childL1.submenu">
                      <li class="dropdown-items__item" mat-menu-item (click)="multiExcelDownload(childL2)">
                        <a class="nav-link">{{childL2.name}}</a>
                      </li>
                    </ng-container>
                  </mat-menu>
                </ng-container>
              </ng-container>
            </span>
          </mat-menu>

          <button class="pdf-download" (click)="excelDownload()" *ngIf="showExcelDownload"> <img
              src="../../../../../assets/images/Export-excel.svg" alt="">
          </button>
          <input type="text" placeholder="Search" [(ngModel)]="searchText" (keyup)="searchTable($event)">
          <span>
            <svg width="23" height="23" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12.2226" cy="12.2226" r="8.64267" transform="rotate(-45 12.2226 12.2226)" stroke="#0094FF"
                stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M23.6045 23.1806L18.5469 18.123" stroke="#0094FF" stroke-width="2" stroke-miterlimit="10"
                stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </span>

          
        </div>
      </div>
    </div>
    <div class="bnx-filter-list__expand-sec" *ngIf="showFilter === true">
      <div class="bnx-form__row">
        <div class="bnx-form__col"  *ngIf="showLocation">
          <div class="bnx-form__group">
            <div class="bnx-currency-item">
              <ng-select class="recNgclass" placeholder="Location" [items]="locationList" bindLabel="name" bindValue="id" [clearable]="false"
               (change)="getCheckLocation($event)" [(ngModel)]="selectedLocation" (clear)="selectedTypeRemove(selectedLocation,checkedLocation,'location')">
              </ng-select>
            </div>
          </div>
        </div>
        <div class="bnx-form__col" *ngIf="projects">
          <div class="bnx-form__group">
            <div class="bnx-currency-item">
              <ng-select class="recNgclass" placeholder="Job Title" [items]="jobTitileList" bindLabel="name" [clearable]="false"
                bindValue="id" (change)="getCheckStatusJobTitile($event)" [(ngModel)]="selectedJobTitle" (clear)="selectedTypeRemove(selectedJobTitle,checkedJobTitle,'jobTitle')"></ng-select>
            </div>
          </div>
        </div>

        <div class="bnx-form__col" *ngIf="department">
          <div class="bnx-form__group">
            <div class="bnx-currency-item">
              <ng-select class="recNgclass" placeholder="Department" [items]="departmentList" bindLabel="name" [clearable]="false"
                bindValue="id" (change)="getCheckDepartment($event)" [(ngModel)]="selectedDepartment"(clear)="selectedTypeRemove(selectedDepartment,checkedDepartment,'department')"></ng-select>
            </div>
          </div>
        </div>

        <div class="bnx-form__col" *ngIf="status">
          <div class="bnx-form__group">
            <div class="bnx-currency-item">
              <ng-select class="recNgclass" placeholder="Status" [items]="statusList" bindLabel="name" bindValue="name" [clearable]="false"
                (change)="getCheckStatus($event)" [(ngModel)]="selectedStatus" (clear)="selectedTypeRemove(selectedStatus,checkedStatus,'status')"></ng-select>
            </div>
          </div>
        </div>

        <div class="bnx-form__col" *ngIf="demandStatus">
          <div class="bnx-form__group">
            <div class="bnx-currency-item">
              <ng-select class="recNgclass" placeholder="Status" [items]="demandStatusList" bindLabel="name"
                bindValue="id" (change)="getCheckDemandStatus($event)" [(ngModel)]="selectedDemandStatus" [clearable]="false"
                (clear)="selectedTypeRemove(selectedDemandStatus,checkedDemandStatus,'demandStatus')"></ng-select>
            </div>
          </div>
        </div>

        <div class="bnx-form__col" *ngIf="demandJobStatus">
          <div class="bnx-form__group">
            <div class="bnx-currency-item">
              <ng-select class="recNgclass" placeholder="Job Status" [items]="demandJobStatusList" bindLabel="name"
                bindValue="name" (change)="getCheckDemandJobStatus($event)" [(ngModel)]="selectedDemandJobStatus" [clearable]="false"
                (clear)="selectedTypeRemove(selectedDemandJobStatus,checkedDemandJobStatus,'demandJobStatus')"
                ></ng-select>
            </div>
          </div>
        </div>

        <div class="bnx-form__col" *ngIf="candidateLevel">
          <div class="bnx-form__group">
            <div class="bnx-currency-item">
              <ng-select class="recNgclass" placeholder="Level" [items]="candidateLevelList" bindLabel="name"
                bindValue="name" (change)="getCheckCandidateLevel($event)" [(ngModel)]="selectedLevel" [clearable]="false"
                (clear)="selectedTypeRemove(selectedLevel,checkedCandidateLevel,'level')"
                ></ng-select>
             
            </div>
          </div>
        </div>

        <div class="bnx-form__col" *ngIf="roll">
          <div class="bnx-form__group">
            <div class="bnx-currency-item">
              <ng-select class="recNgclass" placeholder="User Role" [items]="rollList" bindLabel="name"
                bindValue="id" (change)="getCheckRoll($event)" [(ngModel)]="selectedRoll" [clearable]="false"
                (clear)="selectedTypeRemove(selectedRoll,checkedRoll,'roll')"></ng-select> 

                <!--  -->
            </div>
          </div>
        </div>

      </div>
      <div class="bnx-filter-list__btns">
        <a class="bnx-btn bnx-btn-border"  [ngClass]="{'disabled': !cleatBtn}" (click)="clearAll()">Clear</a>
      </div>
    </div>

    <div class="select-filters" *ngIf="showFilter === true">
      <span class="select-filters__filter-margin" *ngIf="checkedDepartment.length >0 ">
        <div class="select-filters__label">  Department : </div>
        <div class="select-filters__mat-chip">
        <mat-chip *ngFor="let item of checkedDepartment; let i = index" [removable]="true"
          (removed)="remove(item,'department')">
          {{item?.name}}
          <mat-icon matChipRemove>clear</mat-icon>
        </mat-chip>
      </div>
      </span>

      <span class="select-filters__filter-margin" *ngIf="checkedJobTitle.length >0 ">
       <div class="select-filters__label">  Job Title : </div>
        <div class="select-filters__mat-chip">
            <mat-chip *ngFor="let item of checkedJobTitle; let i = index" [removable]="true"
              (removed)="remove(item,'jobTitle')">
              {{item?.name}}
              <mat-icon matChipRemove>clear</mat-icon>
            </mat-chip>
          </div>
      </span>

      <span class="select-filters__filter-margin" *ngIf="checkedLocation.length >0 ">
        <div class="select-filters__label">Location : </div>
        <div class="select-filters__mat-chip">
        <mat-chip *ngFor="let item of checkedLocation; let i = index" [removable]="true"
          (removed)="remove(item,'location')">
          {{item?.name}}
          <mat-icon matChipRemove>clear</mat-icon>
        </mat-chip>
      </div>
      </span>

      <span class="select-filters__filter-margin" *ngIf="checkedStatus.length >0 ">
        <div class="select-filters__label"> Status :  </div>
          <div class="select-filters__mat-chip">
            <mat-chip *ngFor="let item of checkedStatus; let i = index" [removable]="true"
              (removed)="remove(item,'status')">
              {{item?.name}}
              <mat-icon matChipRemove>clear</mat-icon>
            </mat-chip>
          </div>
      </span>

      <span class="select-filters__filter-margin" *ngIf="checkedDemandStatus.length >0 ">
        <div class="select-filters__label">Status: </div>
          <div class="select-filters__mat-chip">
            <mat-chip *ngFor="let item of checkedDemandStatus; let i = index" [removable]="true"
              (removed)="remove(item,'demandStatus')">
              {{item?.name}}
              <mat-icon matChipRemove>clear</mat-icon>
            </mat-chip>
          </div>
      </span>

      <span class="select-filters__filter-margin" *ngIf="checkedDemandJobStatus.length >0 ">
        <div class="select-filters__label">Job Status : </div>
        <div class="select-filters__mat-chip">
        <mat-chip *ngFor="let item of checkedDemandJobStatus; let i = index" [removable]="true"
          (removed)="remove(item,'jobStatus')">
          {{item?.name}}
          <mat-icon matChipRemove>clear</mat-icon>
        </mat-chip>
        </div>
      </span>

      <span class="select-filters__filter-margin" *ngIf="checkedCandidateLevel.length >0 ">
        <div class="select-filters__label"> Level: </div>
          <div class="select-filters__mat-chip">
          <mat-chip *ngFor="let item of checkedCandidateLevel; let i = index" [removable]="true"
            (removed)="remove(item,'level')">
            {{item?.name}}
            <mat-icon matChipRemove>clear</mat-icon>
          </mat-chip>
          </div>
      </span>

      <span class="select-filters__filter-margin" *ngIf="checkedRoll.length >0 ">
        <div class="select-filters__label">Role :</div>
        <div class="select-filters__mat-chip">
          <mat-chip *ngFor="let item of checkedRoll; let i = index" [removable]="true"
            (removed)="remove(item,'roll')">
            {{item?.name}}
            <mat-icon matChipRemove>clear</mat-icon>
          </mat-chip>
        </div>
      </span>

      <!-- <a class="select-filters__btn" *ngIf="showFilter === true" (click)="clearAll()">Clear All</a> -->

    </div>


    

  </div>
</div>
