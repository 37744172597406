import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdminApiService } from '../../../services/admin-api.service';

@Component({
  selector: 'app-job-title-list',
  templateUrl: './job-title-list.component.html',
  styleUrls: ['./job-title-list.component.scss']
})
export class JobTitleListComponent implements OnInit {
  @Input() data!:any[];
  @Input() tabKey!:any;
  @Input() settingDetail!:any;
  @Input() isLoading!: boolean;
  @Input() tableOption!: any;
  @Input() tableEditDelele!: any;
  @Input() totalPages!: number;
  @Input() setDefaultPage!: boolean;
  @Output() event = new EventEmitter<any>();
jobList:any[]=[];
constructor(
  private adminApiService:AdminApiService
){
}
  ngOnInit(): void {
  }
  editRow(i: any, row: any) {
    const emitId = {
      index: i,
      action: 'edit',
      row: row,
    };
    this.event.emit(emitId);
  }
  deleteRow(i: any, row: any) {
    const emitId = {
      index: i,
      action: 'delete',
      row: row,
    };
    this.event.emit(emitId);
  }
  getPage(env:any){
    window.scroll(0,0);
    this.event.emit(env);
  }
}
