import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AdminApiService } from '../../services/admin-api.service';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})
export class AdminHomeComponent {
  deapartmentCards: any;
  yearList = ['2024','2023','2022'];
  monthList = [{id:1, value: 'January'}, {id: 2, value: 'February'}, {id: 3, value: 'March'}, {id: 4, value: 'April'}, {id: 5, value: 'May'},{id: 6, value: 'June'},
  {id: 7, value: 'July'},{id: 8, value: 'August'}, {id: 9, value: 'September'}, {id: 10, value: 'October'}, { id: 11, value: 'November' }, { id: 12, value: 'December' }]
  year:any;
  currentYear = new Date().getFullYear();
  currentMonth:any = [];
  filterArray:any = {
    "year": [this.currentYear],
    "month": [],
  }
  progressDetails: any[] = [];
  isRecChartLoading: boolean = true;
  isSouChartLoading: boolean = true;

  constructor(
    private AdminApiService : AdminApiService
  ){}

  ngOnInit(): void {
    this.getDepartmentCards(this.filterArray);
  }

  getDepartmentCards(filterArray: any){
    this.filterArray = filterArray;
    if(filterArray == ''){
      filterArray = {
        "year": [],
        "month": [],
      }
    }
    this.AdminApiService.getDepartmentCards(filterArray).subscribe((res:any) =>{
      this.deapartmentCards = res.cards;
    })
  }

  changeYear(env:any){
    let filterMonth = [];
    let filterYear = this.currentYear;
    if((this.currentMonth?.length === 0) || (this.currentMonth === null)){
      filterMonth = []
    } else{
      filterMonth.push(this.currentMonth?.id);
    }
    if(filterYear === null){
      filterYear = new Date().getFullYear()
    }

    this.filterArray = {
      "year": [filterYear],
      "month": filterMonth
    }
    this.getDepartmentCards(this.filterArray);
    this.getDepartmentCharts(filterYear);
  }

  changeMonth(env:any){
    let filterMonth = [];
    let filterYear = this.currentYear;
    if((this.currentMonth?.length === 0) || (this.currentMonth === null)){
      filterMonth = []
    }
    else{
      filterMonth.push(this.currentMonth?.id);
    }

    if((filterYear === undefined) || filterYear === null){
      filterYear = new Date().getFullYear()
    }

    this.filterArray = {
      "year": [filterYear],
      "month":filterMonth
    }
    this.getDepartmentCards(this.filterArray);
  }

  getDepartmentCharts(env:any){
    this.currentYear = env;
  }

  getSourceCharts(env:any){
    this.currentYear = env;
  }

  getRecruitmentCharts(env:any){
    this.currentYear = env;
  }

  setLoadingChange(env:any){
    this.isRecChartLoading = false;
  }

  setSourceLoadingChange(env:any){
    this.isSouChartLoading = false;
  }
}
