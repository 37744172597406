import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { catchError, finalize, of } from 'rxjs';
import { CandidateMasterService } from 'src/app/modules/user/services/candidate-master.service';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-hr-feedback',
  templateUrl: './hr-feedback.component.html',
  styleUrls: ['./hr-feedback.component.scss']
})
export class HrFeedbackComponent {
  hrFeedbackForm!: FormGroup;
  isLoading = false;
  selectedHR: any[] = [];
  hrList:any = [];
  hrDetailsList: any;
  public formSubmitted: boolean = false;
  public panel_data:any;
  communication = ['Fluent', 'Proficient', 'Intermediate'];
  attitude = ['Good', 'Average', 'Poor'];
  rating = ['1 - Unacceptable', '2 - Poor', '3 - Good', '4 - Excellent', '5 - Outstanding'];
  status = ['Shortlist', 'On hold', 'Rejected', 'Pending' , 'Completed', 'In Progress'];

  constructor(private commonService: CommonService, private formBuilder: FormBuilder, private toast: ToastrService,
    private datePipe: DatePipe,private candidateService: CandidateMasterService,
    public dialogRef: MatDialogRef<HrFeedbackComponent>, @Inject(MAT_DIALOG_DATA) public candidateDetailForm: any, private candidateApiService: CandidateMasterService,

   ) { }

  ngOnInit(): void {
    this.initForm();
    this.getHRList();
    this.patchFeedback();
  }


  initForm(): void {
    this.hrFeedbackForm = this.formBuilder.group({
      id: new FormControl(''),
      communication: new FormControl(null, Validators.required),
      attitude: new FormControl(null, Validators.required),
      rating: new FormControl(null, Validators.required),
      status: new FormControl(null, Validators.required),
      remark: new FormControl('', [Validators.required]),
      date: new FormControl('', [Validators.required]),
      interviewer: new FormControl('', [Validators.required]),
    })
  }

  patchFeedback() {
    let updatedRemark = this.candidateDetailForm.data?.hr_feedback;
    let updatedRemarksPanel = this.candidateDetailForm.data?.remarks;
    let updatedRemarks = this.candidateDetailForm.data?.remark;
    
    if(this.candidateDetailForm.data?.remark !== ' ') {
      if(this.candidateDetailForm.data?.remark?.includes('<br>')) {
        updatedRemarks = this.candidateDetailForm.data?.remark?.replace(/<br\s*\/?>/g, '\n');
      }
    } else {
      if (this.candidateDetailForm.data?.hr_feedback?.includes('<br>')) {
        updatedRemark = this.candidateDetailForm.data?.hr_feedback?.replace(/<br\s*\/?>/g, '\n');
      }
    }

    if(this.candidateDetailForm.data?.remarks?.includes('<br>')) {
      updatedRemarksPanel = this.candidateDetailForm.data?.remarks?.replace(/<br\s*\/?>/g, '\n');
    }

    this.hrFeedbackForm.patchValue({
      communication: this.candidateDetailForm.data?.communication,
      attitude:  this.candidateDetailForm.data?.attitude,
      rating:  this.candidateDetailForm.data?.rating  === 1 ? '1 - Unacceptable' : this.candidateDetailForm.data?.rating === 2 ? '2 - Poor' : this.candidateDetailForm.data?.rating === 3 ? '3 - Good' : this.candidateDetailForm.data?.rating === 4 ? '4 - Excellent' : this.candidateDetailForm.data?.rating === 5 ? '5 - Outstanding' : this.candidateDetailForm.data?.rating,
      status:  this.candidateDetailForm.data?.feedbackStatus || this.candidateDetailForm.data?.status.label,
      // date:  this.candidateDetailForm.data?.date,
      remark :  this.candidateDetailForm.data?.remark === null ? updatedRemark : this.candidateDetailForm.panelFeedback === true ? updatedRemarksPanel : updatedRemarks,
      interviewer: this.candidateDetailForm.panelFeedback === true ? this.candidateDetailForm.data?.interviewer.map((ele:any) => ele.id) : this.candidateDetailForm.data?.interviewer
    })

    if (this.candidateDetailForm.panelFeedback === true) {
      let dateString = this.candidateDetailForm.data?.date; // "17-09-2024"
      let formattedDate = this.convertToDateFormat(dateString);
      if (formattedDate) {
        this.hrFeedbackForm.controls['date'].patchValue(this.datePipe.transform(formattedDate, 'yyyy-MM-dd'));
      }
    } else {
      this.hrFeedbackForm.controls['date'].patchValue(this.candidateDetailForm.data?.date);
    }
  }

  convertToDateFormat(dateString: string): string | null {
    if (!dateString) {
      return null;
    }
    const [day, month, year] = dateString.split('-');
    return `${year}-${month}-${day}`;
  }

  getHRList(): void {
    let filterArray = {
      'license_type': [1],
      'is_active': [],
      'q': []
    }
    this.commonService.getJRecruiterList(filterArray).subscribe((result) => {
      this.hrList = result;
    })
  }

  selectedType(event: any) {
    event.forEach((xx: any) => {
      let addHR: boolean = false;
      this.selectedHR = [];
      this.selectedHR = event;
      var index = this.selectedHR?.findIndex(x => x?.id == xx.id);
      if (index == -1) {
        var selectedArray = this.hrList.find((element: any) => element.id == Number(xx.id));

        if (!addHR) {
          this.selectedHR?.push({
            'id': selectedArray.id,
            'name': selectedArray.full_name?.name,
            'email': selectedArray.email
          });
          this.hrFeedbackForm.value.interviewer.push(selectedArray.id);
        } else {
          console.log('d');
          // this.getControl(0, 'interviewer').reset()
        }
      }
    });
  }


  submit() {
    this.formSubmitted = true;
    if (this.hrFeedbackForm.valid) {
      this.isLoading = true;
      let c_data = this.hrFeedbackForm.value;
      const data = {
        "communication": c_data.communication,
        "attitude": c_data.attitude,
        "rating": c_data?.rating?.split(" - ")[0],
        "remarks": c_data.remark,
        "status": c_data.status,
        "date": c_data?.date,
        "interviewers": this.hrFeedbackForm.value.interviewer
      }
      this.panel_data = data;
      if (this.candidateDetailForm.panelFeedback === true) {
        let formData = new FormData();
        formData.append('data', JSON.stringify(this.panel_data));

        this.candidateService.editHRFeedbackDetails(
          formData,this.candidateDetailForm.data?.id
        )
        .pipe(
          catchError((err) => {
            this.toast.warning(err.error.message);
            return of(false)
          })
        )
        .subscribe((res:any) => {
          this.toast.success(res.message);
          this.dialogRef.close(true);
        });

      } else {
        this.dialogRef.close(this.panel_data);
      }
     
    } else { 
      this.formSubmitted = false;  
      this.toast.warning('Please fill all mandatory field');
    }
}


}
